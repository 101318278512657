/**
 * Created by nicom on 5/3/2017.
 */

export default class HourRowData {
  constructor(index = 0, frm = "", till = "", project = "", description = "") {
    this.index = index;
    this.frm = frm;
    this.till = till;
    this.project = project;
    this.description = description;
  }
  updateData(objToCopy) {
    this.index = objToCopy.index;
    this.frm = objToCopy.frm;
    this.till = objToCopy.till;
    this.project = objToCopy.project;
    this.project = objToCopy.description;
  }
}
