/**
 * Created by nicom on 7/26/2017.
 */

import React, { Component } from "react";
import moment from "moment";
import { DropdownButton, Dropdown } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

const DAY_FORMAT = "DD/MM/YYYY";

let storedState = {
  startDate: moment(new Date())
    .subtract(new Date().getDay(), "d")
    .format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
};

class DatesPicker extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, storedState);
  }

  onSelectedStartDate(selectedDate) {
    let startDate = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    this.props.onDatesChanged(startDate, null);
  }

  onSelectedEndDate(selectedDate) {
    let endDate = moment(new Date(selectedDate)).format("YYYY-MM-DD");
    this.props.onDatesChanged(null, endDate);
  }

  onSelectQuickpick(quick) {
    let startDate = null,
      endDate = null;
    switch (quick) {
      case "yesterday":
        startDate = moment(new Date()).subtract(1, "d").format("YYYY-MM-DD");
        endDate = startDate;
        break;
      case "today":
        startDate = moment(new Date()).format("YYYY-MM-DD");
        endDate = startDate;
        break;
      case "this_week":
        startDate = moment(new Date())
          .subtract(new Date().getDay(), "d")
          .format("YYYY-MM-DD");
        endDate = moment(new Date()).format("YYYY-MM-DD");
        break;
      case "last_week":
        let lastWeekFinalDay = moment(new Date()).subtract(
          new Date().getDay() + 1,
          "d"
        );
        startDate = moment(new Date(lastWeekFinalDay))
          .subtract(6, "d")
          .format("YYYY-MM-DD");
        endDate = moment(new Date(lastWeekFinalDay)).format("YYYY-MM-DD");
        break;
      case "this_month":
        startDate = moment(new Date()).date(1).format("YYYY-MM-DD");
        endDate = moment(new Date()).format("YYYY-MM-DD");
        break;
      case "last_month":
        let lastDayPrvMonth = moment(new Date()).date(1).subtract(1, "d");
        startDate = moment(lastDayPrvMonth).date(1).format("YYYY-MM-DD");
        endDate = moment(lastDayPrvMonth).format("YYYY-MM-DD");
        break;
      default:
        console.log("Something unfamiliar was chosen");
    }
    this.props.onDatesChanged(startDate, endDate);
  }

  render() {
    return (
      <div>
        <div
          className="hours-dates-holder"
          disabled={
            (this.props.allDates && this.props.currentPage === "Hours") ||
            (this.props.allFuture && this.props.currentPage === "Planner")
          }
        >
          <DayPickerInput
            format={DAY_FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder="Start date"
            value={
              this.props.startDate
                ? moment(this.props.startDate).format(DAY_FORMAT)
                : moment(this.state.startDate).format(DAY_FORMAT)
            }
            onDayChange={this.onSelectedStartDate.bind(this)}
            inputProps={{
              className: "hours-date-picker",
            }}
          />
          <div className="hours-date-sep"></div>
          <DayPickerInput
            format={DAY_FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder="End date"
            value={
              this.props.endDate
                ? moment(this.props.endDate).format(DAY_FORMAT)
                : moment(this.state.endDate).format(DAY_FORMAT)
            }
            onDayChange={this.onSelectedEndDate.bind(this)}
            inputProps={{
              className: "hours-date-picker",
            }}
          />
          <DropdownButton variant=""
            className="quickpick-dropdown"
            onSelect={this.onSelectQuickpick.bind(this)}
            size="sm"
            id="quickpick"
            title=""
          >
            <Dropdown.Item eventKey="yesterday">Yesterday</Dropdown.Item>
            <Dropdown.Item eventKey="today">Today</Dropdown.Item>
            <Dropdown.Item eventKey="this_week">This Week</Dropdown.Item>
            <Dropdown.Item eventKey="last_week">Last Week</Dropdown.Item>
            <Dropdown.Item eventKey="this_month">This Month</Dropdown.Item>
            <Dropdown.Item eventKey="last_month">Last Month</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    );
  }
}

export default DatesPicker;
