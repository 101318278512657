import React, { Component } from "react";
import ManagerWorkersHelper from "../../common/ManagerWorkersHelper";
import Alerts from "./Alerts";
import WorkedHoursList from "./HoursData/WorkedHoursList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { batchActions } from "redux-batched-actions";
import {
  updateIsShowingWorkers,
  updateSelectedWorkersIds,
  updateSelectedProjectsIds,
  updateManagerProjects,
  updateCurrentPage,
  updateAllDates,
  updateAllDatesEnabled,
  updateSelectedProjectsObjects,
  updateSelectedWorkersObjects,
  updateTimeFormat,
  updateAlerts,
  updateUserProjects,
  updateHours,
  updateWorkedDetails,
  updateIsLoading,
} from "../../stores/actions";
import shallowCompareArrays from "../../common/ShallowComparer";
import {
  httpGetUsersHours,
  httpGetAllUsersHoursForProject,
  httpGetAllUserHoursForProject,
} from "../../Urls";

import {
  httpGetManagerWorkers,
  httpGetUserOwnProjects,
  httpGetUserHours,
  httpGetUserHoursForProject,
  httpGetUserHoursForFreeDay,
  httpGetFreeDaysTypes,
  httpGetUserWorkTimeDetails,
  httpGetUserErrors,
  httpGetAllProjects,
} from "../../Urls";

import { getProjectNameByID } from "../../common/ProjectsHelper";

const freeDayShift = 1000;
const freeDayPrefix = "Free day - ";

class Hours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 20,
      skip: 0,
      retrievedAll: false,
    };
    let validTimeFormats = ["H:mm", "h:mm A"];
    let timeFormat = validTimeFormats[0];
    let settings = localStorage.getItem("settings");
    if (settings) {
      try {
        let format = JSON.parse(settings).timeFormat;
        if (validTimeFormats.indexOf(format) >= 0) {
          timeFormat = format;
        }
      } catch (e) {}
    }

    if (this.props.selectedWorkersIds.length === 0) {
      this.props.updateSelectedWorkersIds([this.props.user.userName]);
      this.props.updateSelectedWorkersObjects([
        {
          label: this.props.user.profile.name,
          value: this.props.user.userName,
        },
      ]);
    }

    this.props.updateTimeFormat(timeFormat);

    this.fetchWorkerHours = this.fetchWorkerHours.bind(this);
    this.updateWorkerHours = this.updateWorkerHours.bind(this);
    this.populateAvailableProjects = this.populateAvailableProjects.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchCurrentData = this.fetchCurrentData.bind(this);

    this.onWorkersChanged = this.onWorkersChanged.bind(this);
    this.onProjectsChanged = this.onProjectsChanged.bind(this);
    this.setToAllProjects = this.setToAllProjects.bind(this);
    this.fetchProjectHours = this.fetchProjectHours.bind(this);
    this.isLessThanLimit = this.isLessThanLimit.bind(this);
    this.resetSkipAndLimit = this.resetSkipAndLimit.bind(this);
    this.updateProjectHours = this.updateProjectHours.bind(this);
  }

  async onWorkersChanged(selectedWorkersIds) {
    this.resetSkipAndLimit(() => {
      if (this.props.isShowingWorkers) {
        this.onProjectOrWorkerChanged(
          this.props.selectedProjectsIds[0],
          selectedWorkersIds[0]
        );
      } else {
        this.onProjectOrWorkerChanged(this.props.selectedProjectsIds[0], null);
      }
    });
  }

  async onProjectsChanged(selectedProjectsIds) {
    this.resetSkipAndLimit(() => {
      if (this.props.isShowingWorkers)
        this.onProjectOrWorkerChanged(
          selectedProjectsIds[0],
          this.props.selectedWorkersIds[0]
        );
      else {
        this.onProjectOrWorkerChanged(selectedProjectsIds[0], null);
      }
    });
  }

  async onProjectOrWorkerChanged(projectId, workerId) {
    const {
      projectHelper,
      userProjects,
      allDates,
      selectedWorkersIds,
      startDate,
      endDate,
      freeDayTypes,
      selectedAuditOption,
    } = this.props;
    if (projectHelper !== null) {
      let _userProjects = userProjects;
      let allDatesEnabled = true;
      let _allDates = allDates;
      if (projectId < 0 || projectId >= freeDayShift) {
        allDatesEnabled = false;
        _allDates = false;
      }
      if (workerId !== null && workerId !== undefined) {
        // if user + project
        _userProjects = await this.getWorkerProjects(
          startDate,
          endDate,
          workerId,
          freeDayTypes
        );
        this.props.updateUserProjects(_userProjects);
        if (!_userProjects.find((p) => p.ID === projectId)) {
          //if selected project is not allowed
          this.setToAllProjects();
        }
        await this.getProjectsWithFreeDays(true);
      } else {
        // if just project
        if (!selectedWorkersIds[0]) return;
        workerId = selectedWorkersIds[0];

        let data;
        if (_allDates) {
          data = await this.fetchAllProjectHours();
        } else {
          data = await this.fetchProjectHours(
            startDate,
            endDate,
            projectId,
            selectedAuditOption.value
          );
        }
        this.updateProjectHours(data);
      }

      this.props.dispatch(
        batchActions([
          updateAllDates(_allDates),
          updateAllDatesEnabled(allDatesEnabled),
        ])
      );
    }
  }

  /**
   * fetch all the hours worked rows from the user
   * @param {Date} fromDate
   * @param {Date} tillDate
   * @param {Number} projectId
   */
  async fetchProjectHours(fromDate, tillDate, projectId, auditStatus) {
    if (projectId === -1) {
      this.props.updateHours([]);
      this.setState({ retrievedAll: true });
      return;
    }
    this.props.updateIsLoading(true);
    if (this.state.retrievedAll) this.setState({ retrievedAll: false });
    let data = await httpGetUsersHours(
      fromDate,
      tillDate,
      projectId,
      this.state.skip,
      this.state.limit,
      auditStatus
    );
    return data;
  }

  async updateProjectHours(resp) {
    if (!resp) return;
    if (resp && resp.status === 200) {
      var countRows = 0;

      let hoursData = await resp.json();
      Object.keys(hoursData).forEach((key) => {
        // got to count num rows returned in case of same employee
        var values = hoursData[key];
        values.forEach((v) => {
          countRows += v.HoursWorked.length;
        });
      });
      if (
        Object.keys(hoursData).length === 0 ||
        this.isLessThanLimit(hoursData, countRows)
      ) {
        this.setState({ retrievedAll: true });
      }
      let data = this.populateAvailableProjectsForUsers(hoursData);
      this.props.updateHours([]);
      this.props.updateHours(data);
      this.props.updateIsLoading(false);

      let currRows = 0;
      Object.keys(this.props.hours).forEach((key) => {
        var v = this.props.hours[key];
        currRows += v.HoursWorked.length;
      });

      this.setState({
        limit: this.state.limit,
        skip: currRows,
      });
    }
  }

  isLessThanLimit = (data, countRows) => {
    let count = 0;
    let flag = true;
    Object.entries(data).forEach((entry) => {
      var hours = entry[1];
      hours.forEach((day) => {
        count += day.HoursWorked.length;
        if (count >= countRows && countRows >= 20) {
          flag = false;
          return;
        }
      });
    });
    return flag;
  };

  loadMoreHandler = async () => {
    let data;
    if (this.props.allDates) {
      data = await this.fetchAllProjectHours();
    } else {
      data = await this.fetchProjectHours(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedProjectsIds[0],
        this.props.selectedAuditOption.value
      );
    }
    this.updateProjectHours(data);
  };

  populateAvailableProjectsForUsers(userHours) {
    var data = [];
    let that = this;
    Object.entries(userHours).forEach((entry) => {
      var hours = entry[1];
      if (!Array.isArray(hours)) return;
      hours.forEach((day) => {
        var unique = {};
        var distinct = [];
        day.HoursWorked.forEach((report) => {
          if (typeof unique[report.ProjectID] === "undefined") {
            distinct.push(report.ProjectID);
          }
          unique[report.ProjectID] = 0;
        });
        let projects = distinct.map((pid) =>
          getProjectNameByID(pid, that.props.projectHelper)
        );
        projects.sort((a, b) => a.Name.localeCompare(b.Name));
        day.ProjectsAvailable = projects;
      });
      data = [...data, ...hours];
    });
    if (this.state.skip >= 20) {
      data = [...this.props.hours, ...data];
    }
    return data.sort(function (a, b) {
      return new Date(b.Day.Date) - new Date(a.Day.Date);
    });
  }

  populateAvailableProjects(hours) {
    let that = this;
    hours.forEach((day) => {
      var unique = {};
      var distinct = [];
      day.HoursWorked.forEach((report) => {
        if (typeof unique[report.ProjectID] === "undefined") {
          distinct.push(report.ProjectID);
        }
        unique[report.ProjectID] = 0;
      });
      let projects = distinct.map((pid) =>
        getProjectNameByID(pid, that.props.projectHelper)
      );
      projects.sort((a, b) => a.Name.localeCompare(b.Name));
      day.ProjectsAvailable = projects;
    });
  }

  async onToggleAllDates(allDates) {
    let res;
    this.props.updateIsLoading(true);
    if (this.state.retrievedAll) this.setState({ retrievedAll: false });

    if (this.props.isShowingWorkers) {
      this.fetchData(
        this.props.startDate,
        this.props.endDate,
        allDates,
        this.props.selectedProjectsIds[0],
        this.props.selectedWorkersIds[0],
        this.props.projectHelper
      );
    } else {
      if (allDates) res = await this.fetchAllProjectHours();
      else {
        res = await this.fetchProjectHours(
          this.props.startDate,
          this.props.endDate,
          this.props.selectedProjectsIds[0],
          this.props.selectedAuditOption.value
        );
        this.updateProjectHours(res);
      }
    }
  }

  async fetchAllProjectHours() {
    this.props.updateIsLoading(true);
    if (this.state.retrievedAll) this.setState({ retrievedAll: false });
    let data = await httpGetAllUsersHoursForProject(
      this.props.selectedProjectsIds[0],
      this.state.skip,
      this.state.limit,
      this.props.selectedAuditOption.value
    );
    return data;
  }

  resetSkipAndLimit = (cb = null) => {
    this.setState(
      {
        limit: 20,
        skip: 0,
      },
      () => {
        if (cb) cb();
      }
    );
  };

  async onDatesChanged(startDate, endDate) {
    this.resetSkipAndLimit();
    if (startDate === null || startDate === "Invalid date") {
      startDate = this.props.startDate;
    }

    if (endDate === null) {
      endDate = this.props.endDate;
    }

    let userProjects = await this.getWorkerProjects(
      startDate,
      endDate,
      this.props.selectedWorkersIds[0],
      this.props.freeDayTypes
    );

    let allDatesEnabled = true;
    let allDates = this.props.allDates;
    let selectedProjectId = this.props.selectedProjectsIds[0];

    if (!userProjects.find((p) => p.ID === this.props.selectedProjectsIds[0])) {
      selectedProjectId = -1;
      allDatesEnabled = false;
      allDates = false;
    }

    let selectedWorkerId = this.props.selectedWorkersIds[0];
    if (selectedProjectId < 0 || selectedProjectId >= freeDayShift) {
      allDatesEnabled = false;
      allDates = false;
    }
    this.props.dispatch(
      batchActions([
        updateUserProjects(userProjects),
        updateAllDates(allDates),
        updateAllDatesEnabled(allDatesEnabled),
      ])
    );

    if (this.props.isShowingWorkers) {
      this.fetchData(
        startDate,
        endDate,
        allDates,
        selectedProjectId,
        selectedWorkerId,
        this.props.projectHelper
      );
    } else {
      var data = await this.fetchProjectHours(
        startDate,
        endDate,
        selectedProjectId,
        this.props.selectedAuditOption.value
      );
      this.updateProjectHours(data);
    }
  }

  async getProjectsWithFreeDays(useUserProjects = false) {
    if (this.props.selectedWorkersIds[0]) {
      let freeDayTypes = this.props.freeDayTypes;
      let userProjects = useUserProjects
        ? this.props.userProjects
        : await this.getWorkerProjects(
            this.props.startDate,
            this.props.endDate,
            this.props.selectedWorkersIds[0],
            freeDayTypes
          );
      if (this.props.isShowingWorkers) {
        this.fetchData(
          this.props.startDate,
          this.props.endDate,
          this.props.allDates,
          this.props.selectedProjectsIds[0],
          this.props.selectedWorkersIds[0],
          this.props.projectHelper
        );
      } else {
        await this.getProjectHours();
      }
      this.props.updateManagerProjects([...userProjects]);
    }
  }

  async getProjectHours() {
    let data;
    if (!this.props.allDates) {
      let id = this.props.selectedProjectsIds
        ? this.props.selectedProjectsIds[0]
        : undefined;
      data = await this.fetchProjectHours(
        this.props.startDate,
        this.props.endDate,
        id,
        this.props.selectedAuditOption.value
      );
    } else {
      data = await this.fetchAllProjectHours();
    }
    this.updateProjectHours(data);
  }

  async componentDidMount() {
    this.props.dispatch(batchActions([updateCurrentPage("Hours")]));
    if (this.props.selectedWorkersObjects.length > 1) {
      let selectedWorkersIds;
      let selectedWorkersObjects;
      if (
        this.props.selectedWorkersIds.length > 1 &&
        (this.props.selectedWorkersObjects[0].label === "Select all" ||
          this.props.managerWorkers.Workers.length ===
            this.props.selectedWorkersObjects.length)
      ) {
        selectedWorkersIds = [this.props.user.userName];
        selectedWorkersObjects = [
          {
            label: this.props.user.profile.name,
            value: this.props.user.userName,
          },
        ];
      } else {
        selectedWorkersIds = [this.props.selectedWorkersIds[0]];
        selectedWorkersObjects = [
          this.props.selectedWorkersObjects.find(
            (worker) => worker.value === this.props.selectedWorkersIds[0]
          ),
        ];
      }
      this.props.dispatch(
        batchActions([
          updateSelectedWorkersObjects(selectedWorkersObjects),
          updateSelectedWorkersIds(selectedWorkersIds),
        ])
      );
    }
    await this.getProjectsWithFreeDays();
    if (
      this.props.selectedProjectsObjects.length > 1 &&
      this.props.selectedProjectsObjects[0].value !== "*"
    ) {
      this.setToAllProjects();
    }
  }

  setToAllProjects(allSelected = false) {
    if (this.props.selectedProjectsIds[0] !== -1 || allSelected) {
      this.props.dispatch(
        batchActions([
          updateSelectedProjectsObjects({
            label: "Select project",
            selected: true,
            value: -1,
          }),
          updateSelectedProjectsIds([-1]),
          updateAllDates(false),
          updateAllDatesEnabled(false),
        ])
      );
    }
  }

  async fetchWorkerTimeDetails(fromDate, tillDate, workerId) {
    let response = await httpGetUserWorkTimeDetails(
      fromDate,
      tillDate,
      workerId
    );
    if (response.status === 200) {
      response.json().then((resp) => {
        this.props.updateWorkedDetails(resp);
      });
    }
  }

  async fetchUserErrors(fromDate, tillDate, workerId) {
    let response = await httpGetUserErrors(fromDate, tillDate, workerId);
    if (response.status === 200) {
      response.json().then((resp) => {
        let alerts = [];
        resp.forEach((error) => {
          alerts.push({
            Date: error.Date,
            Text: error.Description,
          });
        });
        this.props.updateAlerts(alerts);
      });
    }
  }

  async getAllProjects(freeDayTypes) {
    let response = await httpGetAllProjects();
    if (response.status === 200) {
      let allProjects = await response.json();
      this.addFreeDayTypes(allProjects, freeDayTypes);

      allProjects = allProjects.sort((a, b) => a.Name.localeCompare(b.Name));
      return allProjects;
    }
  }

  async getFreeDayTypes() {
    let response = await httpGetFreeDaysTypes();
    if (response.status === 200) {
      let freeTypes = await response.json();
      return freeTypes;
    }
  }

  /**
   * fetch all the necesary data needed to show.
   * @param {Date} fromDate
   * @param {Date} tillDate
   * @param {Number} workerId
   * @param {Number} projectId
   */
  async fetchData(
    fromDate,
    tillDate,
    allDates,
    projectId,
    workerId,
    projectsHelper
  ) {
    if (this.props.isShowingWorkers) {
      this.fetchWorkerHours(
        fromDate,
        tillDate,
        allDates,
        projectId,
        workerId,
        projectsHelper,
        this.props.selectedAuditOption.value
      );
      this.fetchWorkerTimeDetails(fromDate, tillDate, workerId);
      this.fetchUserErrors(fromDate, tillDate, workerId);
    } else {
      this.fetchProjectHours(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedProjectsIds[0],
        this.props.selectedAuditOption.value
      );
    }
  }

  async fetchCurrentData(currentDate, workerId) {
    this.updateWorkerHours(
      currentDate,
      this.props.selectedProjectsIds[0],
      workerId
    );
    this.fetchWorkerTimeDetails(
      this.props.startDate,
      this.props.endDate,
      workerId
    );
    this.fetchUserErrors(this.props.startDate, this.props.endDate, workerId);
  }

  async updateWorkerHours(currentDate, projectId, workerId) {
    var resp;
    if (projectId === undefined || projectId === -1) {
      resp = await httpGetUserHours(
        currentDate,
        currentDate,
        workerId,
        this.props.selectedAuditOption.value
      );
    } else if (projectId < freeDayShift) {
      if (!this.props.isShowingWorkers) {
        resp = await httpGetUserHoursForProject(
          currentDate,
          currentDate,
          workerId,
          projectId,
          this.props.selectedAuditOption.value
        );
      } else {
        resp = await httpGetUserHoursForProject(
          currentDate,
          currentDate,
          workerId,
          projectId,
          this.props.selectedAuditOption.value
        );
      }
    } else {
      resp = await httpGetUserHoursForFreeDay(
        currentDate,
        currentDate,
        workerId,
        projectId - freeDayShift
      );
    }

    if (resp.status === 200) {
      let hoursData = await resp.json();

      if (hoursData.length === 1) {
        let idx = this.props.hours.findIndex(
          (day) =>
            day.Day.Date === currentDate && day.Name === hoursData[0].Name
        );
        if (idx >= 0) {
          let newHours = this.props.hours.slice();
          this.populateAvailableProjects(hoursData, this.props.projectHelper);
          newHours[idx] = hoursData[0];
          hoursData = newHours;
          this.props.updateHours(hoursData);
        }
      } else {
        let newHours = this.props.hours.slice();
        let idx = this.props.hours.findIndex(
          (day) => day.Day.Date === currentDate && workerId === day.Email
        );

        newHours.splice(idx, 1);
        this.props.updateHours(newHours);
      }
    }
  }

  /**
   * fetch all the hours worked rows from the user
   * @param {Date} fromDate
   * @param {Date} tillDate
   * @param {Number} projectId
   */
  async fetchWorkerHours(
    fromDate,
    tillDate,
    allDates,
    projectId,
    workerId,
    projectsHelper,
    auditStatus = 1
  ) {
    var resp;
    if (
      projectId === undefined ||
      projectId === -1 ||
      projectId === "-1" ||
      (Array.isArray(projectId) && projectId[0] === -1)
    ) {
      if (!this.props.isShowingWorkers) {
        resp = await httpGetUsersHours(
          fromDate,
          tillDate,
          this.props.selectedProjectsIds[0],
          null,
          null,
          auditStatus
        );
      } else {
        resp = await httpGetUserHours(
          fromDate,
          tillDate,
          workerId,
          auditStatus
        );
      }
    } else if (projectId < freeDayShift) {
      if (this.props.isShowingWorkers) {
        if (!allDates) {
          resp = await httpGetUserHoursForProject(
            fromDate,
            tillDate,
            workerId,
            projectId,
            auditStatus
          );
        } else {
          resp = await httpGetAllUserHoursForProject(
            workerId,
            projectId,
            auditStatus
          );
        }
      } else {
        if (allDates) {
          resp = await httpGetAllUsersHoursForProject(
            projectId,
            this.state.skip,
            this.state.limit,
            this.props.selectedAuditOption.value
          );
        } else {
          //resp = await httpGetUsersHours(fromDate, tillDate, -1, null, null, auditStatus);
          resp = await httpGetUserHours(
            fromDate,
            tillDate,
            workerId,
            auditStatus
          );
        }
      }
    } else {
      resp = await httpGetUserHoursForFreeDay(
        fromDate,
        tillDate,
        workerId,
        projectId - freeDayShift
      );
    }
    if (resp.status === 200) {
      let hoursData = await resp.json();
      if (!this.props.isShowingWorkers) {
        hoursData = this.populateAvailableProjectsForUsers(hoursData);
      } else {
        hoursData.reverse();
        this.populateAvailableProjects(hoursData, projectsHelper);
      }
      this.props.updateHours([]);
      this.props.updateHours(hoursData);
    }
  }

  /**
   *  fetch all the project availabel between the 2 dates.
   * @param {Date} fromDate
   * @param {Date} tillDate
   * @param {Number} projectId
   */
  async getWorkerProjects(fromDate, tillDate, workerId, freeDayTypes) {
    let resp = await httpGetUserOwnProjects(fromDate, tillDate, workerId);
    if (resp.status === 200) {
      resp = await resp.json();
      resp.sort((a, b) => a.Name.localeCompare(b.Name));
      this.addFreeDayTypes(resp, freeDayTypes);
      return resp;
    }
  }

  addFreeDayTypes(projects, freeDayTypes) {
    freeDayTypes.forEach((type) => {
      projects.push({
        Name: freeDayPrefix + type.Name,
        ID: freeDayShift + type.ID,
      });
    });
  }

  async getManagerWorkers(startDate, endDate, cb = null) {
    let resp = await httpGetManagerWorkers(startDate, endDate);
    if (resp.status === 200) {
      resp = await resp.json();
      if (resp.length > 0) {
        return new ManagerWorkersHelper(resp);
      }
    }
    if (cb != null) cb();
  }

  setSelectedProjectsObjects(selectedProjectsObjects) {
    let isArray = Array.isArray(selectedProjectsObjects);
    let project = isArray
      ? selectedProjectsObjects[0].value
      : selectedProjectsObjects.value;
    if (this.props.userProjects.find((proj) => proj.ID === project)) {
      this.props.updateSelectedProjectsIds([project]);
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.onDatesChanged(this.props.startDate, this.props.endDate);
    }
    if (
      shallowCompareArrays(
        prevProps.selectedWorkersIds,
        this.props.selectedWorkersIds
      )
    ) {
      this.onWorkersChanged(this.props.selectedWorkersIds, false);
    }
    if (
      shallowCompareArrays(
        prevProps.selectedProjectsIds,
        this.props.selectedProjectsIds
      )
    ) {
      if (
        !(this.props.allSelected && this.props.selectedProjectsIds[0] === -1)
      ) {
        // same data
        this.onProjectsChanged(this.props.selectedProjectsIds, false);
      }
    }
    if (this.props.selectedProjectsIds && !prevProps.selectedProjectsIds) {
      this.props.updateSelectedProjectsIds(this.props.selectedProjectsIds[0]);
    }
    if (prevProps.allDates !== this.props.allDates) {
      this.resetSkipAndLimit(() => {
        this.onToggleAllDates(this.props.allDates);
      });
    }
    if (prevProps.isShowingWorkers !== this.props.isShowingWorkers) {
      this.resetSkipAndLimit();
      this.props.updateHours([]);
      if (this.props.isShowingWorkers) {
        this.fetchData(
          this.props.startDate,
          this.props.endDate,
          this.props.allDates,
          this.props.selectedProjectsIds[0],
          this.props.selectedWorkersIds[0],
          this.props.projectHelper
        );
        this.getProjectsWithFreeDays();
      } else {
        this.props.updateSelectedWorkersIds([this.props.user.userName]);
        this.props.updateSelectedWorkersObjects([
          {
            label: this.props.user.profile.name,
            value: this.props.user.userName,
          },
        ]);
        await this.getProjectHours();
        this.getProjectsWithFreeDays();
        this.setToAllProjects(true);
      }
    }
    if (prevProps.selectedAuditOption !== this.props.selectedAuditOption) {
      if (!this.props.isShowingWorkers) {
        this.resetSkipAndLimit(() => {
          let promise = this.getProjectHours();
          promise.then((data) => {
            this.updateProjectHours(data);
          });
        });
      } else {
        const projectId = this.props.selectedProjectIds
          ? this.props.selectedProjectIds[0]
          : -1;
        const userId = this.props.selectedWorkersIds
          ? this.props.selectedWorkersIds[0]
          : this.props.user.userName;
        this.fetchWorkerHours(
          this.props.startDate,
          this.props.endDate,
          this.props.allDates,
          projectId,
          userId,
          this.props.projectHelper,
          this.props.selectedAuditOption.value
        );
      }
    }
  }

  render() {
    const {
      alerts,
      timeFormat,
      hours,
      projectHelper,
      selectedProjectsIds,
      selectedWorkersIds,
      freeDayTypes,
      user,
      workedDetails,
      isShowingWorkers,
      selectedAuditOption,
      isLoading,
    } = this.props;
    if (this.props.currentPage !== "Hours")
      this.props.updateCurrentPage("Hours");
    return this.props.selectedProjectsIds ? (
      <>
        {isShowingWorkers && <Alerts alerts={alerts} />}
        <WorkedHoursList
          isLoading={isLoading}
          timeFormat={timeFormat}
          hourListData={hours}
          projectHelper={projectHelper}
          selectedProjectId={selectedProjectsIds[0]}
          workerId={selectedWorkersIds[0]}
          FreeDayType={freeDayTypes}
          fetchData={this.fetchCurrentData}
          currentUser={user}
          workedDetails={workedDetails}
          isShowingWorkers={isShowingWorkers}
          selectedAuditOption={selectedAuditOption}
          user={this.props.user}
        />
        {!isShowingWorkers &&
          !this.props.isLoading &&
          !this.state.retrievedAll &&
          hours.length > 0 && (
            <button className="load_more" onClick={this.loadMoreHandler}>
              Load more...
            </button>
          )}
      </>
    ) : "";
  }
}

const mapStateToProps = function (state) {
  return {
    isShowingWorkers: state.workers.isShowingWorkers,
    startDate: state.dates.startDate,
    endDate: state.dates.endDate,
    managerWorkers: state.workers.managerWorkers,
    managerProjects: state.projects.managerProjects,
    selectedWorkersIds: state.workers.selectedWorkersIds,
    selectedProjectsIds: state.projects.selectedProjectsIds,
    freeDayTypes: state.projects.freeDayTypes,
    allDates: state.dates.allDates,
    allDatesEnabled: state.dates.allDatesEnabled,
    currentPage: state.general.currentPage,
    selectedProjectsObjects: state.projects.selectedProjectsObjects,
    selectedWorkersObjects: state.workers.selectedWorkersObjects,
    timeFormat: state.dates.timeFormat,
    alerts: state.hours.alerts,
    userProjects: state.projects.userProjects,
    hours: state.hours.hours,
    workedDetails: state.hours.workedDetails,
    projectHelper: state.projects.projectHelper,
    selectedAuditOption: state.projects.selectedAuditOption,
    isLoading: state.projects.isLoading,
    allSelected: state.projects.allSelected,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateIsShowingWorkers,
        updateSelectedWorkersIds,
        updateSelectedProjectsIds,
        updateManagerProjects,
        updateCurrentPage,
        updateAllDates,
        updateAllDatesEnabled,
        updateSelectedProjectsObjects,
        updateSelectedWorkersObjects,
        updateTimeFormat,
        updateAlerts,
        updateUserProjects,
        updateHours,
        updateWorkedDetails,
        updateIsLoading,
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Hours);
