export default function shallowCompareArrays(left, right) {
  if (left === right) {
    return false;
  }

  if (left === undefined || right === undefined) {
    return true;
  }

  if (left.length !== right.length) {
    return true;
  }

  for (var i = 0; i < left.length; ++i) {
    if (left[i] !== right[i]) {
      return true;
    }
  }

  return false;
}
