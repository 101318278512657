/**
 * Created by nicom on 8/1/2017.
 */

import BackGroundColors from "./BackGroundColors";

class WorkerChartData {
  constructor(data, totalTime) {
    this.Data = data;
    this.totalTime = totalTime;
  }

  GetDataObject() {
    if (this.Data.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      };
    } else {
      let datalabels = [];
      let dataValues = [];

      this.Data.map((workerData) => {
        let workedTime = workerData.WorkedTime;
        if (workedTime !== 0) {
          dataValues.push(Math.round((workedTime / this.totalTime) * 100));
          datalabels.push(workerData.Project.Name);
        }
        return null;
      });
      let colors = BackGroundColors.getBackGroundColors(dataValues.length);
      return {
        labels: datalabels,
        datasets: [
          {
            backgroundColor: colors,
            data: dataValues,
          },
        ],
      };
    }
  }
}

export default WorkerChartData;
