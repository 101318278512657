import React, { Component } from "react";
import moment from "moment";
import ButtonsInRow from "../HoursPage/HoursData/ButtonsInRow";
import Confirm from "../Modals/Confirm";
import HoursActivityDataModel from "../../common/DataModels/HoursActivityDataModel";
import AdminActivityReportDataModel from "../../common/DataModels/AdminActivityReportDataModel";
import WorkedHourRow from "../HoursPage/HoursData/WorkedHourRow";
import { Input } from "../HoursPage/HoursData/Inputs";
import {
  getProjectIDByName,
  getProjectNameByID,
} from "../../common/ProjectsHelper";
import "../css/Hours.css";

//get by props the info to fill, include have props for gatekeepr entry and exit, if they exist the will be filled
//const projects = ["All Projects","A","B"];

///Todo: Bug when the save is enabel and the a project or description come back to the same value and then it false, no matter if the hour was changed.
class InfoRowHours extends Component {
  constructor(props) {
  
    super(props);
    this.state = {
      editable: false,
      saveDisable: [
        Input.Invalid,
        Input.Invalid,
        Input.NoChange,
        Input.Invalid,
      ],
      fromHour: null,
      tillHour: null,
      duration: null,
      project: "",
      calendar: this.props.calendar,
      description: "",
      jsonDescription: null,
      showConfirmModal: false,
    };
    
    this.editModeChange = this.editModeChange.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);
    this.checkAndUpdateStartHour = this.checkAndUpdateStartHour.bind(this);
    this.checkAndUpdateEndingHour = this.checkAndUpdateEndingHour.bind(this);
    this.setStartingData = this.setStartingData.bind(this);
    this.changedSelectedProjectAndUpdate = this.changedSelectedProjectAndUpdate.bind(
      this
    );
    this.changedDescriptionAndUpdate = this.changedDescriptionAndUpdate.bind(
      this
    );
    this.changedCalendarAndUpdate = this.changedCalendarAndUpdate.bind(this);
    this.calculateDuration = this.calculateDuration.bind(this);
    this.setStartingDataOfAddedRow = this.setStartingDataOfAddedRow.bind(this);
    this.setStartingDataOfExistingRow = this.setStartingDataOfExistingRow.bind(
      this
    );
    this.removeRow = this.removeRow.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.TimeValidation = this.TimeValidation.bind(this);
    this.checkHoursConflict = this.checkHoursConflict.bind(this);
  }

  componentDidMount() {
    this.setStartingData();
  }
  setStartingData() {
    if (this.props.rowData.ID === undefined) {
      this.setStartingDataOfAddedRow();
    } else {
      this.setStartingDataOfExistingRow();
    }
  }
  setStartingDataOfAddedRow() {
   
    let projectData = null;
    let projectName = "";
    let text=""
    let saveDisable = this.state.saveDisable;
    if (
      this.props.projectsAllowed != null &&
      this.props.projectsAllowed.length <= 2
    ) {
      projectData = this.props.projectsAllowed[0];
      projectName = projectData.Name;
    } else {
      if (!this.props.showCalendar) saveDisable[2] = Input.Invalid;
    }

    let fromHour = null;
    let tillHour = null;
    let duration = null;
    if (this.props.rowData.frm !== "") {
      fromHour = moment(this.props.rowData.frm, this.props.timeFormat);
      this.checkAndUpdateStartHour(fromHour);
    }
    if (this.props.rowData.till !== "") {
      tillHour = moment(this.props.rowData.till, this.props.timeFormat);
      this.checkAndUpdateEndingHour(tillHour);
    }
    if (this.props.rowData.description !== "") {
      text=this.props.rowData.description
      
    }
    
    if (this.props.rowData.project !== "") {
      projectData=this.props.projectsAllowed.find(
        (p) => p.ID === this.props.rowData.project
      );
      projectName=projectData.Name
    }
    if (fromHour !== null && tillHour !== null) {
      duration = moment.duration(tillHour.diff(fromHour));
    }

    this.setState({
      fromHour: fromHour,
      tillHour: tillHour,
      duration: duration,
      project: projectName,
      calendar: this.props.calendar,
      description: text,
      jsonDescription: null,
      editable: true,
      saveDisable: saveDisable,
    });

    if (this.props.rowData.frm !== "") {
      this.checkAndUpdateStartHour(fromHour);
    }
    if (this.props.rowData.till !== "") {
      this.checkAndUpdateEndingHour(tillHour);
    }
  }

  setStartingDataOfExistingRow() {
    const {
      ProjectID,
      Description,
      JsonDescription,
      Duration,
      StartTime,
      CalendarID,
    } = this.props.rowData;
    const { projectHelper } = this.props;
    let timeArray = this.calculateDuration(StartTime, Duration);
    let projectData = getProjectNameByID(ProjectID, projectHelper);
    let calendar = { Id: "", Name: "" };
    if (this.props.showCalendar) {
      const result = this.props.calendarsList.find((c) => c.Id === CalendarID);
      if (result) calendar = result;
    }

    this.setState({
      fromHour: timeArray[0],
      tillHour: timeArray[1],
      duration: moment.duration(Duration),
      project: projectData.Name,
      calendar,
      description: Description,
      jsonDescription: JsonDescription,
      saveDisable: [
        Input.NoChange,
        Input.NoChange,
        Input.NoChange,
        Input.NoChange,
      ],
    });
  }

  editModeChange() {
    //we are switching from non-editable to editable state.
    //Make sure that the allowed projects list is populated
    if (!this.state.editable) {
      this.props.ensureAllowedProjects();
    }
    this.setState({ editable: !this.state.editable });
  }

  saveClicked() {
    
    this.editModeChange();
    const {
      fromHour,
      tillHour,
      project,
      description,
      jsonDescription,
      calendar,
    } = this.state;
    const { currentDay, rowData, showCalendar } = this.props;

    let time = moment(fromHour, this.props.timeFormat, true);
    let tillMoment = moment(tillHour, this.props.timeFormat, true);
    let duration = tillMoment.diff(time);
    let formattedDuration = moment.utc(duration).format("HH:mm");

    let projectData = getProjectIDByName(project, this.props.projectHelper);
    let employeeID = rowData.EmployeeID
      ? rowData.EmployeeID
      : this.props.employeeID;
    let dataToSend;
    if (!showCalendar) {
      dataToSend = new HoursActivityDataModel(
        rowData.ID,
        projectData.ID,
        currentDay,
        fromHour.format("HH:mm"),
        formattedDuration,
        description,
        jsonDescription,
        this.props.workerId,
        this.props.selectedAuditOption.value === 2 ? true : false,
        employeeID
      );
    } else {
      dataToSend = new AdminActivityReportDataModel(
        rowData.ID,
        calendar.Id,
        currentDay,
        fromHour.format("HH:mm"),
        formattedDuration,
        description,
        jsonDescription
      );
    }
    this.props.updateOrAddRow(dataToSend);

    if (rowData.ID === undefined)
    {
      this.props.removeRow();
   }
  }

  cancelClicked() {
    if (this.props.rowData.ID === undefined) {
      this.props.removeRow();
    } else {
      this.setState({
        saveDisable: [
          Input.NoChange,
          Input.NoChange,
          Input.NoChange,
          Input.NoChange,
        ],
      });
      this.editModeChange();
      this.setStartingData();
    }
  }

  open() {
    this.setState({ showConfirmModal: true });
  }

  close() {
    this.setState({ showConfirmModal: false });
  }

  removeRow() {
    this.close();
    this.props.removeRow(this.props.rowData);
  }

  render() {
    const {
      editable,
      fromHour,
      tillHour,
      duration,
      project,
      description,
      saveDisable,
      jsonDescription,
      calendar,
    } = this.state;
    const {
      projectHelper,
      projectsAvailable,
      projectsAllowed,
      rowData,
      selectedAuditOption,
      deletedIds,
      showCalendar,
      calendarsList,
    } = this.props;
    let isNew = rowData.Action === 2;
    let doNotRender = rowData.Action === 3;
    return ( 
      !doNotRender && (
        <div
          className={
           
            "InfoRowHours " +
            (rowData.ReportAdminId !== null && !showCalendar && !editable
              ? "rowContainer adminDeclaration"
              : this.props.selectedAuditOption.value === 3 &&
                !rowData.Action &&
                deletedIds &&
                deletedIds.includes(rowData.ID)
              ? "rowContainer deletedRow"
              : isNew &&
                selectedAuditOption.value === 3 &&
                !this.props.isLoading
              ? "rowContainer addedRow"
              : "rowContainer")
          }
        >
          <WorkedHourRow
            timeFormat={this.props.timeFormat}
            projectHelper={projectHelper}
            projectsAvailable={projectsAvailable}
            projectsAllowed={projectsAllowed}
            editable={editable}
            fromHour={fromHour}
            checkAndUpdateStartHour={this.checkAndUpdateStartHour}
            tillHour={tillHour}
            duration={duration}
            checkAndUpdateEndingHour={this.checkAndUpdateEndingHour}
            project={project}
            changedSelectedProjectAndUpdate={
              this.changedSelectedProjectAndUpdate
            }
            description={description}
            jsonDescription={jsonDescription}
            changedDescriptionAndUpdate={this.changedDescriptionAndUpdate}
            invalidInput={saveDisable}
            showCalendar={showCalendar}
            calendar={calendar}
            changedCalendarAndUpdate={this.changedCalendarAndUpdate}
            calendarsList={calendarsList}
            ReportAdminId={rowData.ReportAdminId}
          />
          {!this.props.isLoading &&
            this.props.selectedAuditOption.value === 3 &&
            (rowData.Action ||
              (deletedIds && deletedIds.includes(rowData.ID))) && (
              <span>
                <span className={"badge"}>
                  {rowData.Action === 1
                    ? "Modified"
                    : rowData.Action === 2
                    ? "Added"
                    : "Deleted"}
                </span>
              </span>
            )}
          {selectedAuditOption.value !== 3 && (
            <ButtonsInRow
              saveDisable={saveDisable}
              saveClicked={this.saveClicked}
              cancelClicked={this.cancelClicked}
              editable={editable}
              editModeChange={this.editModeChange}
              open={this.open}
            />
          )}
          <Confirm
            open={this.open}
            close={this.close}
            showModal={this.state.showConfirmModal}
            onConfirm={this.removeRow}
            title="Remove Hour Activity Row"
            message="Are you sure?"
          />
        </div>
      )
    );
  }

  ///Input Check
  calculateDuration(StartTime, Duration) {
   
    let startTime = moment(StartTime, "HH:mm");
    let duration = moment(Duration, "HH:mm");
    let endTime = startTime
      .clone()
      .add(duration.hours(), "h")
      .add(duration.minutes(), "m");
    return [startTime, endTime];
  }

  checkHoursConflict(startTime, endTime, rowID) {
    let startConfilct = false;
    let endConflict = false;
    const { HoursWorked } = this.props;
    HoursWorked.map((rowData) => {
      if (rowData.ID !== rowID) {
        let rowTimes = this.calculateDuration(
          rowData.StartTime,
          rowData.Duration
        );
        if (startTime.isBetween(rowTimes[0], rowTimes[1], null, "[)"))
          startConfilct = true;
        if (endTime.isBetween(rowTimes[0], rowTimes[1], null, "(]"))
          endConflict = true;
        if (
          startTime.isSameOrBefore(rowTimes[0]) &&
          endTime.isSameOrAfter(rowTimes[1])
        ) {
          startConfilct = true;
          endConflict = true;
        }
      }
      return null;
    });
    return [startConfilct, endConflict];
  }

  checkAndUpdateStartHour(time) {
    if (typeof time === "string") {
      time = time.replace(" ", "");
      time = moment(time, this.props.timeFormat, true);
    }
    if (moment.isMoment(time) && time.isValid()) {
      let endTime = this.state.tillHour;
      let disable = this.TimeValidation(time, 0, endTime, 1, false);
      let duration = null;
      if (endTime !== null) {
        const { rowData } = this.props;
        let ID = rowData.ID === undefined ? -1 : rowData.ID;
        let conflictValues = this.checkHoursConflict(time, endTime, ID);

        if (conflictValues[0]) disable[0] = Input.Invalid;
        if (conflictValues[1]) disable[1] = Input.Invalid;
        if (time.isBefore(endTime))
          duration = moment.duration(endTime.diff(time));
      }
      this.setState({
        fromHour: time,
        duration: duration,
        saveDisable: disable,
      });
    }
  }

  checkAndUpdateEndingHour(time) {
    if (typeof time === "string") {
      time = time.replace(" ", "");
      time = moment(time, this.props.timeFormat, true);
    }

    if (moment.isMoment(time) && time.isValid()) {
      let startTime = this.state.fromHour;
      let disable = this.TimeValidation(time, 1, startTime, 0, true);
      let duration = null;
      if (startTime !== null) {
        const { rowData } = this.props;
        let ID = rowData.ID === undefined ? -1 : rowData.ID;
        let conflictValues = this.checkHoursConflict(startTime, time, ID);

        if (conflictValues[0]) disable[0] = Input.Invalid;
        if (conflictValues[1]) disable[1] = Input.Invalid;
        if (startTime.isBefore(time))
          duration = moment.duration(time.diff(startTime));
      }
      this.setState({
        tillHour: time,
        duration: duration,
        saveDisable: disable,
      });
    }
  }

  TimeValidation(newTime, thisIndex, secondTime, secondIndex, isTillHour) {
    let disable = this.state.saveDisable;
    let isDisable = false;
    if (secondTime != null) {
      if (isTillHour) {
        if (newTime.isBefore(secondTime)) {
          disable[thisIndex] = Input.Invalid;
          disable[secondIndex] = Input.Invalid;
          isDisable = true;
        }
      } else {
        if (newTime.isAfter(secondTime)) {
          disable[thisIndex] = Input.Invalid;
          disable[secondIndex] = Input.Invalid;
          isDisable = true;
        }
      }
    }
    if (!isDisable) {
      if (this.props.rowData.ID !== undefined) {
        const { Duration, StartTime } = this.props.rowData;
        let time = this.calculateDuration(StartTime, Duration);
        disable[thisIndex] = newTime.isSame(time[thisIndex])
          ? Input.NoChange
          : Input.Change;
        disable[secondIndex] = secondTime.isSame(time[secondIndex])
          ? Input.NoChange
          : Input.Change;
      } else {
        disable[thisIndex] = Input.Change;
        if (secondTime != null) {
          disable[secondIndex] = Input.Change;
        }
      }
    }
    return disable;
  }

  changedSelectedProjectAndUpdate(e) {
    let saveDisable = this.state.saveDisable;
    let projectSelected = e.target.value;
    if (projectSelected === "empty" || projectSelected === undefined) {
      saveDisable[2] = Input.Invalid;
    } else {
      if (this.props.rowData.ProjectID !== undefined) {
        let rowProject = getProjectNameByID(
          this.props.rowData.ProjectID,
          this.props.projectHelper
        );
        saveDisable[2] =
          rowProject.Name === e.target.value ? Input.NoChange : Input.Change;
      } else {
        saveDisable[2] = Input.Change;
      }
    }

    this.setState({ project: projectSelected, saveDisable });
  }

  changedCalendarAndUpdate(e) {
    let saveDisable = this.state.saveDisable;
    const calendarSelected = e.target.value;
    if (calendarSelected === "empty" || calendarSelected === undefined) {
      saveDisable[2] = Input.Invalid;
    } else {
      if (this.props.rowData.CalendarID !== undefined) {
        let calendarObj = this.props.calendarsList.find(
          (c) => c.Id === this.props.rowData.CalendarID
        );
        saveDisable[2] =
          calendarObj.Name === calendarSelected ? Input.NoChange : Input.Change;
      } else {
        saveDisable[2] = Input.Change;
      }
    }
    const calendar = this.props.calendarsList.find(
      (c) => c.Name === e.target.value
    );

    this.setState({ calendar });
  }

  changedDescriptionAndUpdate(value, plainText) {
    let saveDisable = this.state.saveDisable;
    if (plainText.trim() === "") {
      saveDisable[3] = Input.Invalid;
    } else {
      if (this.props.rowData.ID !== undefined) {
        saveDisable[3] =
          value === this.props.rowData.JsonDescription
            ? Input.NoChange
            : Input.Change;
      } else {
        saveDisable[3] = Input.Change;
      }
    }
    this.setState({
      description: plainText,
      jsonDescription: value,
      saveDisable,
    });
  }
}

export default InfoRowHours;
