/**
 * Created by nicom on 8/19/2017.
 */

import { en } from "./English";
import { he } from "./Hebrew";
class Language {
  static Text = en;

  static changeText(language) {
    switch (language) {
      case 0:
        this.Text = en;
        break;
      case 1:
        this.Text = he;
        break;
      default:
        this.Text = en;
        break;
    }
  }
}

export default Language;
