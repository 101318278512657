/**
 * Created by nicom on 5/2/2017.
 */

import React, { Component } from "react";
import WorkedHourItem from "./WorkedHourItem";
import "../../css/Hours.css";
import _ from "lodash";

class WorkedHoursList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hourListData: this.props.hourListData.slice(0, 1),
      prevAudit: 1,
    };
    this.renderWorkedHourItems = this.renderWorkedHourItems.bind(this);
    this.recursive = this.recursive.bind(this);
  }

  recursive = () => {
    setTimeout(() => {
      let hasMore =
        this.state.hourListData.length + 1 < this.props.hourListData.length;
      this.setState((prev, props) => ({
        hourListData: props.hourListData.slice(0, prev.hourListData.length + 1),
      }));
      if (hasMore) this.recursive();
    }, 0);
  };

  componentDidMount() {
    this.recursive();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hourListData !== this.props.hourListData) {
      this.recursive();
    }
    if (prevProps.selectedAuditOption !== this.props.selectedAuditOption) {
      this.setState({ prevAudit: prevProps.selectedAuditOption.value });
    }
    if (
      !_.isEqual(prevProps.hourListData, this.props.hourListData) &&
      prevProps.selectedAuditOption.value !==
        this.props.selectedAuditOption.value
    ) {
      this.setState({ hourListData: [] }, () => this.recursive());
    }
  }

  renderWorkedHourItems() {
    return this.state.hourListData.map((dayData, index) => {
      const { FreeDayType, fetchData } = this.props;
      return (
        <WorkedHourItem
          isLoading={this.props.isLoading}
          key={dayData.Day.Date + dayData.Name + index}
          timeFormat={this.props.timeFormat}
          dayData={dayData}
          projectHelper={this.props.projectHelper}
          workerId={dayData.Email} //{this.props.workerId}
          FreeDayType={FreeDayType}
          fetchData={fetchData}
          isShowingWorkers={this.props.isShowingWorkers}
          selectedAuditOption={this.props.selectedAuditOption}
          user={this.props.user}
        />
      );
    });
  }
  render() {
    let summaryString = `${this.props.workedDetails.WorkedHours} from minimum of ${this.props.workedDetails.FullWorkedTime} hours worked on all projects combined`;
    
    return (
      <div className="listContainer">
        {this.props.isShowingWorkers && (
          <div className="hoursWorkedText">
            {summaryString}
            <div className="report-sep">
              <div className="report-sep-bullet left"></div>
              <div className="report-sep-bullet right"></div>
            </div>
          </div>
        )}
        <div>{this.renderWorkedHourItems()}</div>
      </div>
    );
  }
}

export default WorkedHoursList;
