/**
 * Created by nicom on 8/19/2017.
 */

export const en = {
  hours: "Hours",
  dashBoard: "Dash Board",
  settings: "Settings",
  help: "Help",
  signOut: "Sign out",
  allProjects: "All Projects",
  me: "Me",
  fromDate: "From Date",
  toDate: "to Date",
  export: "Export",
  prevMonth: "Prev Month",
  prevWeek: "Prev Week",
  thisWeek: "This Week",
  thisMonth: "This Month",
  custom: "Custom",
  outOfOffice: "Out of Office",
  alerts: "Alerts",
  addNew: "+ Add New",
  addOut: "+ Add Out of",
  today: "Today",
  edit: "Edit",
  save: "Save",
  cancel: "Cancel",
  freeDayDeclaration: "Free Day Declaration",
  language: "Language",
  time: "Time Format",
  close: "Close",
  confirm: "Confirm",
  removeFreeText: "Remove Free Day Declaration",
  removeHourText: "Remove Hour Activity Row",
  removeSubTittle: "Are you sure?",
  firstDay: "first day",
  lastDay: "last day",
  reset: "reset",
  chooseCustom: "Choose custom dates",
  pleaseSelect: "Please Select the",
  chooseFrom: "You choose from",
  chooseTill: "to",
  english: "English",
  hebrew: "Hebrew",
  selectPercentage: "Select Percentage",
  days: "Day/s",
  selectType: "Select type",
  clear: "Clear",
  from: "from",
  hoursWorked: "hours worked (avg ",
  fromDay: "hours a day)",
  firstEntry: "First Entry",
  lastExit: "Last Exit",
};
