/**
 * Created by nicom on 7/27/2017.
 */
import React, { Component } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import ApprovalState from "../../ApprovalState/ApprovalState";

const DAY_FORMAT = "DD/MM/YYYY";

class PlanRowDataView extends Component {
  constructor(props) {
    super(props);
    this.showInvalidInput = this.showInvalidInput.bind(this);
  }

  showInvalidInput(inputId) {
    if (this.props.invalidInput[inputId] === "Invalid") {
      return <div style={{ color: "red" }}>X</div>;
    }
  }

  renderPlansTypes() {
    return this.props.plansTypes.map((type) => {
      return (
        <option key={type.ID} value={type.ID}>
          {type.Name}
        </option>
      );
    });
  }

  render() {
    const {
      checkAndUpdateDescription,
      isShowingDescription,
      editable,
      fromDate,
      checkAndUpdateStartDate,
      tillDate,
      checkAndUpdateEndingDate,
      checkAndUpdatePlanType,
      checkAndUpdatePercentage,
      planType,
      percentage,
      description,
      approvalId,
    } = this.props;
    return (
      <div className={"textInputInRowHour"}>
        <div className="freeDayWrapperPlanner">
          <div className="durationWraperPlanner">
            <div className="timeStart">
              <DayPickerInput
                format={DAY_FORMAT}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder="From"
                disabled={!editable}
                value={fromDate.format(DAY_FORMAT)}
                onDayChange={checkAndUpdateStartDate}
                inputProps={{
                  className: "plan-date-picker",
                }}
              />
              {this.showInvalidInput(0)}
            </div>
            <div className="drawLine">{" ━ "}</div>
            <div className="timeEnd">
              <DayPickerInput
                format={DAY_FORMAT}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder="Until"
                disabled={!editable}
                value={tillDate.format(DAY_FORMAT)}
                onDayChange={checkAndUpdateEndingDate}
                inputProps={{
                  className: "plan-date-picker",
                }}
              />
              {this.showInvalidInput(1)}
            </div>
          </div>
          <div className="freeDropdownPlanner">
            <FormGroup>
              <FormControl
                disabled={!editable}
                bsPrefix="formControlText"
                as="select"
                value={planType}
                onChange={checkAndUpdatePlanType}
              >
                {this.renderPlansTypes()}
              </FormControl>
            </FormGroup>
          </div>

          {isShowingDescription && (
            <input
              className="editPlan"
              value={description}
              type="text"
              placeholder="Add a short description ..."
              onChange={checkAndUpdateDescription}
              disabled={!editable}
            ></input>
          )}

          <div className="percentDropDownPlanner">
            <FormGroup>
              <FormControl
                disabled={
                  !editable || fromDate.toString() !== tillDate.toString()
                }
                bsPrefix="formControlText percent"
                as="select"
                value={percentage}
                onChange={(e) => checkAndUpdatePercentage(e.target.value)}
              >
                <option value={100}>100 </option>
                <option value={50}>50 </option>
              </FormControl>
            </FormGroup>
          </div>
          {!editable && this.props.totalDays > 0 && (
            <p className="total-days">Total days: {this.props.totalDays}</p>
          )}
          {!editable && <ApprovalState approvalId={approvalId} />}
        </div>
      </div>
    );
  }
}

export default PlanRowDataView;
