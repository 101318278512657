import React, { Component } from "react";
import { batchActions } from "redux-batched-actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HoursList from "./AdminData/HoursList";
import {
  updateCurrentPage,
  updateAdminHours,
  updateTimeFormat,
  updateCalendars,
} from "../../stores/actions";
import { httpGetAllHoursAdmin, httpGetCalendars } from "../../Urls";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.fetchCurrentData = this.fetchCurrentData.bind(this);
    let validTimeFormats = ["H:mm", "h:mm A"];
    let timeFormat = validTimeFormats[0];
    let settings = localStorage.getItem("settings");
    if (settings) {
      try {
        let format = JSON.parse(settings).timeFormat;
        if (validTimeFormats.indexOf(format) >= 0) {
          timeFormat = format;
        }
      } catch (e) {}
    }
    this.props.dispatch(batchActions([updateTimeFormat(timeFormat)]));
  }
  render() {
    const {
      timeFormat,
      hoursAdmin,
      projectHelper,
      selectedProjectsIds,
      freeDayTypes,
      isLoading,
    } = this.props;
    return (
      <HoursList
        isLoading={isLoading}
        timeFormat={timeFormat}
        hourListData={hoursAdmin}
        projectHelper={projectHelper}
        selectedProjectId={selectedProjectsIds[0]}
        FreeDayType={freeDayTypes}
        fetchData={this.fetchCurrentData}
      />
    );
  }
  componentDidMount() {
    this.props.dispatch(batchActions([updateCurrentPage("Admin")]));
    this.fetchCurrentData();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchHoursAdmin();
    }
  }
  async fetchHoursAdmin() {
    const resp = await httpGetAllHoursAdmin(
      this.props.startDate,
      this.props.endDate
    );
    if (resp.status === 200) {
      let data = await resp.json();
      this.props.dispatch(batchActions([updateAdminHours(data)]));
    }
  }
  async fetchCalendars() {
    const resp = await httpGetCalendars();
    if (resp.status === 200) {
      let data = await resp.json();
      this.props.dispatch(batchActions([updateCalendars(data)]));
    }
  }
  fetchCurrentData() {
    this.fetchHoursAdmin();
    this.fetchCalendars();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateCurrentPage,
        updateAdminHours,
        updateTimeFormat,
        updateCalendars,
      },
      dispatch
    ),
    dispatch,
  };
}
const mapStateToProps = function (state) {
  return {
    currentPage: state.general.currentPage,
    timeFormat: state.dates.timeFormat,
    hoursAdmin: state.admin.hours,
    projectHelper: state.projects.projectHelper,
    selectedProjectsIds: state.projects.selectedProjectsIds,
    freeDayTypes: state.projects.freeDayTypes,
    isLoading: state.projects.isLoading,
    startDate: state.dates.startDate,
    endDate: state.dates.endDate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
