/**
 * Created by nicom on 7/29/2017.
 */

class FreeDayReportDataModel {
  constructor(
    id,
    freeDayID,
    CalendarDayId,
    percentage,
    description = "",
    approved = 1,
    reportAdminId = null
  ) {
    this.ID = id;
    this.FreeDayID = freeDayID;
    this.CalendarDayId = CalendarDayId;
    this.Percentage = percentage;
    this.Description = description;
    this.Approved = approved;
    this.ReportAdminId = reportAdminId;
  }
}

export default FreeDayReportDataModel;
