import {
  UPDATE_CALENDARS,
  UPDATE_CURRENT_PAGE,
  UPDATE_HEADER_SHOW_MODAL,
  UPDATE_USER_GROUPS,
} from "../actionTypes";

var defaultState = {
  currentPage: null,
  headerShowModal: false,
  userGroups: [],
  calendars: [],
};

export default function general(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.page };
    case UPDATE_HEADER_SHOW_MODAL:
      return { ...state, headerShowModal: action.value };
    case UPDATE_USER_GROUPS:
      return { ...state, userGroups: action.value };
    case UPDATE_CALENDARS:
      return { ...state, calendars: action.calendars };

    default:
      return state;
  }
}
