/**
 * Created by nicom on 7/30/2017.
 */

import React, { Component } from "react";
import CustomChart from "./CustomChart";
import Report from "./Report";
import { connect } from "react-redux";
import shallowCompareArrays from "./../../common/ShallowComparer";

import {
  httpGetManagerProjects,
  httpGetManagerWorkers,
  httpGetAllProjectsWorkTime,
  httpGetAllUsersWorkTime,
  httpGetMissingEmployees,
} from "../../Urls";
import WorkerParagraphData from "./WorkerParagraphData";
import WorkerChartData from "./WorkerChartData";
import ProjectParagraphData from "./ProjectParagraphData";
import ProjectChartData from "./ProjectChartData";
import ManagerWorkersHelper from "../../common/ManagerWorkersHelper";
import { Button, Modal } from "react-bootstrap";
import {
  updateCurrentPage,
  updateShowAbsentEmployeesModal,
  updateAllManagerProjects,
} from "../../stores/actions";

let storedState = {
  projectsData: {},
  usersData: {},
  isMounted: true,
  selectedCurrentProjectIds: [],
  selectedCurrentWorkersIds: [],
};

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, storedState);

    this.reportAndCloseModal = this.reportAndCloseModal.bind(this);
    this.onWorkersChanged = this.onWorkersChanged.bind(this);
    this.onProjectsChanged = this.onProjectsChanged.bind(this);
    this.onDatesChanged = this.onDatesChanged.bind(this);
    this.renderData = this.renderData.bind(this);
    this.renderHROptions = this.renderHROptions.bind(this);
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.onDatesChanged(this.props.startDate, this.props.endDate);
    }
    if (
      prevProps.selectedWorkersIds !== this.props.selectedWorkersIds && // do not refetch worker data when all is selected b/c project selected
      (shallowCompareArrays(
        prevProps.selectedProjectsIds === this.props.selectedProjectsIds
      ) ||
        prevProps.selectedProjectsIds === -1) &&
      this.props.startDate === prevProps.startDate &&
      this.props.endDate === prevProps.endDate
    ) {
      this.onWorkersChanged(this.props.selectedWorkersIds);
    }
    if (
      shallowCompareArrays(
        prevProps.selectedProjectsIds,
        this.props.selectedProjectsIds
      )
    ) {
      this.onProjectsChanged(this.props.selectedProjectsIds);
    }
    if (this.props.isShowingWorkers !== prevProps.isShowingWorkers) {
      this.props.isShowingWorkers
        ? this.fetchWorkersHours(
            this.props.startDate,
            this.props.endDate,
            this.props.selectedWorkersIds,
            this.props.selectedAuditOption.value
          )
        : this.fetchProjectsHours(
            this.props.startDate,
            this.props.endDate,
            this.props.selectedProjectsIds,
            this.props.selectedAuditOption.value
          );
    }
    if (this.props.selectedAuditOption !== prevProps.selectedAuditOption) {
      this.props.isShowingWorkers
        ? this.fetchWorkersHours(
            this.props.startDate,
            this.props.endDate,
            this.props.selectedWorkersIds,
            this.props.selectedAuditOption.value
          )
        : this.fetchProjectsHours(
            this.props.startDate,
            this.props.endDate,
            this.props.selectedProjectsIds,
            this.props.selectedAuditOption.value
          );
    }
  }

  onWorkersChanged(selectedWorkersIds) {
    this.fetchWorkersHours(
      this.props.startDate,
      this.props.endDate,
      selectedWorkersIds,
      this.props.selectedAuditOption.value
    );
  }

  onProjectsChanged(selectedStringProjectsIds) {
    let selectedProjectsIds = selectedStringProjectsIds.map(
      (stringProjectId) => {
        return parseInt(stringProjectId, 10);
      }
    );

    this.fetchProjectsHours(
      this.props.startDate,
      this.props.endDate,
      selectedProjectsIds,
      this.props.selectedAuditOption.value
    );
  }

  async onDatesChanged(startDate, endDate) {
    if (startDate === null) {
      startDate = this.props.startDate;
    }

    if (endDate === null) {
      endDate = this.props.endDate;
    }

    this.setState({
      usersData: {},
      projectsData: {},
    });

    await this.fetchManagerProjects(startDate, endDate);

    if (!this.state.isMounted) return;
    if (this.props.isShowingWorkers) {
      this.fetchWorkersHours(
        startDate,
        endDate,
        this.props.selectedWorkersIds,
        this.props.selectedAuditOption.value
      );
    } else {
      this.fetchProjectsHours(
        startDate,
        endDate,
        this.props.selectedProjectsIds,
        this.props.selectedAuditOption.value
      );
    }

    //if (!this.state.isMounted) return;
    //this.fetchProjectsHours(startDate, endDate, this.props.selectedProjectsIds);
  }

  fetchWorkersHours(startDate, endDate, workerIds, auditStatus = 1) {
    if (
      !workerIds ||
      workerIds.length === 0 ||
      !workerIds[0] ||
      !this.props.managerWorkers ||
      this.props.managerWorkers.length === 0
    )
      return;
    let workerIdsEmails = workerIds.map(
      (val) =>
        this.props.managerWorkers.Workers.find(
          (worker) => worker.Id.toLowerCase() === val.toLowerCase()
        ).EmployeeId
    );
    if (!Array.isArray(workerIdsEmails)) {
      workerIdsEmails = [workerIdsEmails];
    }
    if (isNaN(workerIdsEmails[0])) workerIdsEmails.shift();
    return httpGetAllUsersWorkTime(
      startDate,
      endDate,
      workerIdsEmails,
      auditStatus === 2
    ).then((resp) => {
      if (resp.status === 200) {
        return resp.json().then((resp) => {
          const results = this.mapUsersToWorkerTimes(resp);
          let usersData = {};

          results.forEach((val) => {
            const workerObject = this.getWorkerName(parseInt(val.id, 10));
            let paragraphData = new WorkerParagraphData(
              val.data.ProjectTimes,
              workerObject.name,
              val.data.TotalHours,
              val.data.AbsentTotals
            );

            let chartData = new WorkerChartData(
              val.data.ProjectTimes,
              val.data.TotalHours
            );
            usersData = this.state.usersData;
            usersData[workerObject.email.toLowerCase()] = {
              chartData,
              paragraphData,
            };
          });
          if (!this.state.isMounted) return;
          this.setState({ usersData: usersData });
        });
      }
    });
  }

  async fetchProjectsHours(startDate, endDate, projectIds, auditStatus = 1) {
    if (projectIds.length === 0) return;
    if (isNaN(projectIds[0])) projectIds.shift();
    let resp = await httpGetAllProjectsWorkTime(
      startDate,
      endDate,
      projectIds,
      auditStatus
    );
    if (resp.status === 200) {
      let projectsData = [];
      resp = await resp.json();
      const results = this.mapHoursToUserTimes(resp);
      results.forEach((val) => {
        let paragraphData = new ProjectParagraphData(
          val.data.UserTimes,
          this.getProjectName(parseInt(val.id, 10)),
          val.data.TotalHours
        );
        let chartData = new ProjectChartData(
          val.data.UserTimes,
          val.data.TotalHours
        );
        projectsData = this.state.projectsData;
        projectsData[val.id] = { chartData, paragraphData };
      });
      if (!this.state.isMounted) return;
      this.setState({ projectsData: projectsData });
    }
  }

  mapHoursToUserTimes(resp) {
    let results = [];
    for (var prop in resp.TotalHours) {
      if (Object.prototype.hasOwnProperty.call(resp.TotalHours, prop)) {
        let result = {};
        result.id = prop;
        result.data = {
          TotalHours: resp.TotalHours[prop],
          UserTimes: resp.UserTimes[prop],
        };
        results.push(result);
      }
    }
    return results;
  }

  mapUsersToWorkerTimes(resp) {
    let results = [];
    for (var prop in resp.TotalHours) {
      if (Object.prototype.hasOwnProperty.call(resp.TotalHours, prop)) {
        let result = {};
        result.id = prop;
        result.data = {
          ProjectTimes: resp.ProjectTimes[prop],
          TotalHours: resp.TotalHours[prop],
          AbsentTotals: {
            AbsentPerType: resp.AbsentTotals.AbsentPerType[prop],
            TotalDays: resp.AbsentTotals.TotalDays[prop],
          },
        };
        results.push(result);
      }
    }
    return results;
  }

  async fetchManagerWorkers(startDate, endDate, cb = null) {
    let resp = await httpGetManagerWorkers(startDate, endDate);
    if (resp.status === 200) {
      resp = await resp.json();
      if (resp.length > 0) {
        let managerWorkers = new ManagerWorkersHelper(resp);
        let managerWorkersIds = managerWorkers.Workers.map((w) => w.Id);
        let selectedWorkersIds = this.props.selectedWorkersIds.filter(
          (workerId) => managerWorkersIds.indexOf(workerId) !== -1
        );
        if (selectedWorkersIds.length === 0) {
          selectedWorkersIds = [this.props.user.userName];
        }
      }
    }
    if (cb !== null) cb();
  }

  async fetchManagerProjects(startDate, endDate, cb = null) {
    let resp = await httpGetManagerProjects(startDate, endDate);
    if (resp.status === 200) {
      let managerProjects = await resp.json();
      managerProjects.sort((a, b) => a.Name.localeCompare(b.Name));
      if (managerProjects.length > 0) {
        this.props.updateAllManagerProjects(managerProjects);
      }
    }
    if (cb !== null) cb();
  }

  getWorkerName(workerId) {
    if (this.props.managerWorkers.Workers !== undefined) {
      let worker = this.props.managerWorkers.Workers.find(
        (w) => w.EmployeeId === workerId
      );
      if (worker !== undefined)
        return { name: worker.EngName, email: worker.Id };
    }
  }

  getProjectName(projectId) {
    if (!(projectId <= 0 || (projectId >= 1001 && projectId <= 1105))) {
      if (this.props.managerProjects !== undefined) {
        var project = this.props.managerProjects.find(
          (p) => p.ID === projectId
        );
        return project ? project.Name : project;
      }
    }
  }

  renderData() {
    if (this.props.isShowingWorkers) {
      return (
        this.props.managerWorkers.Workers &&
        this.props.managerWorkers.Workers.map((worker) => {
          let workerId = worker.Id.toLowerCase();
          if (
            this.props.selectedWorkersIds.indexOf(worker.Id) >= 0 &&
            this.state.usersData.hasOwnProperty(workerId)
          ) {
            let data = this.state.usersData[workerId];
            let chart = null;
            if (data.chartData.Data && data.chartData.Data.length > 0) {
              chart = <CustomChart data={data.chartData} />;
            }
            return (
              <div key={workerId} className="reportContainer">
                <div
                  style={{
                    width: "70%",
                    marginLeft: "15%",
                    textAlign: "center",
                    fontSize: "24px",
                    color: "grey",
                  }}
                >
                  {worker.EngName}
                </div>
                {chart}
                <Report
                  data={data.paragraphData}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                />
              </div>
            );
          }
          return null;
        })
      );
    } else {
      return this.props.managerProjects.map((project) => {
        let projectId = project.ID;
        if (
          this.props.selectedProjectsIds.indexOf(projectId) >= 0 &&
          this.state.projectsData.hasOwnProperty(projectId)
        ) {
          let data = this.state.projectsData[projectId];
          return (
            <div key={projectId} className="reportContainer">
              <div
                style={{
                  width: "70%",
                  marginLeft: "15%",
                  textAlign: "center",
                  fontSize: "24px",
                  color: "grey",
                }}
              >
                {project.Name}
              </div>
              <CustomChart data={data.chartData} />
              <Report
                data={data.paragraphData}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
              />
            </div>
          );
        }
        return null;
      });
    }
  }

  async componentDidMount() {
    await this.fetchManagerProjects(this.props.startDate, this.props.endDate);
    if (!this.props.currentPage) {
      // if rendered from this page
      this.setState({ mounted: true });
      await this.fetchProjectsHours(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedProjectsIds,
        this.props.selectedAuditOption.value
      );
    }
    await this.fetchWorkersHours(
      this.props.startDate,
      this.props.endDate,
      this.props.selectedWorkersIds,
      this.props.selectedAuditOption.value
    );
    await this.fetchAbsentEmployees(false);
    if (!this.props.isShowingWorkers)
      await this.fetchProjectsHours(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedProjectsIds,
        this.props.selectedAuditOption.value
      );
  }

  close() {
    this.props.updateShowAbsentEmployeesModal(false);
  }

  async reportAndCloseModal() {
    await this.fetchAbsentEmployees(true);
    this.close();
  }

  async fetchAbsentEmployees(shouldReport) {
    let resp = await httpGetMissingEmployees(shouldReport);
    if (resp.status === 200) {
      resp = await resp.json();
      if (!this.state.isMounted) return;
      this.setState({ missingEmployees: resp });
    }
  }

  renderHROptions() {
    if (this.props.currentPage !== "Dashboard" && this.state.mounted) {
      this.props.updateCurrentPage("Dashboard");
    }
    if (this.state.missingEmployees === undefined) {
      return null;
    }
    let missingEmployees = this.state.missingEmployees.map((me) => {
      let employees = me.MissingEmployees.map((e) => {
        return e.Name + " " + e.LastName;
      }).join(", ");
      return (
        <div key={me.Manager.ID}>
          <span style={{ color: "green" }}>
            {me.Manager.Name} {me.Manager.LastName}
          </span>{" "}
          -&nbsp;
          <span style={{ color: "red" }}>{employees}</span>
          <div className="report-sep">
            <div className="report-sep-bullet left"></div>
            <div className="report-sep-bullet right"></div>
          </div>
        </div>
      );
    });
    return (
      <div className="hr-options">
        ({" "}
        <Button onClick={() => this.props.updateShowAbsentEmployeesModal(true)}>
          Show absent employees
        </Button>{" "}
        ) <br /> ~~~
        <Modal
          size="lg"
          show={this.props.showAbsentEmployeesModal}
          onHide={() => this.close()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Do you want to email the managers?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="RangeExample">{missingEmployees}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.reportAndCloseModal} variant="success">
              Send emails
            </Button>
            <Button onClick={() => this.close()} variant="danger">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render() {
    return (
      <div style={{ marginBottom: 10 }}>
        {this.renderHROptions()}
        {this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    isShowingWorkers: state.workers.isShowingWorkers,
    startDate: state.dates.startDate,
    endDate: state.dates.endDate,
    selectedWorkersIds: state.workers.selectedWorkersIds,
    selectedProjectsIds: state.projects.selectedProjectsIds,
    currentPage: state.general.currentPage,
    managerProjects: state.projects.allManagerProjects,
    managerWorkers: state.workers.managerWorkers,
    showAbsentEmployeesModal: state.workers.showAbsentEmployeesModal,
    selectedAuditOption: state.projects.selectedAuditOption,
  };
};

const mapDispatchToProps = {
  updateCurrentPage,
  updateShowAbsentEmployeesModal,
  updateAllManagerProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
