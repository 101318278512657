/**
 * Created by nicom on 7/29/2017.
 */

class FreeDayReportDatesWorker {
  constructor(freeDayReportDataModels, datesWorker) {
    this.FreeDays = freeDayReportDataModels;
    this.DatesWorker = datesWorker;
  }
}

export class DatesWorker {
  constructor(startDate, endDate, workerId, approved) {
    this.StartDate = startDate;
    this.EndDate = endDate;
    this.WorkerId = workerId;
    this.Approval = approved;
  }
}

export default FreeDayReportDatesWorker;
