/**
 * Created by nicom on 7/29/2017.
 */

import React, { Component } from "react";
import { Input } from "./Inputs";
import "../../css/Hours.css";

class ButtonsInRow extends Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
    this.handleApproveOrDecline = this.handleApproveOrDecline.bind(this);
  }

  render() {
    return this.renderButtons();
  }

  handleApproveOrDecline() {
    const approvalStatus = this.props.approved === 2 ? 3 : 2;
    this.props.approveOrDecline(approvalStatus);
  }

  handleDeclineAfterPending = () => {
    this.props.approveOrDecline(3);
  };
  isSickness(planType) {
    return (
      parseInt(this.props.planType, 10) === 1 ||
      parseInt(this.props.planType, 10) === 4
    );
  }
  renderButtons() {
    const teamLeader = 2;
    if (!this.props.editable) {
      return (
        <div>
          <div className="buttonsInRowHour">
            {((this.isSickness(this.props.planType) &&
              this.props.isInTopGroup) ||
              ((this.props.isInTopGroup ||
                (this.props.thisUser !== this.props.employeeId &&
                  this.props.userRelation == teamLeader)) &&
                !this.isSickness(this.props.planType))) && (
              <p onClick={this.handleApproveOrDecline} className="valid">
                <span>{this.props.approved === 2 ? "Decline" : "Approve"}</span>
              </p>
            )}

            {((this.isSickness(this.props.planType) &&
              this.props.isInTopGroup) ||
              ((this.props.isInTopGroup ||
                (this.props.thisUser !== this.props.employeeId &&
                  this.props.userRelation == teamLeader)) &&
                !this.isSickness(this.props.planType))) &&
              parseInt(this.props.approved, 10) === 1 && (
                <p onClick={this.handleDeclineAfterPending} className="valid">
                  <span>Decline</span>
                </p>
              )}
            <a className="edit" onClick={this.props.editModeChange}></a>
            <a className="delete" onClick={this.props.open}></a>
          </div>
        </div>
      );
    } else if (this.props.editable) {
      const {
        saveDisable,
        saveClicked,
        cancelClicked,
        approveMode,
      } = this.props;
      return (
        <div className="buttonsInRowHour v-x-buttons">
          <a
            disabled={
              (saveDisable.includes(Input.Invalid) ||
                !saveDisable.includes(Input.Change)) &&
              !approveMode
            }
            onClick={saveClicked}
            className="save-button"
          ></a>
          <a onClick={cancelClicked} className="cancel-button"></a>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default ButtonsInRow;
