/**
 * Created by nicom on 5/2/2017.
 */

import React, { Component } from "react";
import "../../css/Hours.css";
import PlanRowData from "./DataModels/PlanRowData";
import PlanRow from "./PlanRow";
import moment from "moment";

const DAY_FORMAT = "DD/MM/YYYY";

class PlansList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addedPlanRow: null,
      plans: [],
    };

    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.FreeDaysListData !== this.props.FreeDaysListData) {
      this.update(this.props);
    }
  }

  addNewPlan() {
    if (this.state.addedPlanRow === null) {
      this.setState({ addedPlanRow: new PlanRowData() });
    }
  }

  removeNewPlanRow() {
    this.setState({ addedPlanRow: null });
  }

  update() {
    let freeDaysData = this.props.FreeDaysListData;
    let userRelation = this.props.userRelation;
    let plansData = [];
    let currPlans = []; // one for each type

    let i = 0;
    freeDaysData.forEach((freeDay) => {
      freeDay.FreeDayReport.forEach((freeDayReport) => {
        let dayHours = parseInt(freeDayReport.Percentage, 10) !== 100 ? 0.5 : 1;
        let planType = freeDayReport.FreeDayID.toString();
        let day = moment(freeDay.Day);
        let approved = freeDayReport.Approved;
        let percentage = freeDayReport.Percentage;
        let reportAdminId = freeDayReport.ReportAdminId;
        let description = freeDayReport.Description;
        if (currPlans[planType] === undefined) {
          currPlans[planType] = new PlanRowData(
            i++,
            day,
            day,
            planType,
            percentage,
            description,
            approved,
            dayHours,
            reportAdminId,
            userRelation
          );
        } else if (
          parseInt(currPlans[planType].frm.diff(day, "days", true), 10) <= 1 &&
          parseInt(currPlans[planType].approved, 10) ===
            parseInt(approved, 10) &&
          currPlans[planType].percentage !== "50" &&
          currPlans[planType].percentage === percentage
        ) {
          currPlans[planType].frm = day;
          currPlans[planType].totalDays =
            currPlans[planType].totalDays + dayHours;
          currPlans[planType].approved = approved;
          currPlans[planType].userRelation = userRelation;
        } else {
          plansData.push(currPlans[planType]);
          currPlans[planType] = new PlanRowData(
            i++,
            day,
            day,
            planType,
            percentage,
            description,
            approved,
            dayHours,
            reportAdminId,
            userRelation
          );
          currPlans[planType].approved = approved;
        }

        currPlans[planType].addEntryId(freeDayReport.ID);
      });
    });

    currPlans
      .filter((plan) => !!plan)
      .forEach((plan) => {
        plansData.push(plan);
      });

    plansData.sort((a, b) => b.frm - a.frm);
    this.setState({ plans: plansData });
  }

  renderNewPlanRowData() {
    if (this.state.addedPlanRow !== null) {
      const { updateOrAddRow, employeeId, thisUser, isInTopGroup } = this.props;
      return (
        <PlanRow
          isInTopGroup={isInTopGroup}
          rowData={this.state.addedPlanRow}
          currentDay={null}
          removeRow={this.removeNewPlanRow.bind(this)}
          plansTypes={this.props.FreeDayType}
          updateOrAddRow={updateOrAddRow}
          employeeId={employeeId}
          thisUser={thisUser}
        />
      );
    }
  }

  renderPlans() {
    return this.state.plans.map((planRow) => {
      const {
        removeRow,
        updateOrAddRow,
        employeeId,
        thisUser,
        isInTopGroup,
        userRelation
      } = this.props;


      return (
        <PlanRow
          isInTopGroup={isInTopGroup}
          key={
            planRow.frm.format(DAY_FORMAT) +
            planRow.till.format(DAY_FORMAT) +
            planRow.type.toString()
          }
          rowData={planRow}
          removeRow={removeRow}
          plansTypes={this.props.FreeDayType}
          updateOrAddRow={updateOrAddRow}
          employeeId={employeeId}
          thisUser={thisUser}
          totalDays={planRow.totalDays}
        />
      );
    });
  }

  render() {
    const isOwn = this.props.employeeId === this.props.thisUser;
    return (
      <span className="PlansList">
        <div className="itemContainer">
          <div className="hoursInfoRow">
            <div className="textInfoRow">
              <div className="dateShow">
                Out of Office -<span> {this.props.WorkerName}</span>
              </div>
              <div className="dateShow">
                {this.props.VacationsBalance !== null && this.props.VacationsBalance !== undefined &&(
                  <span>
                    {`Balance of ${this.props.VacationsBalance} vacation days`}
                  </span>
                )}
              </div>
            </div>
            {isOwn && (
              <div className="buttonsInRowHour PlansListButtonsInRowHour">
                <span>
                  <a
                    className="new-row"
                    onClick={this.addNewPlan.bind(this)}
                    style={{ backgroundColor: "transparent" }}
                  ></a>
                </span>
              </div>
            )}
          </div>
          <div className="listContainer">
            {this.renderPlans()}
            {this.renderNewPlanRowData()}
          </div>
        </div>
      </span>
    );
  }
}

export default PlansList;
