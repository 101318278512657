import React, { Component } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import * as Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import FormatUtils from "../../../common/FormatUtils";
import "../../css/Hours.css";
import Editor from "../Editor/Editor.js";

class WorkedHourRow extends Component {
  constructor(props) {
    super(props);
    this.addProjects = this.addProjects.bind(this);
    this.showInvalidInput = this.showInvalidInput.bind(this);
  }

  addProjects() {
    const { projectsAvailable, projectsAllowed } = this.props;
    let projects = projectsAllowed;
    if (projects === undefined) {
      projects = projectsAvailable;
    }
    var arr = [];
    if (projects.length > 2)
      arr.push(
        <option key="empty" value="empty">
          Select project...
        </option>
      );
    return arr.concat(
      projects.map((project) => {
        return (
          <option key={project.ID} value={project.Name}>
            {project.Name}
          </option>
        );
      })
    );
  }

  addCalendars() {
    const calendars = this.props.calendarsList;
    return calendars.map((calendar) => {
      return (
        <option key={calendar.Id} value={calendar.Name}>
          {calendar.Name}
        </option>
      );
    });
  }
  showInvalidInput(inputId) {
    if (this.props.invalidInput[inputId] === "Invalid") {
      return <div style={{ color: "red" }}>X</div>;
    }
  }

  setStartTimeToNow() {
    this.props.checkAndUpdateStartHour(moment());
  }

  setEndingTimeToNow() {
    this.props.checkAndUpdateEndingHour(moment());
  }

  onDescriptionChange = (val, plainText) => {
    this.props.changedDescriptionAndUpdate(val, plainText);
  };
  render() {
    const {
      editable,
      fromHour,
      checkAndUpdateStartHour,
      tillHour,
      checkAndUpdateEndingHour,
      duration,
      project,
      changedSelectedProjectAndUpdate,
      changedCalendarAndUpdate,
      description,
      jsonDescription,
      showCalendar,
      calendar,
    } = this.props;
    const format = this.props.timeFormat;
  
    return (

      <div className={"WorkedHourRow textInputInRowHour"}>
        <div className="navOptions">
          <div className="durationWraper">
            <div className="timeStart">
              <Datetime
                dateFormat={false}
                timeFormat={format}
                inputProps={{
                  placeholder: "Start",
                  onDoubleClick: this.setStartTimeToNow.bind(this),
                  className: "timePickerInput",
                  disabled: !editable,
                }}
                value={fromHour}
                onChange={checkAndUpdateStartHour}
              />
              {this.showInvalidInput(0)}
            </div>
            <div className={"drawLine"}>{" ━ "}</div>
            <div className="timeEnd">
              <Datetime
                dateFormat={false}
                timeFormat={format}
                inputProps={{
                  placeholder: "End",
                  onDoubleClick: this.setEndingTimeToNow.bind(this),
                  className: "timePickerInput",
                  disabled: !editable,
                }}
                value={tillHour}
                onChange={checkAndUpdateEndingHour}
              />
              {this.showInvalidInput(1)}
            </div>
            <div className="duration">
              {FormatUtils.FormatDurationHours(duration)}
            </div>
          </div>
          {!showCalendar && (
            <div className="dropdownList">
              <FormGroup>
                <FormControl
                  disabled={!editable}
                  bsPrefix="formControlText"
                  as="select"
                  value={project}
                  placeholder="Project..."
                  onChange={changedSelectedProjectAndUpdate}
                >
                  {this.addProjects()}
                </FormControl>
              </FormGroup>
            </div>
          )}
          {showCalendar && (
            <div className="dropdownCalendar">
              <FormGroup>
                <FormControl
                  disabled={!editable}
                  bsPrefix="formControlText"
                  as="select"
                  value={calendar.Name}
                  placeholder="Calendar..."
                  onChange={changedCalendarAndUpdate}
                >
                  {this.addCalendars()}
                </FormControl>
              </FormGroup>
            </div>
          )}
        </div>
        {
          <div className="editorBox">
            <Editor
              description={description}
              jsonDescription={jsonDescription}
              onDescriptionChange={this.onDescriptionChange}
              editable={this.props.editable}
            />
          </div>
        }
      </div>
    );
  }
}

export default WorkedHourRow;
