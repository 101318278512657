import { createStore } from "redux";
import reducer from "./reducers/index";
import { enableBatching } from "redux-batched-actions";

var store = createStore(
  enableBatching(reducer),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
