/**
 * Created by nicom on 8/1/2017.
 */

const colors = [
  "#4443ff",
  "#4480eb",
  "#40b5ff",
  "#57e2ff",
  "#50ebc4",
  "#66ffae",
  "#4dff58",
  "#bceb55",
  "#ddf752",
  "#ffe46c",
  "#ebbd62",
  "#FFCE56",
  "#ff7c55",
  "#ff5e7c",
  "#eb4074",
  "#ff53a1",
  "#e852ff",
  "#8f4feb",
  "#6a43ff",
  "#fffcd0",
  "#ffdaf0",
  "#b9a2ff",
  "#a4c0eb",
  "#b6ffd8",
  "#d8ffd0",
  "#ffe7a8",
  "#b2aeff",
];
class BackGroundColors {
  static getBackGroundColors(number) {
    let colorArray = colors.slice(0, number);
    return colorArray;
  }
}
export default BackGroundColors;
