/**
 * Created by nicom on 8/1/2017.
 */
import React from "react";

class ProjectParagraphData {
  constructor(data, projectName, totalTime) {
    this.Data = data;
    this.projectName = projectName;
    this.totalTime = totalTime;
  }
  toParagraph() {
    let index = 0;
    let data = this.Data.map((projectData) => {
      const { Employee } = projectData;
      let workedTime = projectData.WorkedTime;
      if (workedTime === 0) return null;
      let string =
        "Employee - " +
        Employee.Name +
        " " +
        Employee.LastName +
        "   /   Time -  " +
        workedTime.toString() +
        " hours";
      let sep =
        index > 0 ? (
          <div className="report-sep">
            <div className="report-sep-bullet left"></div>
            <div className="report-sep-bullet right"></div>
          </div>
        ) : null;
      return (
        <div key={index++} className="reportText">
          {sep}
          {string}
        </div>
      );
    });

    data = data.filter((d) => d !== null);
    if (data.length === 0) {
      return <p>No data.</p>;
    } else {
      let sep = (
        <div className="report-sep">
          <div className="report-sep-bullet left"></div>
          <div className="report-sep-bullet right"></div>
        </div>
      );
      let string =
        "Total time for project - " + this.totalTime.toString() + " hours";
      data.push(
        <div key={index} className="reportText">
          {sep}
          {string}
        </div>
      );
    }

    return data;
  }

  toCSVFromat() {
    if (this.Data.length === 0) return "";
    else {
      let stringArray = [];
      stringArray.push([this.projectName, " "]);
      this.Data.map((projectData) => {
        const { Employee } = projectData;
        let workedTime = projectData.WorkedTime;
        if (workedTime !== 0) {
          let row = [];
          row.push("Employee");
          row.push(Employee.Name + " " + Employee.LastName);
          row.push(" ");
          row.push("Time");
          row.push(workedTime);

          stringArray.push(row);
        }
        return null;
      });
      return stringArray;
    }
  }
}

export default ProjectParagraphData;
