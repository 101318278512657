/**
 * Created by nicom on 8/1/2017.
 */
import React from "react";

class WorkerParagraphData {
  constructor(data, workerName, totalTime, absentTotals) {
    this.Data = data;
    this.workerName = workerName;
    this.totalTime = totalTime;
    this.absentTotals = absentTotals.AbsentPerType
      ? absentTotals
      : { AbsentPerType: [], TotalDays: 0 };
  }

  toParagraph() {
    if (!this.Data) return <div></div>;
    function getInDayUnits(num) {
      let units = " days";
      if (num === 1) {
        units = " day";
      }

      return num.toString() + units;
    }

    let index = 0;
    let sep = (
      <div className="report-sep">
        <div className="report-sep-bullet left"></div>
        <div className="report-sep-bullet right"></div>
      </div>
    );

    let data = this.Data ? (
      this.Data.map((workerData) => {
        const { Project } = workerData;
        let workedTime = workerData.WorkedTime;
        if (workedTime === 0) return null;
        let string =
          "Project - " +
          Project.Name +
          "   /   Time -  " +
          workedTime.toString() +
          " hours";
        let locSep = index > 0 ? sep : null;
        return (
          <div key={index++} className="reportText">
            {locSep}
            {string}
          </div>
        );
      })
    ) : (
      <div className="reportText"></div>
    );

    data = data.filter((d) => d !== null);
    if (data.length === 0) {
      return <p>No projects data.</p>;
    } else {
      let string =
        "Total work time for employee - " +
        this.totalTime.toString() +
        " hours";
      data.push(
        <div key={index++} className="reportText">
          {sep}
          {string}
        </div>
      );
    }

    let totalsString =
      "Total free time for employee - " +
      getInDayUnits(this.absentTotals.TotalDays);
    let subTotals = this.absentTotals.AbsentPerType.map((absentData) => {
      return absentData.Type.Name + " - " + getInDayUnits(absentData.Days);
    });
    let subTotalsString = subTotals.join(", ");
    if (subTotalsString) {
      totalsString += " (" + subTotalsString + ")";
    }
    data.push(
      <div key={index} className="reportText">
        {sep}
        {totalsString}
      </div>
    );

    return data;
  }

  toCSVFromat() {
    if (!this.Data || this.Data.length === 0) return "";
    else {
      let stringArray = [];
      stringArray.push([this.workerName, " "]);
      this.Data.map((projectData) => {
        const { Project } = projectData;
        let workedTime = projectData.WorkedTime;
        if (workedTime !== 0) {
          let row = [];
          row.push("Project");
          row.push(Project.Name);
          row.push(" ");
          row.push("Time");
          row.push(workedTime);

          stringArray.push(row);
        }
        return null;
      });
      return stringArray;
    }
  }
}

export default WorkerParagraphData;
