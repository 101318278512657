import { UPDATE_ADMIN_HOURS } from "../actionTypes";

var defaultState = {
  hours: [],
};

export default function general(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_ADMIN_HOURS:
      return { ...state, hours: action.hours };
    default:
      return state;
  }
}
