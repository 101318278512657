import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "quill-mention/dist/quill.mention.min.css";
import "quill-mention";
import "quill-emoji";
import { connect } from "react-redux";
import { updateIssues } from "../../../stores/actions";
import "./Editor.css";
import "quill-emoji/dist/quill-emoji.css";
import { fetchUserIssues, sortIssuesIntoMentions } from "./helper";

var atValues = [];
var lastDeleted = false;

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text:"",
      userIssues: [],
    }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentDidMount() {
  
    const { userIssues, description, jsonDescription } = this.props;
    if (userIssues.length > 0) {
      atValues = sortIssuesIntoMentions(userIssues);
    }
    
    this.setDescription(description, jsonDescription);
    let tabHideEls = [];
    var selectors = [
      ".ql-bold",
      ".ql-italic",
      ".ql-underline",
      ".ql-strike",
      ".ql-blockquote",
      ".ql-code-block",
      ".ql-header",
      ".ql-list",
      ".ql-indent",
      ".ql-direction",
      ".ql-size",
      ".ql-color",
      ".ql-background",
      ".ql-font",
      ".ql-align",
      ".ql-clean",
      ".ql-emoji",
      ".ql-picker-label",
      ".ql-script",
    ];

    selectors.forEach(
      (el) => (tabHideEls = [...tabHideEls, ...document.querySelectorAll(el)])
    );

    tabHideEls.forEach(function (item) {
      item.setAttribute("tabindex", -1);
    });
  }

  setDescription = (description, jsonDescription) => {
    if (jsonDescription !== null) {
      try {
        let json = JSON.parse(jsonDescription);
        this.setState({ text: json });
      } catch (e) {
        // should never happen
        this.setState({text:description });
      }
    } else {
      this.setState({ text: description });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userIssues !== this.props.userIssues) {
      let objects = sortIssuesIntoMentions(this.props.userIssues);
      this.setState({ userIssues: objects });
      atValues = objects;
    }
    if (
      prevProps.description !== this.props.description
    ) {
   
      this.setDescription(this.props.description, this.props.jsonDescription);
    }
  }

  handleChange(value, delta, source, editor) {
    lastDeleted = delta.ops[delta.ops.length - 1].hasOwnProperty("delete")
      ? true
      : false;
    const json = editor.getContents();

    const plainText = editor.getText();
    this.setState({
      text: json,
      lastDeleted: delta.ops[delta.ops.length - 1].hasOwnProperty("delete"),
    });
    this.props.onDescriptionChange(JSON.stringify(json), plainText); //update both
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s\u0590-\u05FF]*$/,
    mentionDenotationChars: ["@", "#"],
    fixMentionsToQuill: true,
    source: async function (searchTerm, renderList, mentionChar) {
      if (searchTerm.length === 0) {
        if (atValues?.length === 0) {
          atValues = await fetchUserIssues(null);
        }
        renderList(atValues, searchTerm);
      } else {
        let matches = [];
        if (searchTerm.length === 3 && !lastDeleted) {
          matches = await fetchUserIssues(searchTerm);
          atValues = matches;
        } else {
          for (let i = 0; i < atValues?.length; i++)
            if (
              ~atValues[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(atValues[i]);
        }

        renderList(matches, searchTerm);
      }
    },
  };

  toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
    ["emoji"],
  ];

  render() {
    return (
      <div className={!this.props.editable ? "disable_editor" : "quillEditor"}>
       
        <ReactQuill
          value={this.state.text}
          onChange={this.handleChange}
          modules={{
            mention: this.mentionModule,
            toolbar: this.toolbarOptions,
            "emoji-toolbar": true,
          }}
          readOnly={!this.props.editable}
        />
      </div>
    );
  }
}

const mapStateToProps = function (state, props) {
  return {
    userIssues: state.issues.issues,
  };
};

const mapDispatchToProps = {
  updateIssues,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
