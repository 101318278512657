/**
 * Created by nicom on 6/27/2017.
 */

export function getProjectNameByID(id, projectData) {
  var res = projectData.find((x) => x.ID === id);
  if (!res) {
    res = { ID: id, Name: id };
  }
  return res;
}

export function getProjectIDByName(name, projectData) {
  return projectData.find((x) => x.Name === name);
}
