/**
 * Created by nicom on 7/27/2017.
 */

import React, { Component } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import ButtonsInRow from "../HoursPage/HoursData/ButtonsInRow";
import Confirm from "../Modals/Confirm";
import FreeDayReportDataModel from "../../common/DataModels/FreeDayReportDataModel";
import AdminFreeDayReportDataModel from "../../common/DataModels/AdminFreeDayReportDataModel";
import { Input } from "../HoursPage/HoursData/Inputs";
import "../css/Hours.css";
import ApprovalState from "../ApprovalState/ApprovalState";

class FreeDayRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      percentage: "",
      type: "",
      approved: 1,
      editable: false,
      saveDisable: [Input.NoChange, Input.NoChange],
      showConfirmModal: false,
      calendar: this.props.calendar,
    };

    this.editModeChange = this.editModeChange.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);
    this.setStartingData = this.setStartingData.bind(this);
    this.setStartingDataOfAddedRow = this.setStartingDataOfAddedRow.bind(this);
    this.setStartingDataOfExistingRow = this.setStartingDataOfExistingRow.bind(
      this
    );
    this.removeRow = this.removeRow.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.changeSelectedType = this.changeSelectedType.bind(this);
    this.changeSelectedPercentage = this.changeSelectedPercentage.bind(this);
    this.changedCalendarType = this.changedCalendarType.bind(this);
    this.addTypes = this.addTypes.bind(this);
    this.getFreeDayTypeById = this.getFreeDayTypeById.bind(this);
    this.getFreeDayTypeByName = this.getFreeDayTypeByName.bind(this);
  }

  changeSelectedType(e) {
    const isOther = e.target.value === "Other";
    let saveDisable = this.state.saveDisable;

    if (isOther && this.state.description === "") {
      saveDisable[2] = Input.Invalid;
    } else {
      if (this.props.rowData.ID === undefined) {
        saveDisable[0] = Input.Change;
      } else {
        const { FreeDayID } = this.props.rowData;
        let freeDayName = this.getFreeDayTypeById(FreeDayID);
        if (freeDayName === e.target.value) saveDisable[0] = Input.NoChange;
        else saveDisable[0] = Input.Change;
      }
    }
    this.setState({ type: e.target.value, saveDisable });
  }

  changeSelectedPercentage(e) {
    let saveDisable = this.state.saveDisable;
    if (this.props.rowData.ID === undefined) {
      saveDisable[1] = Input.Change;
    } else {
      const { Percentage } = this.props.rowData;
      if (Percentage === Number(e.target.value))
        saveDisable[1] = Input.NoChange;
      else saveDisable[1] = Input.Change;
    }
    this.setState({ percentage: e.target.value, saveDisable });
  }
  changedCalendarType(e) {
    let saveDisable = this.state.saveDisable;
    const calendarSelected = e.target.value;
    if (calendarSelected === "empty" || calendarSelected === undefined) {
      saveDisable[2] = Input.Invalid;
    } else {
      if (this.props.rowData.CalendarID !== undefined) {
        let calendarObj = this.props.calendarsList.find(
          (c) => c.Name === this.props.rowData.CalendarID
        );
        saveDisable[2] =
          calendarObj.Name === calendarSelected ? Input.NoChange : Input.Change;
      } else {
        saveDisable[2] = Input.Change;
      }
    }
    const calendar = this.props.calendarsList.find(
      (c) => c.Name === e.target.value
    );

    this.setState({ calendar });
  }
  componentDidMount() {
    this.setStartingData();
  }

  setStartingData() {
    if (this.props.rowData.ID === undefined) this.setStartingDataOfAddedRow();
    else this.setStartingDataOfExistingRow();
  }

  setStartingDataOfAddedRow() {
    this.setState({
      type: this.props.FreeDayType[0].Name,
      percentage: "100",
      editable: true,
      calendar: this.props.calendar,
      saveDisable: [Input.Change, Input.Change],
    });
  }

  setStartingDataOfExistingRow() {
    const {
      FreeDayID,
      Percentage,
      Approved,
      CalendarId,
      Description,
    } = this.props.rowData;
    let freeDayName = this.getFreeDayTypeById(FreeDayID);
    let calendar = { Id: "", Name: "" };
    if (this.props.showCalendar) {
      const result = this.props.calendarsList.find((c) => c.Id === CalendarId);
      if (result) calendar = result;
    }
    this.setState({
      percentage: Percentage,
      description: Description,
      type: freeDayName,
      approved: Approved,
      calendar,
    });
  }

  editModeChange() {
    this.setState({ editable: !this.state.editable });
  }

  open() {
    this.setState({ showConfirmModal: true });
  }

  close() {
    this.setState({ showConfirmModal: false });
  }

  removeRow() {
    this.close();
    this.props.removeRow(this.props.rowData);
  }

  saveClicked() {
    this.editModeChange();
    this.setState({ approved: 1 });
    const { rowData, currentDay } = this.props;
    let typeId = this.getFreeDayTypeByName(this.state.type);
    let Percentage = this.state.percentage;
    let Description = this.state.description;
    let dataToUpdate;
    if (!this.props.showCalendar) {
      dataToUpdate = new FreeDayReportDataModel(
        rowData.ID,
        typeId,
        currentDay,
        Percentage,
        Description
      );
    } else {
      dataToUpdate = new AdminFreeDayReportDataModel(
        rowData.ID,
        typeId,
        currentDay,
        Percentage,
        this.state.calendar.Id,
        Description
      );
    }
    this.props.updateOrAddRow(dataToUpdate);
    if (rowData.ID === undefined) {
      this.props.removeRow();
    }
  }

  cancelClicked() {
    if (this.props.rowData.ID === undefined) {
      this.props.removeRow();
    } else {
      this.setState({ saveDisable: [Input.NoChange, Input.NoChange] });
      this.editModeChange();
      this.setStartingData();
    }
  }

  addTypes() {
    const { FreeDayType } = this.props;
    return FreeDayType.map((freeDayType) => {
      return (
        <option key={freeDayType.ID} value={freeDayType.Name}>
          {freeDayType.Name}
        </option>
      );
    });
  }
  addCalendars() {
    const calendars = this.props.calendarsList;
    var arr = [];

    return arr.concat(
      calendars.map((calendar) => {
        return (
          <option key={calendar.Id} value={calendar.Name}>
            {calendar.Name}
          </option>
        );
      })
    );
  }
  UpdateDescription(description) {
    let text = description.target.value;
    let saveDisable = this.state.saveDisable;
    if (text === "") saveDisable[2] = Input.Invalid;
    else {
      if (text !== this.props.rowData.description) {
        saveDisable[2] = Input.Change;
      } else {
        saveDisable[2] = Input.NoChange;
      }
    }
    this.setState({
      description: text,
      saveDisable: saveDisable,
    });
  }
  render() {
    const {
      editable,
      saveDisable,
      type,
      percentage,
      approved,
      calendar,
      description,
    } = this.state;
    const { showCalendar } = this.props;
    const { ReportAdminId } = this.props.rowData;
    return (
      <div
        className={"FreeDayRow " +
          (ReportAdminId !== null && !editable && !showCalendar
            ? "rowContainer adminDeclaration"
            : "rowContainer")
        }
      >
        <div className={"textInputInRowHour"}>
          <div className={"freeDayWrapper"}>
            <p className="textDeclaration">Free Day Declaration</p>
            <div className="freeDropdown">
              <FormGroup>
                <FormControl
                  disabled={!editable}
                  bsPrefix="formControlText"
                  as="select"
                  value={type}
                  onChange={this.changeSelectedType}
                >
                  {this.addTypes()}
                </FormControl>
              </FormGroup>
            </div>
            {type === "Other" && (
              <input
                className="editPlanInput"
                value={description}
                type="text"
                placeholder="Add a short description ..."
                onChange={this.UpdateDescription.bind(this)}
                disabled={!editable}
              ></input>
            )}
            <div className="precentDropDown">
              <FormGroup>
                <FormControl
                  disabled={!editable}
                  bsPrefix="formControlText percent"
                  as="select"
                  value={percentage}
                  onChange={this.changeSelectedPercentage}
                >
                  <option value={100}>100 </option>
                  <option value={50}>50 </option>
                </FormControl>
              </FormGroup>
            </div>

            {showCalendar && (
              <div className="dropdownCalendar">
                <FormGroup>
                  <FormControl
                    disabled={!editable}
                    bsPrefix="formControlText"
                    as="select"
                    value={calendar.Name}
                    placeholder="Calendar..."
                    onChange={this.changedCalendarType}
                  >
                    {this.addCalendars()}
                  </FormControl>
                </FormGroup>
              </div>
            )}

            {!editable && !showCalendar && (
              <ApprovalState approvalId={approved} />
            )}
          </div>
        </div>
        {this.props.showButtons && (
          <ButtonsInRow
            saveDisable={saveDisable}
            saveClicked={this.saveClicked}
            cancelClicked={this.cancelClicked}
            editable={editable}
            editModeChange={this.editModeChange}
            open={this.open}
          />
        )}
        <Confirm
          open={this.open}
          close={this.close}
          showModal={this.state.showConfirmModal}
          onConfirm={this.removeRow}
          title="Remove Free Day Declaration"
          message="Are you sure?"
        />
      </div>
    );
  }

  getFreeDayTypeById(typeId) {
    const { FreeDayType } = this.props;
    let name;
    FreeDayType.map((dataType) => {
      if (dataType.ID === typeId) name = dataType.Name;
      return null;
    });
    return name;
  }

  getFreeDayTypeByName(name) {
    const { FreeDayType } = this.props;
    let id;
    FreeDayType.map((dataType) => {
      if (dataType.Name === name) id = dataType.ID;
      return null;
    });
    return id;
  }
}

export default FreeDayRow;
