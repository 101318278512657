/**
 * Created by nicom on 5/1/2017.
 */
import React, { Component } from "react";
import moment from "moment";
import "../css/Hours.css";

class Alerts extends Component {
  renderAlerts() {
    let index = 0;
    return this.props.alerts.map((alert) => (
      <div className="alertsStyles" key={index++}>
        {moment(alert.Date).format("DD.MM.YYYY") +
          "\u00A0\u00A0\u00A0\u00A0" +
          alert.Text}
      </div>
    ));
  }

  render() {
    return (
      <div className="itemContainer">
        <div className="headerStyle">ALERTS</div>
        <div>{this.renderAlerts()}</div>
      </div>
    );
  }
}

export default Alerts;
