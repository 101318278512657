class AdminActivityReportDataModel {
  constructor(
    id,
    calendarId,
    calendarDayId,
    startTime,
    duration,
    description,
    jsonDescription
  ) {
    this.ID = id;
    this.CalendarDayId = calendarDayId;
    this.CalendarId = calendarId;
    this.StartTime = startTime;
    this.Duration = duration;
    this.Description = description;
    this.JsonDescription = jsonDescription;
  }
}

export default AdminActivityReportDataModel;
