import { combineReducers } from "redux";
import dates from "./dates";
import general from "./general";
import projects from "./projects";
import workers from "./workers";
import hours from "./hours";
import freeDays from "./freeDays";
import issues from "./issues";
import admin from "./admin";
const reducer = combineReducers({
  dates,
  general,
  projects,
  workers,
  hours,
  freeDays,
  issues,
  admin,
});

export default reducer;
