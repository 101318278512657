import { UPDATE_APPROVAL_STATUS } from "../actionTypes";

var defaultState = {
  approvalStatus: { label: "All", value: -1 },
};

export default function freeDays(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_APPROVAL_STATUS:
      return { ...state, approvalStatus: action.approvalStatus };
    default:
      return state;
  }
}
