/**
 * Created by nicom on 7/29/2017.
 */

export default class FreeDayRowData {
  constructor(index = 0, type = "", percentage = "100") {
    this.index = index;
    this.type = type;
    this.percentage = percentage;
  }
}
