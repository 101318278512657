/**
 * Created by nicom on 8/19/2017.
 */

class WorkerDataModel {
  constructor(empId, email, name, lastName, hebName, hebLastName) {
    this.EngName = name + " " + lastName;
    this.HebName = hebName + " " + hebLastName;
    this.Id = email;
    this.EmployeeId = empId;
  }
}

export default WorkerDataModel;
