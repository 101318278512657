/**
 * Created by nicom on 7/29/2017.
 */

import React, { Component } from "react";
import { Input } from "./Inputs";
import "../../css/Hours.css";

class ButtonsInRow extends Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
  }

  render() {
    return this.renderButtons();
  }

  renderButtons() {
    const { saveDisable, saveClicked, cancelClicked, editable } = this.props;

    return !editable ? (
      <div className="ButtonsInRow buttonsInRowHour">
        <a className="edit" onClick={this.props.editModeChange} />
        <a className="delete" onClick={this.props.open} />
      </div>
    ) : (
      <div className="buttonsInRowHour v-x-buttons">
        <a
          disabled={
            saveDisable.includes(Input.Invalid) ||
            !saveDisable.includes(Input.Change)
          }
          onClick={saveClicked}
          className="save-button"
        />
        <a onClick={cancelClicked} className="cancel-button" />
      </div>
    )
  }
}
export default ButtonsInRow;
