import { Navbar, Nav } from "react-bootstrap";
import "./Header.css";
import logo from "./logo_sm.svg";
import logoFull from "./logo.svg";
import useScreenMode from "../../hooks/useScreenMode";
import { connect } from "react-redux";
import { updateHeaderShowModal, updateTimeFormat } from "../../stores/actions";
import Settings from "../Modals/Settings";
import { Person, Gear, BoxArrowRight, Clock, House, Calendar3, Sliders } from "react-bootstrap-icons";
import HeaderNav from "../HeaderNav/HeaderNav";

const Header = props => {

  const { isLandscapeMobile, isTablet } = useScreenMode();
  let { profile: { name, image } } = window.user;
  name = name || "User name";
  const iconSize = isLandscapeMobile ? "1.8em" : "1.5em";

  const openSettings = () => props.setShowSettings?.(true);
  const closeSettings = () => props.setShowSettings?.(false);
  const onSettingsChange = newSettings => props.updateTimeFormat?.(newSettings.timeFormat);

  return (
    <>
      <Navbar className="d-block p-0" expand="sm">
        <div className="Header-top d-flex justify-content-between px-3 flex-wrap">
          <Navbar.Brand>
            <img src={isTablet ? logoFull : logo}
                 alt="TandemG logo"
                 height="80"
                 className="py-3" />
          </Navbar.Brand>
          <div className="d-flex align-items-center gap-1">
            <div className="user-name text-white d-none d-sm-inline-flex">{name}</div>
            <div className="user-image m-1" style={{ backgroundImage: `url(${image})` }} title={isLandscapeMobile ? null : name}>
              {!image && <Person size="1.8em" />}
            </div>
            <Nav.Link className="text-white p-1 header-btn" onClick={openSettings} title="Settings">
              <Gear size="2em" />
            </Nav.Link>
            <Nav.Link className="text-white p-1 header-btn" onClick={window.logout} title="Logout">
              <BoxArrowRight size="2em" />
            </Nav.Link>
            <Navbar.Toggle className="p-0 ms-2" title="Navigation" />
          </div>
        </div>
        <Navbar.Collapse className="Header-bottom w-100">
          <Nav className="w-75 mx-auto justify-content-around py-3 gap-3">
            <HeaderNav saveFilterState={props.saveFilterState} to="/">
              <Clock size={iconSize} />
              Hours
            </HeaderNav>
            <HeaderNav saveFilterState={props.saveFilterState} to="/Dashboard">
              <House size={iconSize} />
              Dashboard
            </HeaderNav>
            <HeaderNav saveFilterState={props.saveFilterState} to="/Attendance" pageName="Planner">
              <Calendar3 size={iconSize} />
              Attendance
            </HeaderNav>
            {!!props.isInTopGroup && (
              <HeaderNav saveFilterState={props.saveFilterState} to="/Admin">
                <Sliders size={iconSize} />
                Admin
              </HeaderNav>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Settings open={openSettings} close={closeSettings} showModal={props.headerShowModal} onSettingsChange={onSettingsChange} />
    </>
  );
};

const mapStateToProps = state => ({
  headerShowModal: state.general.headerShowModal,
  isInTopGroup: state.general.userGroups.includes(1),
});

const mapDispatchToProps = {
  setShowSettings: updateHeaderShowModal,
  updateTimeFormat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
