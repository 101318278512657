import moment from "moment";
import {
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  UPDATE_ALL_DATES,
  UPDATE_ALL_DATES_ENABLED,
  UPDATE_TIME_FORMAT,
  UPDATE_ALL_FUTURE,
} from "../actionTypes";

var defaultState = {
  startDate: moment(new Date())
    .subtract(new Date().getDay(), "d")
    .format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  allDates: false,
  allDatesEnabled: false,
  timeFormat: null,
  allFuture: false,
};

export default function dates(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_START_DATE:
      return { ...state, startDate: action.startDate };
    case UPDATE_END_DATE:
      return { ...state, endDate: action.endDate };
    case UPDATE_ALL_DATES:
      return { ...state, allDates: action.allDates };
    case UPDATE_ALL_DATES_ENABLED:
      return { ...state, allDatesEnabled: action.enabled };
    case UPDATE_TIME_FORMAT:
      return { ...state, timeFormat: action.format };
    case UPDATE_ALL_FUTURE:
      return { ...state, allFuture: action.value };
    default:
      return state;
  }
}
