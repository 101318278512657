/**
 * Created by nicom on 5/2/2017.
 */

import React, { Component } from "react";
import ButtonsInRow from "./ButtonsInRow";
import Confirm from "../../Modals/Confirm";
import PlanRowDataView from "./PlanRowDataView";
import { Input } from "./Inputs";

import "../../css/Hours.css";
import PlanRowData from "./DataModels/PlanRowData";
import moment from "moment";

class PlanRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      saveDisable: [
        Input.NoChange,
        Input.NoChange,
        Input.NoChange,
        Input.NoChange,
      ],
      fromDate: null,
      tillDate: null,
      planType: "",
      showConfirmModal: false,
      approved: 1,
      percentage: "",
      description: "",
    };
    this.editModeChange = this.editModeChange.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);
    this.setStartingData = this.setStartingData.bind(this);
    this.setStartingDataOfAddedRow = this.setStartingDataOfAddedRow.bind(this);
    this.setStartingDataOfExistingRow = this.setStartingDataOfExistingRow.bind(
      this
    );
    this.validateDatesRange = this.validateDatesRange.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.approveOrDecline = this.approveOrDecline.bind(this);
    this.checkAndUpdatePercentage = this.checkAndUpdatePercentage.bind(this);
  }

  componentDidMount() {
    this.setStartingData();
  }
  setStartingData() {
   
    if (this.props.rowData.ID === undefined) this.setStartingDataOfAddedRow();
    else this.setStartingDataOfExistingRow();
  }

  setStartingDataOfAddedRow() {
    this.setState({
      fromDate: moment(new Date()),
      tillDate: moment(new Date()),
      planType: "2",
      saveDisable: [Input.Change, Input.Change, Input.Change, Input.Change],
      editable: true,
      percentage: "100",
    });
  }

  setStartingDataOfExistingRow() {
    const {
      frm,
      till,
      type,
      approved,
      percentage,
      description,
    } = this.props.rowData;
    this.setState({
      fromDate: frm,
      tillDate: till,
      planType: type,
      percentage: percentage,
      approved: approved,
      Description: description,
    });
  }

  editModeChange() {
    this.setState({ editable: !this.state.editable });
  }

  saveClicked() {
    this.editModeChange();
    this.setState({ approved: 1 });
    const {
      fromDate,
      tillDate,
      planType,
      percentage,
      description,
    } = this.state;
    const { rowData, employeeId } = this.props;

    let dataToSend = new PlanRowData(
      rowData.ID,
      fromDate,
      tillDate,
      planType,
      percentage,
      description
    );
    this.props.updateOrAddRow(dataToSend, rowData, employeeId);
    if (rowData.ID === undefined) this.props.removeRow();
  }

  approveOrDecline(approvalStatus) {
    const {
      fromDate,
      tillDate,
      planType,
      percentage,
      description,
    } = this.state;
    const { rowData, employeeId  } = this.props;
    let dataToSend = new PlanRowData(
      rowData.ID,
      fromDate,
      tillDate,
      planType,
      percentage,
      description,
      approvalStatus
    );
    this.props.updateOrAddRow(dataToSend, rowData, employeeId, true);
    if (rowData.ID === undefined) this.props.removeRow();

    this.setState({ approved: approvalStatus });
  }

  cancelClicked() {
    if (this.props.rowData.ID === undefined) {
      this.props.removeRow();
    } else {
      this.setState({
        saveDisable: [Input.NoChange, Input.NoChange, Input.NoChange],
      });
      this.editModeChange();
      this.setStartingData();
    }
  }

  open() {
    this.setState({ showConfirmModal: true });
  }

  close() {
    this.setState({ showConfirmModal: false });
  }

  removeRow() {
    this.close();
    this.props.removeRow(this.props.rowData);
  }

  validateDatesRange(first, second) {
    let saveDisable = this.state.saveDisable;
    if (first === null) {
      saveDisable[0] = Input.Invalid;
    }

    if (second === null) {
      saveDisable[1] = Input.Invalid;
    }

    if (first !== null && second !== null) {
      if (first.isAfter(second)) {
        saveDisable[0] = Input.Invalid;
        saveDisable[1] = Input.Invalid;
      } else {
        if (first.isSame(this.props.rowData.frm)) {
          saveDisable[0] = Input.NoChange;
        } else {
          saveDisable[0] = Input.Change;
        }
        if (second.isSame(this.props.rowData.till)) {
          saveDisable[1] = Input.NoChange;
        } else {
          saveDisable[1] = Input.Change;
        }
      }
    }

    return saveDisable;
  }

  checkAndUpdateStartDate(selectedDate) {
    let startDate = moment(selectedDate).startOf("day");
    let saveDisable = this.validateDatesRange(startDate, this.state.tillDate);

    if (!startDate.isSame(this.state.tillDate, "day")) {
      this.setState({ percentage: "100" });
    }

    this.setState({
      fromDate: startDate,
      saveDisable: saveDisable,
    });
  }

  checkAndUpdateEndingDate(selectedDate) {
    let endDate = moment(selectedDate).startOf("day");

    let saveDisable = this.validateDatesRange(this.state.fromDate, endDate);

    if (!endDate.isSame(this.state.fromDate, "day")) {
      this.setState({ percentage: "100" });
    }

    this.setState({
      tillDate: endDate,
      saveDisable: saveDisable,
    });
  }

  checkAndUpdatePercentage(selectedPercentage) {
    let saveDisable = this.validateDatesRange(
      this.state.fromDate,
      this.state.tillDate
    );

    if (selectedPercentage !== this.props.rowData.percentage) {
      saveDisable[3] = Input.Change;
    } else {
      saveDisable[3] = Input.NoChange;
    }

    this.setState({
      percentage: selectedPercentage,
      saveDisable: saveDisable,
    });
  }

  checkAndUpdatePlanType(selectedPlan) {
    let plan = selectedPlan.target.value;
    let saveDisable = this.state.saveDisable;
    let isOther = this.props.plansTypes.some(
      (p) => p.ID === parseInt(plan, 10) && p.Name === "Other"
    );
    if (isOther && this.state.description === "") {
      saveDisable[2] = Input.Invalid;
    } else {
      if (plan !== this.props.rowData.type) {
        saveDisable[2] = Input.Change;
      } else {
        saveDisable[2] = Input.NoChange;
      }
    }
    this.setState({
      planType: plan,
      saveDisable: saveDisable,
    });
  }

  checkAndUpdateDescription(description) {
    let text = description.target.value;
    let saveDisable = this.state.saveDisable;
    if (text === "") saveDisable[2] = Input.Invalid;
    else {
      if (text !== this.props.rowData.Description) {
        saveDisable[2] = Input.Change;
      } else {
        saveDisable[2] = Input.NoChange;
      }
    }
    this.setState({
      description: text,
      saveDisable: saveDisable,
    });
  }
  render() {
    const {
      editable,
      fromDate,
      tillDate,
      planType,
      saveDisable,
      percentage,
      approved,
      description   
     } = this.state;
    const {
      plansTypes,
      thisUser,
      employeeId,
      totalDays,
      isInTopGroup,
      rowData
    } = this.props;
    return (fromDate !== null) ? (
      <div
        className={"PlanRow " +
          (rowData.ReportAdminId !== null && !editable
            ? "rowContainer adminDeclaration"
            : "rowContainer")
        }
      >
        <PlanRowDataView
          description={description}
          checkAndUpdateDescription={this.checkAndUpdateDescription.bind(
            this
          )}
          isShowingDescription={plansTypes.some(
            (p) => p.ID === parseInt(planType, 10) && p.Name === "Other"
          )}
          editable={editable}
          fromDate={fromDate}
          checkAndUpdateStartDate={this.checkAndUpdateStartDate.bind(this)}
          tillDate={tillDate}
          checkAndUpdateEndingDate={this.checkAndUpdateEndingDate.bind(this)}
          planType={planType}
          checkAndUpdatePlanType={this.checkAndUpdatePlanType.bind(this)}
          plansTypes={plansTypes}
          percentage={percentage}
          checkAndUpdatePercentage={this.checkAndUpdatePercentage.bind(this)}
          invalidInput={saveDisable}
          approvalId={approved}
          totalDays={totalDays}
          ReportAdminId={rowData.ReportAdminId}
        />
        <ButtonsInRow
          isInTopGroup={isInTopGroup}
          saveDisable={saveDisable}
          saveClicked={this.saveClicked}
          cancelClicked={this.cancelClicked}
          editable={editable}
          editModeChange={this.editModeChange}
          approveOrDecline={this.approveOrDecline}
          open={this.open}
          thisUser={thisUser}
          employeeId={employeeId}
          approved={approved}
          planType={planType}
          userRelation= {rowData.userRelation}
        />
        <Confirm
          open={this.open}
          close={this.close}
          showModal={this.state.showConfirmModal}
          onConfirm={this.removeRow}
          title="Remove Plan?"
          message="Are you sure?"
        />
      </div>
    ) : "";
  }
}

export default PlanRow;
