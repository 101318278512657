/**
 * Created by nicom on 7/26/2017.
 */
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-day-picker/lib/style.css";

class Confirm extends Component {
  render() {
    return (
      <div>
        <Modal
          size="sm"
          show={this.props.showModal}
          onHide={this.props.close}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="RangeExample">
              <p>{this.props.message}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onConfirm} variant="success">
              Confirm{" "}
            </Button>
            <Button onClick={this.props.close} variant="danger">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Confirm;
