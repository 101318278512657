/**
 * Created by nicom on 5/3/2017.
 */

export default class PlanRowData {
  constructor(
    index = undefined,
    frm = null,
    till = null,
    type = "",
    percentage = 100,
    description = "",
    approved = 1,
    totalDays,
    reportAdminId = null,
    userRelation= 4,
  ) {
    this.index = index;
    this.ID = index; // temporary
    this.frm = frm;
    this.till = till;
    this.type = type;
    this.approved = approved;
    this.entriesIds = [];
    this.totalDays = totalDays;
    this.percentage = percentage;
    this.ReportAdminId = reportAdminId;
    this.description = description;
    this.userRelation = userRelation;
  }

  addEntryId(entryId) {
    this.entriesIds.push(entryId);
  }

  updateData(objToCopy) {
    this.index = objToCopy.index;
    this.frm = objToCopy.frm;
    this.till = objToCopy.till;
    this.type = objToCopy.type;
  }
}
