import AuthenticationContext from "adal-angular";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./stores/store";
import "bootstrap/dist/css/bootstrap.min.css";

const config = {
  instance: "https://login.microsoftonline.com/",
  tenant: "tandemg.com",
  clientId: "70e1a94e-b526-4bc8-831a-d2509e155b78",
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: "localStorage", // enable this for IE, as sessionStorage does not work for localhost.
};

let authContext = new AuthenticationContext(config);
let originalFetch = window.fetch;
async function myFetch() {
  let resp = await originalFetch.apply(window, arguments);
  if (resp.status === 401) {
    authContext.login();
  }
  return resp;
}

window.fetch = myFetch;
window.logout = authContext.logOut.bind(authContext);

authContext.handleWindowCallback();

let user = authContext.getCachedUser();

function getUserToken(delay, cb) {
  // Calculate how long until the token is expired
  setTimeout(() => {
    authContext.acquireToken(config["clientId"], (error, token) => {
      if (error) {
        console.log("Failed acquiring new token, error: ", error);
        authContext.logOut();
        authContext.login();
        return;
      }

      let user = authContext.getCachedUser();
      //console.log("Acquired token successfully");
      window.user = user;
      window.token = token;
      window.headers = new Headers();
      window.headers.append("Authorization", "Bearer " + window.token);
      window.headers.append("Content-Type", "application/json");

      let now = Date.now() / 1000;
      let exp = user.profile.exp;
      let tokenExpiresIn = exp - now;
      if (tokenExpiresIn < 10) {
        tokenExpiresIn = 100;
      }

      //console.log("Will refresh token in " + (tokenExpiresIn - 10) + " seconds.");
      getUserToken(tokenExpiresIn - 10, null);

      if (cb !== null) {
        cb();
      }
    });
  }, delay * 1000); // 10 seconds before the token expires - renew it
}

if (!user) {
  authContext.login();
} else {
  getUserToken(0, () => {
    ReactDOM.render(
      <BrowserRouter>
        <Provider store={store}>
          <App store={store} />
        </Provider>
      </BrowserRouter>,
      document.getElementById("root")
    );
  });
}
