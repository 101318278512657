/**
 * Created by nicom on 4/30/2017.
 */
import React, { Component } from "react";

class AuthContext extends Component {
  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        user: window.user,
        onLogout: window.logout,
      })
    );
    return <div>{childrenWithProps}</div>;
  }
}

export default AuthContext;
