/**
 * Created by nicom on 5/5/2017.
 */
const serverUrl =  "https://ihoursapi.azurewebsites.net";
const userRoute = "/user";
const hoursRoute = "/hours";
const projectRoute = "/projects";
const plannerRoute = "/freeDay";
const managerRoute = "/manager";
const jiraRoute = "/jira";
const adminRoute = "/admin";

const getOptions = (method, data) => method ? ({
  method,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + window.token,
  },
  body: JSON.stringify(data),
}) : ({ headers: window.headers })

export function httpGetAllowedProjects(date, workerId) {
  let sendVars = "?date=" + date + "&workerId=" + workerId;
  return fetch(serverUrl + userRoute + "/allowedProjects" + sendVars, getOptions());
}

export function httpGetUserOwnProjects(startDate, endDate, workerId) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId;
  return fetch(serverUrl + userRoute + "/projects" + sendVars, getOptions());
}

export function httpGetUserHours(startDate, endDate, workerId, auditStatus) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId + "&auditStatus=" + auditStatus;
  return fetch(serverUrl + userRoute + "/hours" + sendVars, getOptions());
}

export function httpGetUserHoursForProject(startDate, endDate, workerId, projectId, selectedAudit = 1) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId + "&project=" + projectId + "&auditStatus=" + selectedAudit;
  return fetch(serverUrl + hoursRoute + "/projectHours" + sendVars, getOptions());
}

export function httpGetUserHoursForFreeDay(startDate, endDate, workerId, freeDay) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId + "&freeDay=" + freeDay;
  return fetch(serverUrl + userRoute + "/freeDayHours" + sendVars, getOptions());
}

export function httpGetUserWorkTimeDetails(startDate, endDate, workerId) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId;
  return fetch(serverUrl + userRoute + "/workedTimeDetails" + sendVars, getOptions());
}

export function httpGetUserErrors(startDate, endDate, workerId) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&workerId=" + workerId;
  return fetch(serverUrl + userRoute + "/errors" + sendVars, getOptions());
}

export function httpRemoveActivityRow(rowData) {
  return fetch(serverUrl + hoursRoute + "/deleteActivityRow", getOptions("DELETE", rowData));
}

export function httpAddActivityRow(rowData) {
  return fetch(serverUrl + hoursRoute + "/addActivityRow", getOptions("POST", rowData));
}

export function httpUpdateActivityRow(rowData) {
  return fetch(serverUrl + hoursRoute + "/updateActivityRow", getOptions("PUT", rowData));
}

export function httpRemoveFreeDayRow(data) {
  return fetch(serverUrl + userRoute + "/deleteVacationRow", getOptions("DELETE", data));
}

export function httpAddFreeDayRow(data) {
  return fetch(serverUrl + userRoute + "/addVacationRow", getOptions("POST", data));
}

export function httpUpdateFreeDayRow(data) {
  return fetch(serverUrl + userRoute + "/updateVacationRow", getOptions("PUT", data));
}

export function httpGetFreeDaysTypes() {
  return fetch(serverUrl + plannerRoute + "/types", getOptions());
}

export function httpDeleteFreeDaysRange(Ids) {
  return fetch(serverUrl + plannerRoute + "/deleteAll", getOptions("DELETE", Ids));
}

export function httpUpdateOrAddFreeDaysRange(data) {
  return fetch(serverUrl + plannerRoute + "/updateOrAddRows", getOptions("PUT", data));
}

export function httpGetMissingEmployees(shouldReport) {
  let sendVars = "?sendEmailToManagers=" + shouldReport;
  return fetch(serverUrl + managerRoute + "/missingEmployees" + sendVars, getOptions());
}

export function httpGetManagerProjects(startDate, endDate) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;
  return fetch(serverUrl + managerRoute + "/projects" + sendVars, getOptions());
}

export function httpGetManagerWorkers(startDate, endDate) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;

  return fetch(serverUrl + managerRoute + "/workers" + sendVars, getOptions());
 
}
export function httpGetAllProjects() {
  return fetch(serverUrl + projectRoute, getOptions());
}

// new functions
export function httpGetAllProjectsWorkTime(startDate, endDate, projectIds, auditStatus) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;
  let projects = projectIds.join("&projectIds=");
  sendVars += "&projectIds=" + projects + "&auditStatus=" + auditStatus;
  return fetch(serverUrl + projectRoute + "/projectsWorkedTime" + sendVars, getOptions());
}

export function httpGetAllUsersWorkTime(startDate, endDate, workerIds, isAudit) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;
  let workers = workerIds.join("&workerIds=");
  sendVars += "&workerIds=" + workers + "&isAudit=" + isAudit;
  return fetch(serverUrl + hoursRoute + "/usersWorkedTime" + sendVars, getOptions());
}

export function httpGetUsersFreeDays(startDate, endDate, freeDayId, workerIds, filterStatusId) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&freeDay=" + freeDayId;
  let workers = workerIds.join("&workerIds=");
  sendVars += "&workerIds=" + workers + "&filterStatusId=" + filterStatusId;
  return fetch(serverUrl + plannerRoute + "/usersFreeDays" + sendVars, getOptions());
}

export function httpGetAllFutureUserFreeDays(startDate, freeDayId, workerIds, filterStatusId) {
  let sendVars = "?startDate=" + startDate + "&freeDay=" + freeDayId;
  let workers = workerIds.join("&workerIds=");
  sendVars += "&workerIds=" + workers + "&filterStatusId=" + filterStatusId;
  return fetch(serverUrl + plannerRoute + "/usersFutureFreeDays" + sendVars, getOptions());
}

export function httpGetIssuesForUser(user, search = null) {
  return fetch(serverUrl + jiraRoute + "/issues?user=" + user + "&search=" + search, getOptions());
}

export function httpGetUsersHours(startDate, endDate, projectId, skip, limit, auditStatus) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate + "&project=" + projectId +
                 (skip == null ? "" : "&skip=" + skip + "&limit=" + limit) + "&auditStatus=" + auditStatus;
  return fetch(serverUrl + hoursRoute + "/usersProjectHours" + sendVars, getOptions());
}

export function httpGetIsInAuditGroup() {
  return fetch(serverUrl + userRoute + "/userGroups", getOptions());
}

export function httpGetAllUsersHoursForProject(projectId, skip, limit, auditStatus) {
  let sendVars = "?project=" + projectId + "&skip=" + skip + "&limit=" + limit + "&auditStatus=" + auditStatus;
  return fetch(serverUrl + hoursRoute + "/allProjectHours" + sendVars, getOptions());
}

export function httpGetAllUserHoursForProject(workerId, projectId, auditStatus) {
  let sendVars = "?project=" + projectId + "&workerId=" + workerId + "&auditStatus=" + auditStatus;
  return fetch(serverUrl + userRoute + "/allProjectHours" + sendVars, getOptions());
}

export function httpGetHoursAdmin(startDate, endDate) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;
  return fetch(serverUrl + adminRoute + "/hours" + sendVars, getOptions());
}

export function httpGetAllHoursAdmin(startDate, endDate) {
  let sendVars = "?startDate=" + startDate + "&endDate=" + endDate;
  return fetch(serverUrl + adminRoute + "/hours" + sendVars, getOptions());
}

export function httpGetCalendars() {
  return fetch(serverUrl + adminRoute + "/calendars", getOptions());
}

export function httpAddAdminFreeDayRow(rowData) {
  return fetch(serverUrl + adminRoute + "/addFreeDay", getOptions("POST", rowData));
}

export function httpAddAdminActivityRow(rowData) {
  return fetch(serverUrl + adminRoute + "/addActivityRow", getOptions("POST", rowData));
}

export function httpdeleteAdminHoursActivityReport(rowData) {
  return fetch(serverUrl + adminRoute + "/deleteHoursActivityReport", getOptions("DELETE", rowData));
}

export function httpdeleteAdminFreeDayReport(rowData) {
  return fetch(serverUrl + adminRoute + "/deleteFreeDay", getOptions("DELETE", rowData));
}

export function httpUpdateAdminFreeDayReport(rowData) {
  return fetch(serverUrl + adminRoute + "/updateFreedayRow", getOptions("PUT", rowData));
}

export function httpUpdateAdminHoursActivityReport(rowData) {
  return fetch(serverUrl + adminRoute + "/updateActivityRow", getOptions("PUT", rowData));
}
