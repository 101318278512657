import React, { Component } from "react";
import { connect } from "react-redux";
import "./Planner.css";

import {
  httpDeleteFreeDaysRange,
  httpUpdateOrAddFreeDaysRange,
  httpGetUsersFreeDays,
  httpGetAllFutureUserFreeDays,
} from "../../Urls";
import PlansList from "./PlansData/PlansList";
import FreeDayReportDataModel from "../../common/DataModels/FreeDayReportDataModel";
import { updateCurrentPage } from "../../stores/actions";
import { bindActionCreators } from "redux";
import FreeDayReportDatesWorker, {
  DatesWorker,
} from "../../common/DataModels/FreeDayReportDatesWorker";

class Planner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workersFreeDays: {},
      isMounted: true,
      thisUser: {
        label: this.props.user.profile.name,
        value: this.props.user.userName,
      },
      thisUserId: null,
    };
    this.removePlan = this.removePlan.bind(this);
    this.updateOrAddPlan = this.updateOrAddPlan.bind(this);
    this.fetchWorkersFreeDays = this.fetchWorkersFreeDays.bind(this);
  }

  async componentDidMount() {
    this.setState({ isMounted: true });
    let projectObjects = Array.isArray(this.props.selectedWorkersObjects)
      ? this.props.selectedWorkersObjects
      : [this.props.selectedWorkersObjects];

    this.onWorkerChanged(
      this.props.startDate,
      this.props.endDate,
      projectObjects
    );

    this.props.updateCurrentPage("Planner");
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  async onWorkerChanged(fromDate, tillDate, workersObjects) {
    if (
      !workersObjects ||
      workersObjects.length === 0 ||
      this.props.managerWorkers.length === 0
    ) {
      return;
    }
    if (!Array.isArray(workersObjects)) workersObjects = [workersObjects];
    let workers = workersObjects;
    if (workersObjects[0] && workersObjects[0].value === "*") {
      const cloned = [...workersObjects];
      cloned.shift();
      workers = cloned;
    }
    //workers = workers.filter(val => !this.state.workersFreeDays.hasOwnProperty(val.value));

    let ids = workers.map(
      (worker) =>
        this.props.managerWorkers.Workers.find(
          (val) => val.Id.toLowerCase() === worker.value.toLowerCase()
        ).EmployeeId
    );
    if (!this.state.isMounted) return;
    if (ids.length !== 0)
      if (this.props.allFuture) {
        this.fetchWorkersFreeDays(
          null,
          null,
          ids,
          this.props.approvalStatus.value
        );
      } else {
        this.fetchWorkersFreeDays(
          this.props.startDate,
          this.props.endDate,
          ids,
          this.props.approvalStatus.value
        );
      }
  }

  async fetchWorkersFreeDays(fromDate, tillDate, ids, filterStatusId = -1) {
    this.setState({ workersFreeDays: {} });
    if (isNaN(ids[0])) ids.shift();
    const user = { name: this.props.user.profile.name.toLowerCase(), data: [] };
    let workerObjects = Array.isArray(this.props.selectedWorkersObjects)
      ? this.props.selectedWorkersObjects
      : [this.props.selectedWorkersObjects];
    let response = !tillDate
      ? await httpGetAllFutureUserFreeDays(fromDate, -1, ids, filterStatusId)
      : await httpGetUsersFreeDays(fromDate, tillDate, -1, ids, filterStatusId);
    let workersFreeDays = this.state.workersFreeDays;
    // const userId = this.props.managerWorkers.Workers.find(w => w.Id === this.props.user.userName).EmployeeId;
    //if (ids.includes(userId)) workersFreeDays[this.props.user.userName]  = user;
    if (response.status === 200) {
      response.json().then((resp) => {
        for (const item of resp) {
          const workerId = item.Email
          item.UsersFreeDays.reverse();
         
          workersFreeDays[workerId.toLowerCase()] = {
            
            name: workerObjects.find(
              (val) => val.value.toLowerCase() === workerId.toLowerCase()
            ).label,
            data: item.UsersFreeDays,
            vacationsBalance: item.VacationsBalance,
            userRelation : item.UserRelation
          };
        }

        if (!this.state.isMounted) return;
        this.setState({ workersFreeDays: workersFreeDays });
      });
    } else if (response.status === 204) {
      // no content
      let workersFreeDays = {};
      workersFreeDays[this.props.user.userName.toLowerCase()] = user;
      this.setState({ workersFreeDays: workersFreeDays });
    }
  }

  fetchFreeDays = () => {
    const workers = Array.isArray(this.props.selectedWorkersObjects)
      ? this.props.selectedWorkersObjects
      : [this.props.selectedWorkersObjects];
    let ids = workers.map(
      (worker) =>
        this.props.managerWorkers.Workers.find(
          (val) => val.Id.toLowerCase() === worker.value.toLowerCase()
        ).EmployeeId
    );

    if (this.props.allFuture) {
      this.fetchWorkersFreeDays(
        null,
        null,
        ids,
        this.props.approvalStatus.value
      );
    } else {
      this.fetchWorkersFreeDays(
        this.props.startDate,
        this.props.endDate,
        ids,
        this.props.approvalStatus.value
      );
    }
  };

  /**
   * remove a row of acticity
   * @param {Number} rowId
   */
  async removePlan(planToRemove) {
    let resp = await httpDeleteFreeDaysRange(planToRemove.entriesIds);

    if (resp.status === 200) {
      this.fetchFreeDays();
    }
  }

  getThisUserId() {
    if (this.state.thisUserId) {
      return this.state.thisUserId;
    } else {
      const userId = this.props.managerWorkers.Workers.find(
        (worker) => worker.Id === this.state.thisUser.value
      ).EmployeeId;
      this.setState({ thisUserId: userId });
      return userId;
    }
  }

  // /**
  //  * fetch the server to update or add a new row of activity
  //  * @param {HoursActivityDataModel} rowData
  //  */
  async updateOrAddPlan(newPlan, oldPlan, employeeId, approvalUpdate = false) {
    let resp;
    if (newPlan.ID !== undefined) {
      //!approvalUpdate &&
      resp = await httpDeleteFreeDaysRange(oldPlan.entriesIds);
    }
    let fromDate = newPlan.frm.clone();
    let toDate = newPlan.till.clone();
    let approved = newPlan.approved;
    let rowsToAdd = [];

    var entriesIdCopy = [...oldPlan.entriesIds];
    while (fromDate.isSameOrBefore(toDate, "day")) {
      let report;
      if (approvalUpdate) {
        const days = this.state.workersFreeDays[employeeId].data;
        for (let index = 0; index < days.length; index++) {
          report = days[index].FreeDayReport.find(
            (val) => val.ID === entriesIdCopy[entriesIdCopy.length - 1]
          );
          if (report) break;
        }
      }

      if (approvalUpdate && !report) return;

      rowsToAdd.push(
        new FreeDayReportDataModel(
          approvalUpdate ? entriesIdCopy.pop() : undefined,
          parseInt(newPlan.type, 10),
          fromDate.format("YYYY-MM-DD"),
          approvalUpdate ? report.Percentage : newPlan.percentage,
          newPlan.description,
          approved
        )
      );
      fromDate.add(1, "day");
    }

    const data = new FreeDayReportDatesWorker(
      [[], rowsToAdd],
      new DatesWorker(
        this.props.startDate,
        this.props.endDate,
        employeeId,
        approvalUpdate
      )
    );

    resp = await httpUpdateOrAddFreeDaysRange(data);

    if (resp.status === 200) {
      let workers = Array.isArray(this.props.selectedWorkersObjects)
        ? [...this.props.selectedWorkersObjects]
        : [this.props.selectedWorkersObjects];
      if (workers[0].value === "*") workers.shift();
      if (!approvalUpdate) this.fetchFreeDays();
    }
  }

  async onDatesChanged(startDate, endDate) {
    if (this.props.selectedWorkersObjects === undefined) return;
    let selectedWorkersObjects = this.props.selectedWorkersObjects;
    if (!Array.isArray(selectedWorkersObjects))
      selectedWorkersObjects = [selectedWorkersObjects];
    this.setState({ workersFreeDays: {} });
    if (startDate === null) {
      startDate = this.props.startDate;
    }

    if (endDate === null) {
      endDate = this.props.endDate;
    }

    const selectedWorkers = [...selectedWorkersObjects];
    if (selectedWorkers[0].value === "*") selectedWorkers.shift();
    if (!this.state.isMounted) return;
    this.fetchFreeDays();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedWorkersObjects !== this.props.selectedWorkersObjects &&
      this.props.startDate === prevProps.startDate &&
      this.props.endDate === prevProps.endDate
    ) {
      this.onWorkerChanged(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedWorkersObjects
      );
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.onDatesChanged(this.props.startDate, this.props.endDate);
    }
    if (
      prevProps.managerWorkers !== this.props.managerWorkers &&
      this.props.startDate === prevProps.startDate &&
      this.props.endDate === prevProps.endDate
    ) {
      this.onWorkerChanged(
        this.props.startDate,
        this.props.endDate,
        this.props.selectedWorkersObjects
      );
    }
    if (prevProps.approvalStatus !== this.props.approvalStatus) {
      this.fetchFreeDays();
    }
    if (prevProps.allFuture !== this.props.allFuture) {
      this.fetchFreeDays();
    }
  }

  render() {
    if (!this.props.managerWorkers.Workers) return null;
    const {
      managerWorkers,
      selectedWorkersObjects,
      freeDayTypes,
      startDate,
      endDate,
      user,
      isInTopGroup,
    } = this.props;
    const workers = [...managerWorkers.Workers];
    const index = workers.findIndex(
      (val) => val.Id.toLowerCase() === user.userName,
      1
    );
    const currentUser = workers.splice(index, 1)[0];
    workers.unshift(currentUser);
    return workers.map((worker) => {
      let workerId = worker.Id.toLowerCase();
      const projectObjects = Array.isArray(selectedWorkersObjects)
        ? selectedWorkersObjects
        : [selectedWorkersObjects];
      if (
        projectObjects.find(
          (val) => val.value.toLowerCase() === worker.Id.toLowerCase()
        ) &&
        this.state.workersFreeDays.hasOwnProperty(workerId)
      ) {
        let data = this.state.workersFreeDays[workerId];
        return (
          <span key={workerId}>
            <PlansList
              isInTopGroup={isInTopGroup}
              removeRow={this.removePlan}
              updateOrAddRow={this.updateOrAddPlan}
              FreeDaysListData={data.data}
              FreeDayType={freeDayTypes}
              WorkerName={data.name}
              VacationsBalance={data.vacationsBalance}
              employeeId={workerId}
              startDate={startDate}
              endDate={endDate}
              thisUser={user.userName}
             userRelation={data.userRelation}
            />
          </span>
        );
      }
      return null;
    });
  }
}

const mapStateToProps = function (state) {
  return {
    startDate: state.dates.startDate,
    endDate: state.dates.endDate,
    freeDayTypes: state.projects.freeDayTypes,
    currentPage: state.general.currentPage,
    selectedWorkersObjects: state.workers.selectedWorkersObjects,
    managerWorkers: state.workers.managerWorkers,
    approvalStatus: state.freeDays.approvalStatus,
    allFuture: state.dates.allFuture,
    isInTopGroup: state.general.userGroups.includes(1),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateCurrentPage,
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Planner);
