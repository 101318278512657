/**
 * Created by nicom on 6/27/2017.
 */

class HoursActivityDataModel {
  
  constructor(
    
    id,
    projectId,
    day,
    startTime,
    duration,
    description,
    jsonDescription,
    workerId,
    isAudit = false,
    employeeId = null,
    reportAdminId = null
    
  ) {
    
    this.ID = id;
    this.ProjectID = projectId;
    this.CalendarDayId = day;
    this.StartTime = startTime;
    this.Duration = duration;
    this.Description = description;
    this.JsonDescription = jsonDescription;
    this.WorkerId = workerId;
    this.IsAudit = isAudit;
    this.EmployeeID = employeeId;
    this.reportAdminId = reportAdminId;
  }
}

export default HoursActivityDataModel;
