import {
  UPDATE_ALERTS,
  UPDATE_HOURS,
  UPDATE_WORKED_DETAILS,
} from "../actionTypes";

var defaultState = {
  alerts: [],
  hours: [],
  workedDetails: {},
};

export default function general(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_ALERTS:
      return { ...state, alerts: action.alerts };
    case UPDATE_HOURS:
      return { ...state, hours: action.hours };
    case UPDATE_WORKED_DETAILS:
      return { ...state, workedDetails: action.details };
    default:
      return state;
  }
}
