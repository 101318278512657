/**
 * Created by nicom on 5/1/2017.
 */
import React, { Component } from "react";
import DatesPicker from "../DatesPicker";
import "../../components/DashBoardPage/DashBoard.css";
import "../../components/DashBoardPage/bootstrap-multiselect.css";
import "./OptionsNav.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { batchActions } from "redux-batched-actions";
import { httpGetManagerWorkers } from "../../Urls";
import ManagerWorkersHelper from "../../common/ManagerWorkersHelper";
import {
  updateIsShowingWorkers,
  updateManagerWorkers,
  updateStartDate,
  updateEndDate,
  updateSelectedWorkersIds,
  updateSelectedProjectsIds,
  updateAllDates,
  updateSelectedProjectsObjects,
  updateSelectedWorkersObjects,
  updateAllDatesEnabled,
  updateWorkers,
  updateProjects,
  updateApprovalStatus,
  updateAllFuture,
  updateSelectedAuditOption,
  updateAllSelected,
} from "../../stores/actions";
import shallowCompareArrays from "../../common/ShallowComparer";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css"; // Include CSS
import { Toggle } from "react-toggle-component";

class OptionsNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkerCombo: false,
      showProjectCombo: false,
      approvalStatusValue: "All",
      allProjects: true,
    };
    this.updateFromLocalStorage();
    this.updateFromUrlParams();
    this.onSelectWorkers = this.onSelectWorkers.bind(this);
    this.onSelectProjects = this.onSelectProjects.bind(this);
    this.onToggleAllDates = this.onToggleAllDates.bind(this);
    this.getWorkersMultiSelect = this.getWorkersMultiSelect.bind(this);
    this.getProjectsMultiSelect = this.getProjectsMultiSelect.bind(this);
    this.getManagerWorkers = this.getManagerWorkers.bind(this);
    this.clearFilterState = this.clearFilterState.bind(this);
    this.updateFromLocalStorage = this.updateFromLocalStorage.bind(this);
    this.mapProjectsToAllSelected = this.mapProjectsToAllSelected.bind(this);
    this.mapWorkersToAllSelected = this.mapWorkersToAllSelected.bind(this);
    this.checkIfExists = this.checkIfExists.bind(this);
    this.inFreeDays = this.inFreeDays.bind(this);
    this.onToggleAllFuture = this.onToggleAllFuture.bind(this);
  }

  async getManagerWorkers(startDate, endDate) {
    let resp = await httpGetManagerWorkers(startDate, endDate);
    if (resp.status === 200) {
      resp = await resp.json();
      if (resp.length > 0) {
        return new ManagerWorkersHelper(resp);
      }
    }
  }

  onSelectWorkers = (selectedWorkers) => {
    let workers;
    if (selectedWorkers === null) {
      selectedWorkers = [];
    } else if (!Array.isArray(selectedWorkers)) {
      workers = [selectedWorkers.value];
    } else {
      let workersWithoutAll = Object.assign([], selectedWorkers);
      if (selectedWorkers[0] && selectedWorkers[0].label === "Select all") {
        workersWithoutAll.shift();
      }
      workers = workersWithoutAll.map((worker) => worker.value);
    }
    if (!workers) workers = [];
    this.props.dispatch(
      batchActions([
        updateSelectedWorkersObjects(selectedWorkers),
        updateSelectedWorkersIds(workers),
      ])
    );
  };

  onSelectProjects(selectedProjects) {
    let projects;
    if (selectedProjects === null) {
      projects = [];
    } else if (!Array.isArray(selectedProjects)) {
      projects = [selectedProjects.value];
    } else {
      const diff = this.props.projects.filter(
        (firstObj) =>
          !selectedProjects.some(
            (secondObj) => firstObj.value === secondObj.value
          )
      );

      if (diff.length === 1 && this.state.allProjects) {
        // if all projects were selected and then one deselected
        selectedProjects = diff;
      }
      if (this.state.allProjects) {
        this.setState({ allProjects: false });
      }
      if (diff.length === 0) {
        this.setState({ allProjects: true });
      }

      projects = selectedProjects.map((project) => project.value);
    }
    if (!projects) projects = [];
    this.props.dispatch(
      batchActions([
        updateSelectedProjectsObjects(selectedProjects),
        updateSelectedProjectsIds(projects),
        updateAllSelected(false),
      ])
    );
  }

  onSelectApprovalStatus = (status) => {
    this.props.updateApprovalStatus(status);
  };

  inFreeDays(value) {
    return value >= 1001 && value <= 1005;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.managerWorkers !== undefined &&
      this.props.managerWorkers.Workers !== undefined &&
      (this.props === undefined ||
        shallowCompareArrays(
          prevProps.managerWorkers.Workers,
          this.props.managerWorkers.Workers
        ))
    ) {
      const workers = this.mapWorkers(this.props.managerWorkers.Workers);
      this.props.updateWorkers(workers);
    }
    if (
      this.props.managerProjects !== undefined &&
      (prevProps === undefined ||
        shallowCompareArrays(
          prevProps.managerProjects,
          this.props.managerProjects
        ))
    ) {
      let projects = this.mapProjects(this.props.managerProjects);
      this.props.updateProjects(projects);
      if (
        prevProps.managerProjects.length ===
        prevProps.selectedProjectsIds.length
      ) {
        // if all was selected
        this.mapProjectsToAllSelected(this.props.managerProjects);
      }
    } else if (
      this.props.userProjects !== undefined &&
      (prevProps === undefined ||
        shallowCompareArrays(prevProps.userProjects, this.props.userProjects))
    ) {
      let projects = this.mapProjects(this.props.userProjects);
      this.props.updateProjects(projects);
    }
    if (
      this.props.selectedProjectsObjects !== prevProps.selectedProjectsObjects
    ) {
      let isArray = Array.isArray(this.props.selectedProjectsObjects);
      if (
        (this.props.currentPage !== "Planner" &&
          isArray &&
          this.props.selectedProjectsObjects.length === 1 &&
          this.props.selectedProjectsObjects[0].value === "*") ||
        (this.props.currentPage === "Dashboard" &&
          !isArray &&
          this.props.selectedProjectsObjects &&
          this.props.selectedProjectsObjects.value === -1)
      ) {
        this.mapProjectsToAllSelected();
      }
    }
    if (this.props.currentPage !== prevProps.currentPage) {
      // on page change - must update projects accordingly
      this.updateSelectedProjectsObjects(this.props);
    }
  }

  mapProjects(projects) {
    return projects.map((project) => {
      return {
        label: project.Name,
        value: project.ID,
        selected: project.ID === projects[0].ID,
      };
    });
  }

  mapWorkers(workers) {
    return workers.map((worker) => {
      return {
        label: worker.EngName,
        value: worker.Id,
        selected: worker.Id.toLowerCase() === this.props.user.userName,
      };
    });
  }

  setActive(what, event) {
    //if (this.props.currentPage === 'Dashboard') {
    let isShowingWorkers = this.props.isShowingWorkers;
    let shouldStopEvent = true;
    if (what === "workers") {
      if (isShowingWorkers) {
        shouldStopEvent = false;
      }
      isShowingWorkers = true;
    } else {
      if (!isShowingWorkers) {
        shouldStopEvent = false;
      }
      isShowingWorkers = false;
    }

    this.props.updateIsShowingWorkers(isShowingWorkers);

    return !shouldStopEvent;
    //}
  }

  mapProjectsToAllSelected(managerProjects = null) {
    let projects = managerProjects
      ? managerProjects
      : this.props.managerProjects;
    let mappedProjs = projects.map((proj) => {
      return { label: proj.Name, value: proj.ID, selected: true };
    });
    let projIds = projects.map((proj) => proj.ID);
    this.props.dispatch(
      batchActions([
        updateSelectedProjectsObjects(mappedProjs),
        updateSelectedProjectsIds(projIds),
        updateAllSelected(true),
      ])
    );
    this.setState({ allProjects: true });
  }

  mapWorkersToAllSelected() {
    let workers = this.props.managerWorkers.Workers;
    let mappedWorkers = workers.map((worker) => {
      return { label: worker.EngName, value: worker.Id, selected: true };
    });
    this.props.updateSelectedWorkersObjects(mappedWorkers);
  }

  updateSelectedProjectsObjects(nextProps) {
    let isArray = Array.isArray(nextProps.selectedProjectsObjects);
    let len = isArray ? nextProps.selectedProjectsObjects.length : null;
    if (
      (nextProps.currentPage === "Hours" && !this.checkIfExists(nextProps)) ||
      nextProps.selectedProjectsObjects === null ||
      (isArray &&
        (len === 0 ||
          (nextProps.selectedProjectsObjects[0].label === "Select all" &&
            nextProps.currentPage !== "Dashboard") ||
          (!this.props.isShowingWorkers &&
            len > 1 &&
            nextProps.currentPage === "Hours")))
    ) {
      this.props.dispatch(
        batchActions([
          updateSelectedProjectsObjects({
            label: "Select Project",
            selected: true,
            value: -1,
          }),
          updateSelectedProjectsIds([-1]),
          updateAllDates(false),
          updateAllDatesEnabled(false),
        ])
      );
    } else if (
      nextProps.currentPage !== "Hours" &&
      nextProps.selectedProjectsObjects &&
      (nextProps.selectedProjectsObjects.value === -1 ||
        this.inFreeDays(nextProps.selectedProjectsObjects.value))
    ) {
      if (nextProps.currentPage === "Dashboard") {
        this.mapProjectsToAllSelected(nextProps.managerProjects);
      }
    }
  }

  checkIfExists(props) {
    // checks if selectedProject exists for user
    if (!props || !props.selectedProjectsObjects) return;
    // if not array - did not come from Dashboard so no need to check in user projects
    const isArray = Array.isArray(props.selectedProjectsObjects);
    const len = props.selectedProjectsObjects.length;
    return (
      !isArray ||
      (isArray &&
        len === 1 &&
        props.userProjects.find(
          (proj) => proj.ID === props.selectedProjectsObjects[0].value
        ))
    );
  }

  updateSelectdeWorkersObjects(props) {
    if (
      (props.currentPage === "Hours" || props.currentPage === "Planner") &&
      (!props.selectedWorkersObjects ||
        (Array.isArray(props.selectedWorkersObjects) &&
          props.selectedWorkersObjects.length === 0))
    ) {
      const worker = {
        label: window.user.profile.name,
        value: window.user.userName,
        selected: true,
      };
      this.props.updateSelectedWorkersObjects(worker);
      this.props.updateSelectedWorkersIds([worker.value]);
    }
  }

  getWorkersMultiSelect() {
    const { isShowingWorkers, currentPage } = this.props;
    let isMulti = currentPage !== "Hours";
    let disablePages = currentPage === "Dashboard" || currentPage === "Hours";
    return (
      <Picky
        className={!isShowingWorkers && disablePages ? "picky_disabled" : ""}
        options={this.props.workers}
        value={
          isMulti && !Array.isArray(this.props.selectedWorkersObjects)
            ? [this.props.selectedWorkersObjects]
            : this.props.selectedWorkersObjects
        }
        valueKey="value"
        labelKey="label"
        multiple={isMulti}
        includeSelectAll={true}
        includeFilter={true}
        onChange={this.onSelectWorkers}
        dropdownHeight={600}
        disabled={!isShowingWorkers && disablePages}
        clearFilterOnClose={true}
        keepOpen={isMulti}
      />
    );
  }

  getProjectsMultiSelect() {
    const { isShowingWorkers, currentPage } = this.props;
    let isMulti = currentPage !== "Hours" && currentPage !== "Admin";
    let disablePages = currentPage === "Dashboard" || currentPage === "Hours";
    return (
      <Picky
        className={
          disablePages && currentPage !== "Hours" && isShowingWorkers
            ? "picky_disabled"
            : ""
        }
        options={
          !isMulti && isShowingWorkers
            ? [{ label: "All projects", value: -1, selected: true }].concat(this.props.projects)
            : this.props.projects
        }
        numberDisplayed={1}
        value={
          isMulti && !Array.isArray(this.props.selectedProjectsObjects)
            ? [this.props.selectedProjectsObjects]
            : this.props.selectedProjectsObjects
        }
        valueKey="value"
        labelKey="label"
        multiple={isMulti}
        includeSelectAll={true}
        includeFilter={true}
        onChange={this.onSelectProjects}
        dropdownHeight={600}
        placeholder="Select project"
        disabled={disablePages && currentPage !== "Hours" && isShowingWorkers}
        clearFilterOnClose={true}
        keepOpen={isMulti}
      />
    );
  }

  getApprovalStatusMultiSelect = () => {
    const options = [
      { label: "All", value: -1 },
      { label: "Approved", value: 2 },
      { label: "Declined", value: 3 },
      { label: "Pending", value: 1 },
    ];
    return (
      <Picky
        options={options}
        numberDisplayed={1}
        value={this.props.approvalStatus}
        valueKey="value"
        labelKey="label"
        multiple={false}
        includeSelectAll={true}
        includeFilter={false}
        onChange={(val) => this.onSelectApprovalStatus(val)}
        dropdownHeight={600}
        clearFilterOnClose={true}
        keepOpen={false}
      />
    );
  };

  renderManagerOptions() {
    const { isShowingWorkers, currentPage } = this.props;
    const showToggle = currentPage !== "Planner";
    return (
      <span className="managerOptions">
        <span>{this.getWorkersMultiSelect()}</span>
        {showToggle && (
          <Toggle
            knobColor="#225390"
            borderColor="#225390"
            name="toggle-1"
            className="toggle"
            onToggle={this.handleSetActive}
            checked={!isShowingWorkers}
          />
        )}

        {this.props.currentPage !== "Planner" && (
          <span>
            <span>
              <span>{this.getProjectsMultiSelect()}</span>
            </span>
          </span>
        )}
        {this.props.currentPage === "Planner" && (
          <span>
            <span>{this.getApprovalStatusMultiSelect()}</span>
          </span>
        )}
      </span>
    );
  }

  handleSetActive = () => {
    this.props.isShowingWorkers
      ? this.setActive("projects")
      : this.setActive("workers");
  };

  updateFromLocalStorage() {
    if (localStorage.getItem("startDate")) {
      this.props.dispatch(
        batchActions([
          updateStartDate(localStorage.getItem("startDate")),
          updateEndDate(localStorage.getItem("endDate")),
          updateAllDates(localStorage.getItem("allDates") === "true"),
          updateAllDatesEnabled(
            localStorage.getItem("allDatesEnabled") === "true"
          ),
          updateSelectedWorkersIds(
            JSON.parse(localStorage.getItem("selectedWorkersIds"))
          ),
          updateSelectedWorkersObjects(
            JSON.parse(localStorage.getItem("selectedWorkersObjects"))
          ),
          updateSelectedProjectsIds(
            JSON.parse(localStorage.getItem("selectedProjectsIds"))
          ),
          updateSelectedProjectsObjects(
            JSON.parse(localStorage.getItem("selectedProjectsObjects"))
          ),
          updateIsShowingWorkers(
            localStorage.getItem("isShowingWorkers") === "true"
          ),
          updateAllFuture(localStorage.getItem("allFuture") === "true"),
          updateSelectedAuditOption(
            JSON.parse(localStorage.getItem("selectedAuditOption"))
          ),
        ])
      );
      this.clearFilterState();
    }
  }
  updateFromUrlParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const s = urlParams.get("startDate");
    const e = urlParams.get("endDate");
    if (s) this.props.updateStartDate(s);
    if (e) this.props.updateEndDate(e);

    const emp = urlParams.get("employeeId");
    if (emp) {
      this.props.updateSelectedWorkersIds([emp]);
    }
    window.history.pushState(null, "", window.location.href.split("?")[0]);
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.clearFilterState);

    if (this.props.managerWorkers.length === 0) {
      // workers are the same across all pages
      let managerWorkers = await this.getManagerWorkers(
        this.props.startDate,
        this.props.endDate
      );
      this.props.updateManagerWorkers(managerWorkers);
    }
    if (!this.props.selectedWorkersIds || !this.props.selectedWorkersIds[0]) {
      this.props.dispatch(
        batchActions([
          updateSelectedWorkersIds([this.props.user.userName]),
          updateSelectedWorkersObjects([
            {
              label: this.props.user.profile.name,
              value: this.props.user.userName,
            },
          ]),
          updateSelectedProjectsIds([-1]),
        ])
      );

      if (this.props.currentPage === "Dashboard") {
        // app loaded from dashboard page
        this.mapProjectsToAllSelected();
      }
    }
    if (
      this.props.selectedWorkersIds.length > 0 &&
      this.props.selectedWorkersObjects.length === 0
    ) {
      let empsObj = this.props.workers.find(
        (w) => w.value === this.props.selectedWorkersIds[0]
      );
      this.props.updateSelectedWorkersObjects([empsObj]);
    }
  }

  clearFilterState() {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("allDates");
    localStorage.removeItem("allDatesEnabled");
    localStorage.removeItem("selectedWorkersIds");
    localStorage.removeItem("selectedWorkersObjects");
    localStorage.removeItem("selectedProjectsIds");
    localStorage.removeItem("selectedProjectsObjects");
    localStorage.removeItem("isShowingWorkers");
    localStorage.removeItem("allFuture");
    localStorage.removeItem("selectedAuditOption");
  }

  onDatesChanged = async (startDate, endDate) => {
    if (!startDate) startDate = this.props.startDate;
    if (!endDate) endDate = this.props.endDate;
    if (this.props.selectedWorkersObjects === undefined) return;
    let resp = await httpGetManagerWorkers(startDate, endDate);
    if (resp.status === 200) {
      resp = await resp.json();
      if (resp.length > 0) {
        let managerWorkers = new ManagerWorkersHelper(resp);
        let managerWorkersIds = managerWorkers.Workers.map((w) => w.Id);
        let selectedWorkersIds = this.props.selectedWorkersIds.filter(
          (workerId) => managerWorkersIds.indexOf(workerId) !== -1
        );
        const workers = Array.isArray(this.props.selectedWorkersObjects)
          ? this.props.selectedWorkersObjects
          : [this.props.selectedWorkersObjects];
        let selectedWorkersObjects;
        if (workers[0] && workers[0].value === "*") {
          let workers = managerWorkers.Workers;
          selectedWorkersObjects = workers.map((worker) => {
            return { label: worker.EngName, value: worker.Id, selected: true };
          });
          selectedWorkersObjects = [
            { label: "Select all", value: "*", selected: true },
            ...selectedWorkersObjects,
          ];
        } else {
          selectedWorkersObjects = workers.filter(
            (worker) =>
              managerWorkersIds.findIndex(
                (id) => worker.value.toLowerCase() === id.toLowerCase()
              ) !== -1
          );
        }
        if (selectedWorkersIds.length === 0) {
          selectedWorkersIds = [this.props.user.userName];
        }
        if (selectedWorkersObjects.length === 0) {
         
          selectedWorkersObjects = [
            { label: this.props.user.profile.name, value: this.props.user.userName, selected: true },
          ];
        }

        this.props.dispatch(
          batchActions([
            updateManagerWorkers(managerWorkers),
            updateSelectedWorkersIds(selectedWorkersIds),
            updateSelectedWorkersObjects(selectedWorkersObjects),
            updateStartDate(startDate),
            updateEndDate(endDate),
          ])
        );
      }
    }
  };

  onToggleAllDates() {
    this.props.updateAllDates(!this.props.allDates);
  }

  onToggleAllFuture = () => {
    this.props.updateAllFuture(!this.props.allFuture);
  };

  onSelectAuditOption = (val) => {
    this.props.updateSelectedAuditOption(val);
  };

  renderAuditOptions = () => {
    const isHoursPage = this.props.currentPage === "Hours";
    const options = isHoursPage
      ? [
          { label: "Original", value: 1 },
          { label: "Final", value: 2 },
          { label: "Comparison", value: 3 },
        ]
      : [
          { label: "Original", value: 1 },
          { label: "Final", value: 2 },
        ];
    const selected = !isHoursPage
      ? this.props.selectedAuditOption.value === 3
        ? options[0]
        : this.props.selectedAuditOption
      : this.props.selectedAuditOption;
    return (
      !this.props.currentPage !== "Dashboard" && (
        <Picky
          className="picky_audit"
          options={options}
          numberDisplayed={1}
          value={selected}
          valueKey="value"
          labelKey="label"
          multiple={false}
          includeSelectAll={true}
          includeFilter={false}
          onChange={(val) => this.onSelectAuditOption(val)}
          dropdownHeight={600}
          clearFilterOnClose={true}
          keepOpen={false}
        />
      )
    );
  };

  render() {
    const {
      currentPage,
      managerWorkers,
      managerProjects,
      startDate,
      endDate,
      allDates,
      allFuture,
      isInAuditGroup,
      allDatesEnabled,
    } = this.props;
    if (
      (currentPage === "Planner" && managerWorkers.Workers) || // in planner page we don't care about projects
      (managerWorkers.Workers && managerProjects.length !== 0) ||
      currentPage === "Admin"
    ) {
      return (
        <span className="optionNavContainer d-flex align-items-center flex-wrap mb-2">
          {currentPage !== "Admin" && this.renderManagerOptions()}
          <DatesPicker
            startDate={startDate}
            endDate={endDate}
            onDatesChanged={this.onDatesChanged}
            allDates={allDates}
            currentPage={currentPage}
            allFuture={allFuture}
          />
          {currentPage === "Hours" && (
            <span className="allDates">
              <input
                type="checkbox"
                id="allDates"
                checked={allDates}
                disabled={!allDatesEnabled}
                onChange={this.onToggleAllDates}
              />
              <label htmlFor="allDates">All Dates</label>
            </span>
          )}

          {isInAuditGroup &&
            this.props.currentPage !== "Planner" &&
            this.props.currentPage !== "Admin" &&
            (this.props.selectedProjectsIds[0] < 1000 ||
              this.props.isShowingWorkers) &&
            this.renderAuditOptions()}
          {currentPage === "Planner" && (
            <span className="allDates">
              <input
                type="checkbox"
                checked={allFuture}
                onChange={this.onToggleAllFuture}
              />
              <label htmlFor="allDates">All Future</label>
            </span>
          )}
        </span>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = function (state) {
  return {
    isShowingWorkers: state.workers.isShowingWorkers,
    startDate: state.dates.startDate,
    endDate: state.dates.endDate,
    managerWorkers: state.workers.managerWorkers,
    managerProjects:
      state.general.currentPage === "Dashboard"
        ? state.projects.allManagerProjects
        : state.projects.managerProjects,
    selectedWorkersIds: state.workers.selectedWorkersIds,
    selectedProjectsIds: state.projects.selectedProjectsIds,
    currentPage: state.general.currentPage,
    allDates: state.dates.allDates,
    allDatesEnabled: state.dates.allDatesEnabled,
    selectedProjectsObjects: state.projects.selectedProjectsObjects,
    selectedWorkersObjects: state.workers.selectedWorkersObjects,
    workers: state.workers.workers,
    projects: state.projects.projects,
    userProjects: state.projects.userProjects,
    approvalStatus: state.freeDays.approvalStatus,
    allFuture: state.dates.allFuture,
    selectedAuditOption: state.projects.selectedAuditOption,
    isInAuditGroup: state.general.userGroups.includes(2),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateIsShowingWorkers,
        updateManagerWorkers,
        updateStartDate,
        updateEndDate,
        updateSelectedWorkersIds,
        updateSelectedProjectsIds,
        updateAllDates,
        updateSelectedProjectsObjects,
        updateSelectedWorkersObjects,
        updateAllDatesEnabled,
        updateWorkers,
        updateProjects,
        updateApprovalStatus,
        updateAllFuture,
        updateSelectedAuditOption,
        updateAllSelected,
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsNav);
