import { httpGetIssuesForUser } from "../../../Urls";

export async function fetchUserIssues(search) {
  let username = window.user.userName.split("@")[0];
  let resp = await httpGetIssuesForUser(username, search);
  if (resp.status === 200) {
    let userIssues = await resp.json();
    const objects = sortIssuesIntoMentions(userIssues);
    return objects;
  }
}

export function sortIssuesIntoMentions(issues) {
  var objects = issues.map((v) => {
    return {
      id: `${v.Key} ${v.Summary}`,
      value: `${v.Key} ${v.Summary}`,
      link: `http://jira.tandemg.com/browse/${v.Key}`,
    };
  });
  return objects;
}
