import * as types from "./actionTypes";

export function updateIsShowingWorkers(value) {
  return {
    type: types.UPDATE_IS_SHOWING_WORKERS,
    value,
  };
}

export function updateStartDate(startDate) {
  return {
    type: types.UPDATE_START_DATE,
    startDate,
  };
}

export function updateEndDate(endDate) {
  return {
    type: types.UPDATE_END_DATE,
    endDate,
  };
}

export function updateManagerWorkers(workers) {
  return {
    type: types.UPDATE_MANAGER_WORKERS,
    workers,
  };
}

export function updateManagerProjects(projects) {
  return {
    type: types.UPDATE_MANAGER_PROJECTS,
    projects,
  };
}

export function updateSelectedWorkersIds(workersIds) {
  return {
    type: types.UPDATE_SELECTED_WORKERS_IDS,
    workersIds,
  };
}

export function updateSelectedProjectsIds(projectsIds) {
  return {
    type: types.UPDATE_SELECTED_PROJECTS_IDS,
    projectsIds,
  };
}

export function updateFreeDayTypes(freeDayTypes) {
  return {
    type: types.UPDATE_FREE_DAY_TYPES,
    freeDayTypes,
  };
}

export function updateCurrentPage(page) {
  return {
    type: types.UPDATE_CURRENT_PAGE,
    page,
  };
}

export function updateAllDates(allDates) {
  return {
    type: types.UPDATE_ALL_DATES,
    allDates,
  };
}

export function updateAllDatesEnabled(enabled) {
  return {
    type: types.UPDATE_ALL_DATES_ENABLED,
    enabled,
  };
}

export function updateSelectedProjectsObjects(objects) {
  return {
    type: types.UPDATE_SELECTED_PROJECTS_OBJECTS,
    objects,
  };
}

export function updateSelectedWorkersObjects(objects) {
  return {
    type: types.UPDATE_SELECTED_WORKERS_OBJECTS,
    objects,
  };
}

export function updateTimeFormat(format) {
  return {
    type: types.UPDATE_TIME_FORMAT,
    format,
  };
}

export function updateShowAbsentEmployeesModal(value) {
  return {
    type: types.UPDATE_SHOW_ABSENT_EMPLOYEES_MODAL,
    value,
  };
}

export function updateHeaderShowModal(value) {
  return {
    type: types.UPDATE_HEADER_SHOW_MODAL,
    value,
  };
}

export function updateAlerts(alerts) {
  return {
    type: types.UPDATE_ALERTS,
    alerts,
  };
}

export function updateUserProjects(projects) {
  return {
    type: types.UPDATE_USER_PROJECTS,
    projects,
  };
}

export function updateHours(hours) {
  return {
    type: types.UPDATE_HOURS,
    hours,
  };
}

export function updateWorkedDetails(details) {
  return {
    type: types.UPDATE_WORKED_DETAILS,
    details,
  };
}

export function updateProjectHelper(helper) {
  return {
    type: types.UPDATE_PROJECT_HELPER,
    helper,
  };
}

export function updateWorkers(workers) {
  return {
    type: types.UPDATE_WORKERS,
    workers,
  };
}

export function updateProjects(projects) {
  return {
    type: types.UPDATE_PROJECTS,
    projects,
  };
}

export function updateAllManagerProjects(allManagerProjects) {
  return {
    type: types.UPDATE_ALL_MANAGER_PROJECTS,
    allManagerProjects,
  };
}

export function updateApprovalStatus(approvalStatus) {
  return {
    type: types.UPDATE_APPROVAL_STATUS,
    approvalStatus,
  };
}

export function updateAllFuture(value) {
  return {
    type: types.UPDATE_ALL_FUTURE,
    value,
  };
}

export function updateIssues(issues) {
  return {
    type: types.UPDATE_ISSUES,
    issues,
  };
}

export function updateSelectedAuditOption(option) {
  return {
    type: types.UPDATE_SELECTED_AUDIT_OPTION,
    option,
  };
}

export function updateUserGroups(value) {
  return {
    type: types.UPDATE_USER_GROUPS,
    value,
  };
}

export function updateIsLoading(value) {
  return {
    type: types.UPDATE_IS_LOADING,
    value,
  };
}

export function updateAllSelected(value) {
  return {
    type: types.UPDATE_ALL_SELECTED,
    value,
  };
}
export function updateAdminHours(hours) {
  return {
    type: types.UPDATE_ADMIN_HOURS,
    hours,
  };
}
export function updateCalendars(calendars) {
  return {
    type: types.UPDATE_CALENDARS,
    calendars,
  };
}
