/**
 * Created by nicom on 8/19/2017.
 */
import WorkerDataModel from "./DataModels/WorkerDataModel";
class ManagerWorkersHelper {
  constructor(workersData) {
    this.Workers = [];
    workersData.map((worker) => {
      //Email is used as id throughout the system so we can not let in users without an email
      if (worker.Email && worker.Email.trim()) {
        let workerModel = new WorkerDataModel(
          worker.ID,
          worker.Email,
          worker.Name,
          worker.LastName,
          worker.HebName,
          worker.HebLast
        );
        this.Workers.push(workerModel);
      }
      return null;
    });
    this.Workers.sort((a, b) => a.EngName.localeCompare(b.EngName));
  }

  findWorkerIdByName(fullName) {
    let indexOf = this.Workers.indexOf(
      (worker) => worker.EngName === fullName || worker.HebName === fullName
    );
    return this.Workers[indexOf].Id;
  }
}

export default ManagerWorkersHelper;
