/**
 * Created by nicom on 8/1/2017.
 */

import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import "chart.piecelabel.js";

class CustomChart extends Component {
  render() {
    let data = this.props.data.GetDataObject();
    let options = {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: (tooltip, data) => {
            let index = tooltip.index;
            let label = data.labels[index];
            let percentage = data.datasets[0].data[index] + "%";
            return label + ": " + percentage;
          },
        },
      },
      pieceLabel: {
        render: "percentage",
      },
    };
    return (
      <div className="chartContainer">
        <Pie data={data} options={options} />
      </div>
    );
  }
}

export default CustomChart;
