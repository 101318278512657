import { Link } from "react-router-dom";
import "./HeaderNav.css";
import { connect } from "react-redux";
import { updateCurrentPage } from "../../stores/actions";

const getText = children => (children?.find?.(x => typeof x === "string") || children)?.trim?.();

/**
 * Defines a `react-router-dom` `Link`
 * @param {{
 *  children                 ,
 *  to             : string  ,
 *  pageName       : string  ,
 *  saveFilterState: Function
 * }} props
 * @param props.children If specified, the first non-empty string child defines the page name for state-management, as long as pageName isn't specified.
 * @param props.to If specified, defines the link url. Also defines the page name for state-management, as long as pageName isn't specified nor a non-empty string child.
 * @param props.pageName Defines the page name for state-management if specified. Overrides children and to.
 */
const HeaderNav = props => {

  const { to, children, saveFilterState, currentPage, updateCurrentPage } = props;
	const text = getText(children);
	const pageName = props.pageName || text || to?.substring(to.lastIndexOf("/") + 1);
  
  const navigate = e => !e.ctrlKey && updateCurrentPage?.(pageName);

	return (
		<Link className={"HeaderNav d-flex align-items-center gap-1" + (currentPage === pageName ? " active" : "")}
          to={{ pathname: to }}
          onClick={navigate}
          onMouseDown={saveFilterState}
    >
      {props.children}
    </Link>
	);
};

const mapStateToProps = state => ({
  currentPage: state.general.currentPage
});

const mapDispatchToProps = {
  updateCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
