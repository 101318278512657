/**
 * Created by nicom on 5/2/2017.
 */

import React, { Component } from "react";
import moment from "moment";
import InfoRowHours from "../../SharedComponents/InfoRowHours";
import FreeDayRow from "../../SharedComponents/FreeDayRow";
import HourRowData from "../../SharedComponents/DataModels/HourRowData";
import FreeDayRowData from "../../SharedComponents/DataModels/FreeDayRowData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../../css/Hours.css";
import { updateCalendars } from "../../../stores/actions";
import {
  httpAddAdminFreeDayRow,
  httpAddAdminActivityRow,
  httpdeleteAdminFreeDayReport,
  httpdeleteAdminHoursActivityReport,
  httpUpdateAdminHoursActivityReport,
  httpUpdateAdminFreeDayReport,
} from "../../../Urls";
import Popup from "../../Modals/Popup";

class WorkedHourItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addedHourRow: null,
      addedFreeDayRow: null,
      showModal: false,
      bodyPopup: "",
      titlePopup: "",
    };
    this.renderDate = this.renderDate.bind(this);
    this.renderDateWorkedHours = this.renderDateWorkedHours.bind(this);
    this.renderHoursRowsData = this.renderHoursRowsData.bind(this);
    this.renderNewHourRowData = this.renderNewHourRowData.bind(this);
    this.addNewHourRow = this.addNewHourRow.bind(this);
    this.removeNewHourRow = this.removeNewHourRow.bind(this);

    this.renderFreeDayDataRow = this.renderFreeDayDataRow.bind(this);
    this.renderNewFreeDayDataRow = this.renderNewFreeDayDataRow.bind(this);
    this.addNewFreeDayRow = this.addNewFreeDayRow.bind(this);
    this.removeNewFreeDayRow = this.removeNewFreeDayRow.bind(this);

    this.removeExistingActivityRow = this.removeExistingActivityRow.bind(this);
    this.addOrUpdateExistingActivityRow = this.addOrUpdateExistingActivityRow.bind(
      this
    );

    this.removeExistingFreeDayRow = this.removeExistingFreeDayRow.bind(this);
    this.addOrUpdateExistingFreeDayRow = this.addOrUpdateExistingFreeDayRow.bind(
      this
    );

    this.refreshData = this.refreshData.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }
  renderDate() {
    let dayData = moment(Date.now()).millisecond(0).second(0).minute(0).hour(0);
    let dayReceived = moment(new Date(this.props.dayData.Day.Date));

    if (dayData.format("DD.MM.YYYY") === dayReceived.format("DD.MM.YYYY"))
      return <span>Today - {dayReceived.format("DD.MM.YYYY")}</span>;
    else return <span>{dayReceived.format("dddd - DD.MM.YYYY")}</span>;
  }

  renderDateWorkedHours() {
    if (this.props.dayData.TotalHours > 0) {
      return "Total hours worked: " + this.props.dayData.TotalHours;
    }
  }

  renderHoursRowsData() {
    const { HoursWorked } = this.props.dayData;
    return HoursWorked.map((dataRow, index) => {
      const { dayData, projectHelper } = this.props;
      return (
        <InfoRowHours
          key={dataRow.ID + "" + index}
          timeFormat={this.props.timeFormat}
          projectsAvailable={projectHelper}
          projectsAllowed={projectHelper}
          rowData={dataRow}
          currentDay={dayData.Day.Date}
          projectHelper={projectHelper}
          removeRow={this.removeExistingActivityRow}
          updateOrAddRow={this.addOrUpdateExistingActivityRow}
          HoursWorked={HoursWorked}
          workerId={projectHelper}
          isLoading={this.props.isLoading}
          selectedAuditOption={1}
          ensureAllowedProjects={() => {
            return true;
          }}
          // workerId={this.props.workerId}
          thisUser={true}
          // deletedIds={deletedIds}
          showCalendar={true}
          calendar={{ Id: dataRow.CalendarID, Name: "" }}
          calendarsList={this.props.calendars}
        />
      );
    });
  }

  renderNewHourRowData() {
    if (this.state.addedHourRow !== null) {
      const { dayData, projectHelper } = this.props;
      return (
        <InfoRowHours
          // employeeID={
          //   dayData.HoursWorked[0] ? dayData.HoursWorked[0].EmployeeID : null
          // }
          rowData={this.state.addedHourRow}
          timeFormat={this.props.timeFormat}
          projectsAvailable={projectHelper}
          projectsAllowed={projectHelper}
          currentDay={dayData.Day.Date}
          projectHelper={projectHelper}
          removeRow={this.removeNewHourRow}
          updateOrAddRow={this.addOrUpdateExistingActivityRow}
          HoursWorked={this.props.dayData.HoursWorked}
          workerId={this.props.workerId}
          isLoading={this.props.isLoading}
          selectedAuditOption={1}
          ensureAllowedProjects={() => {
            return true;
          }}
          showCalendar={true}
          calendar={{
            Id: this.props.calendars[0].Id,
            Name: this.props.calendars[0].Name,
          }}
          calendarsList={this.props.calendars}
        />
      );
    }
  }

  renderFreeDayDataRow() {
    let { HoursFree, Day } = this.props.dayData;
    HoursFree = HoursFree ? HoursFree : [];
    const { FreeDayType } = this.props;
    return HoursFree.map((freeDayRow) => {
      return (
        <FreeDayRow
          key={freeDayRow.ID}
          rowData={freeDayRow}
          currentDay={Day.Date}
          removeRow={this.removeExistingFreeDayRow}
          updateOrAddRow={this.addOrUpdateExistingFreeDayRow}
          FreeDayType={FreeDayType}
          showButtons={true}
          showCalendar={true}
          calendar={{ Id: freeDayRow.CalendarId, Name: "" }}
          calendarsList={this.props.calendars}
        />
      );
    });
  }

  renderNewFreeDayDataRow() {
    if (this.state.addedFreeDayRow !== null) {
      const { Day } = this.props.dayData;
      const { FreeDayType } = this.props;
      return (
        <FreeDayRow
          rowData={this.state.addedFreeDayRow}
          currentDay={Day.Date}
          removeRow={this.removeNewFreeDayRow}
          updateOrAddRow={this.addOrUpdateExistingFreeDayRow}
          FreeDayType={FreeDayType}
          showButtons={true}
          showCalendar={true}
          calendar={{
            Id: this.props.calendars[0].Id,
            Name: this.props.calendars[0].Name,
          }}
          calendarsList={this.props.calendars}
        />
      );
    }
  }

  addNewHourRow() {
    if (this.state.addedHourRow === null) {
      const { HoursWorked } = this.props.dayData;
      let frmTime = "";
      let lastRow = HoursWorked[HoursWorked.length - 1];
      if (lastRow) {
        let timeArray = this.calculateDuration(
          lastRow.StartTime,
          lastRow.Duration
        );
        frmTime = timeArray[1]; // end time of last item is first time of new one by default
      }
      this.setState({ addedHourRow: new HourRowData(0, frmTime, "") });
    }
  }

  calculateDuration(StartTime, Duration) {
    let startTime = moment(StartTime, "HH:mm");
    let duration = moment(Duration, "HH:mm");
    let endTime = startTime
      .clone()
      .add(duration.hours(), "h")
      .add(duration.minutes(), "m");
    return [startTime, endTime];
  }

  removeNewHourRow() {
    this.setState({ addedHourRow: null });
  }

  addNewFreeDayRow() {
    const { HoursFree } = this.props.dayData;
    if (
      this.state.addedFreeDayRow === null &&
      (!HoursFree || HoursFree.length === 0)
    ) {
      this.setState({ addedFreeDayRow: new FreeDayRowData() });
    }
  }

  removeNewFreeDayRow() {
    this.setState({ addedFreeDayRow: null });
  }

  refreshData() {
    this.props.fetchData(this.props.dayData.Day.Date, this.props.dayData.Email);
  }

  async removeExistingActivityRow(rowData) {
    rowData.WorkerID = this.props.workerId;
    let resp = await httpdeleteAdminHoursActivityReport(rowData);
    if (resp.status === 200) {
      this.refreshData();
    }
  }

  async addOrUpdateExistingActivityRow(rowData) {
    let resp;
    if (rowData.ID === undefined) {
      resp = await httpAddAdminActivityRow(rowData, rowData.CalendarId);
    } else {
      resp = await httpUpdateAdminHoursActivityReport(rowData);
    }

    if (resp.status === 200) {
      const data = await resp.json();
      this.openPopup(data);
      this.refreshData();
    }
  }

  async removeExistingFreeDayRow(data) {
    data.WorkerID = this.props.workerId;
    let resp = await httpdeleteAdminFreeDayReport(data);

    if (resp.status === 200) {
      this.refreshData();
    }
  }
  getWorkerObjById(id) {
    return this.props.workers.find((w) => w.EmployeeId === id);
  }
  openPopup(data) {
    let title;
    let list = [];
    if (data.length > 0) {
      title = "Employees were not modified:";
      const users = data.map((id) => {
        const user = this.getWorkerObjById(id);
        return user.EngName;
      });
      list = (
        <ul>
          {users.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      );
    } else {
      title = "All employees were modified.";
    }

    this.setState({ titlePopup: title });
    this.setState({ bodyPopup: list, showModal: true, titlePopup: title });
  }
  async addOrUpdateExistingFreeDayRow(data) {
    let resp;
    if (data.ID === undefined) {
      resp = await httpAddAdminFreeDayRow(data);
    } else {
      resp = await httpUpdateAdminFreeDayReport(data);
    }

    if (resp.status === 200) {
      const data = await resp.json();
      this.openPopup(data);
      this.refreshData();
    }
  }

  render() {
    let backgroundcolor =
      this.props.dayData.Day.Percentage === "100" ? "#ffe7e2" : "#ffffff";
    let addFreeDayButton =
      (!this.props.dayData.HoursFree ||
        this.props.dayData.HoursFree.length === 0) &&
      this.state.addedFreeDayRow === null ? (
        <a
          className="new-free-day"
          onClick={this.addNewFreeDayRow}
          style={{ backgroundColor: "transparent" }}
        ></a>
      ) : null;
    return (
      <div
        className="itemContainer WorkedHourItem AdminWorkedHourItem"
        style={{ backgroundColor: backgroundcolor }}
      >
        <div className="hoursInfoRow">
          <div className="textInfoRow">
            <div className="dateShow">{this.renderDate()}</div>
          </div>
          <div className="buttonsInRowHour">
            <span>{addFreeDayButton}</span>
            <span>
              <a
                className="new-row"
                onClick={this.addNewHourRow}
                style={{ backgroundColor: "transparent" }}
              ></a>
            </span>
          </div>
        </div>

        <div>
          {this.renderFreeDayDataRow()}
          {this.renderNewFreeDayDataRow()}
          {this.renderHoursRowsData()}
          {this.renderNewHourRowData()}
        </div>
        <Popup
          title={this.state.titlePopup}
          body={this.state.bodyPopup}
          open={this.open}
          close={this.close}
          showModal={this.state.showModal}
        />
      </div>
    );
  }
}

//(this.props.user.userName === this.props.dayData.Email && this.props.selectedAuditOption.value === 1)

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateCalendars,
      },
      dispatch
    ),
    dispatch,
  };
}
const mapStateToProps = function (state) {
  return {
    calendars: state.general.calendars,
    workers: state.workers.managerWorkers.Workers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkedHourItem);
