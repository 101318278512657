class FormatUtils {
  static FormatDurationHoursAndMinutes(duration) {
    return duration
      ? duration.hours() + ":" + ("0" + duration.minutes()).slice(-2)
      : "";
  }

  static FormatDurationHours(duration) {
    if (!duration) {
      return "";
    }

    return duration.asHours().toFixed(2) + "h";
  }

  static GetHourPartFromMinutes(minutes) {
    //plus sign is needed to convert back to number
    return +(minutes / 60).toFixed(2);
  }
}

export default FormatUtils;
