import {
  UPDATE_MANAGER_PROJECTS,
  UPDATE_SELECTED_PROJECTS_IDS,
  UPDATE_SELECTED_PROJECTS_OBJECTS,
  UPDATE_FREE_DAY_TYPES,
  UPDATE_USER_PROJECTS,
  UPDATE_PROJECT_HELPER,
  UPDATE_PROJECTS,
  UPDATE_ALL_MANAGER_PROJECTS,
  UPDATE_SELECTED_AUDIT_OPTION,
  UPDATE_IS_LOADING,
  UPDATE_ALL_SELECTED,
} from "../actionTypes";

var defaultState = {
  managerProjects: [],
  managerProjectsHours: [],
  selectedProjectsIds: [],
  selectedProjectsObjects: [],
  freeDayTypes: [],
  userProjects: [],
  projectHelper: null,
  projects: [],
  selectedAuditOption: { label: "Original", value: 1 },
  isLoading: false,
  allSelected: false,
};

export default function projects(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_MANAGER_PROJECTS:
      return { ...state, managerProjects: action.projects };
    case UPDATE_SELECTED_PROJECTS_IDS:
      return { ...state, selectedProjectsIds: action.projectsIds };
    case UPDATE_SELECTED_PROJECTS_OBJECTS:
      return { ...state, selectedProjectsObjects: action.objects };
    case UPDATE_FREE_DAY_TYPES:
      return { ...state, freeDayTypes: action.freeDayTypes };
    case UPDATE_USER_PROJECTS:
      return { ...state, userProjects: action.projects };
    case UPDATE_PROJECT_HELPER:
      return { ...state, projectHelper: action.helper };
    case UPDATE_PROJECTS:
      return { ...state, projects: action.projects };
    case UPDATE_ALL_MANAGER_PROJECTS:
      return { ...state, allManagerProjects: action.allManagerProjects };
    case UPDATE_SELECTED_AUDIT_OPTION:
      return { ...state, selectedAuditOption: action.option };
    case UPDATE_IS_LOADING:
      return { ...state, isLoading: action.value };
    case UPDATE_ALL_SELECTED:
      return { ...state, allSelected: action.value };
    default:
      return state;
  }
}
