import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Admin from "./components/Admin/Admin";
import Hours from "./components/HoursPage/Hours";
import Planner from "./components/PlannerPage/Planner";
import DashBoard from "./components/DashBoardPage/DashBoard";
import AuthContext from "./components/Auth/AuthContext";
import OptionsNav from "./components/OptionsNav/OptionsNav";
import {
  updateFreeDayTypes,
  updateProjectHelper,
  updateAllManagerProjects,
  updateUserGroups,
} from "./stores/actions";
import {
  httpGetFreeDaysTypes,
  httpGetAllProjects,
  httpGetManagerProjects,
  httpGetIsInAuditGroup,
} from "./Urls";
import Header from "./components/Header/Header";
import { Container } from "react-bootstrap";
import "./App.css";

const freeDayShift = 1000;
const freeDayPrefix = "Free day - ";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };

    this.getFreeDayTypes = this.getFreeDayTypes.bind(this);
    this.getAllProjects = this.getAllProjects.bind(this);
    this.addFreeDayTypes = this.addFreeDayTypes.bind(this);
  }

  async componentDidMount() {
    this.getIsInAuditGroup();
    let freeTypes = await this.getFreeDayTypes();
    await this.getAllProjects(freeTypes);
    await this.fetchManagerProjects(
      this.props.store.getState().dates.startDate,
      this.props.store.getState().dates.endDate
    );
    this.setState({ loaded: true });
  }

  getIsInAuditGroup() {
    httpGetIsInAuditGroup().then((val) => {
      val.json().then((v) => {
        this.props.store.dispatch(updateUserGroups(v));
      });
    });
  }

  SetVacationAsDefaultOption(listFreeTypes) {
    const defaultType = "Vacation";
    var indexVaction = listFreeTypes.findIndex(
      (type) => type.Name === defaultType
    );
    if (indexVaction > 0) {
      var vacation = listFreeTypes[indexVaction];
      listFreeTypes.splice(indexVaction, 1);
      listFreeTypes = [vacation, ...listFreeTypes];
    }
    return listFreeTypes;
  }

  async getFreeDayTypes() {
    let response = await httpGetFreeDaysTypes();
    if (response.status === 200) {
      let freeTypes = await response.json();

      freeTypes = await this.SetVacationAsDefaultOption(freeTypes);

      this.props.store.dispatch(updateFreeDayTypes(freeTypes));
      return freeTypes;
    }
  }
  async getAllProjects(freeDayTypes) {
    let response = await httpGetAllProjects();
    if (response.status === 200) {
      let allProjects = await response.json();
      this.addFreeDayTypes(allProjects, freeDayTypes);

      allProjects = allProjects.sort((a, b) => a.Name.localeCompare(b.Name));
      this.props.store.dispatch(updateProjectHelper(allProjects));
    }
  }

  async addFreeDayTypes(projects, freeDayTypes) {
    freeDayTypes.forEach((type) => {
      projects.push({
        Name: freeDayPrefix + type.Name,
        ID: freeDayShift + type.ID,
      });
    });
  }

  async fetchManagerProjects(startDate, endDate) {
    let resp = await httpGetManagerProjects(startDate, endDate);
    if (resp.status === 200) {
      let managerProjects = await resp.json();
      managerProjects.sort((a, b) => a.Name.localeCompare(b.Name));
      if (managerProjects.length > 0) {
        this.props.store.dispatch(updateAllManagerProjects(managerProjects));
      }
    }
  }

  saveFilterState() {
    const dates = this.props.store.getState().dates;
    const workers = this.props.store.getState().workers;
    const projects = this.props.store.getState().projects;
    localStorage.setItem("isShowingWorkers", workers.isShowingWorkers);
    localStorage.setItem("startDate", dates.startDate);
    localStorage.setItem("endDate", dates.endDate);
    localStorage.setItem("allDates", dates.allDates);
    localStorage.setItem("allDatesEnabled", dates.allDatesEnabled);
    localStorage.setItem(
      "selectedWorkersIds",
      JSON.stringify(workers.selectedWorkersIds)
    );
    localStorage.setItem(
      "selectedWorkersObjects",
      JSON.stringify(workers.selectedWorkersObjects)
    );
    localStorage.setItem(
      "selectedProjectsIds",
      JSON.stringify(projects.selectedProjectsIds)
    );
    localStorage.setItem(
      "selectedProjectsObjects",
      JSON.stringify(projects.selectedProjectsObjects)
    );
    localStorage.setItem("allFuture", JSON.stringify(dates.allFuture));
    localStorage.setItem(
      "selectedAuditOption",
      JSON.stringify(projects.selectedAuditOption)
    );
  }

  render() {
    return this.state.loaded ? (
      <Router>
        <Header saveFilterState={this.saveFilterState.bind(this)} />
        <div className="page-content">
          <Container>
            <OptionsNav user={window.user} />
            <AuthContext>
              <Switch>
                <Route
                  path="/DashBoard"
                  render={() => <DashBoard user={window.user} />}
                />
                <Route
                  path="/Attendance"
                  render={() => <Planner user={window.user} />}
                />
                <Route
                  exact
                  path="/"
                  render={() => <Hours user={window.user} />}
                />
                <Route
                  exact
                  path="/Admin"
                  render={() => <Admin user={window.user} />}
                />
              </Switch>
            </AuthContext>
          </Container>
        </div>
      </Router>
    ) : (
      ""
    );
  }
}

export default App;
