/**
 * Created by nicom on 5/2/2017.
 */

import React, { Component } from "react";
import moment from "moment";
import InfoRowHours from "../../SharedComponents/InfoRowHours";
import FreeDayRow from "../../SharedComponents/FreeDayRow";
import HourRowData from "../../SharedComponents/DataModels/HourRowData";
import FreeDayRowData from "../../SharedComponents/DataModels/FreeDayRowData";
import "../../css/Hours.css";

import {
  httpAddActivityRow,
  httpUpdateActivityRow,
  httpRemoveActivityRow,
  httpAddFreeDayRow,
  httpUpdateFreeDayRow,
  httpRemoveFreeDayRow,
  httpGetAllowedProjects,
} from "../../../Urls";

import { getProjectNameByID } from "../../../common/ProjectsHelper";

class WorkedHourItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addedHourRow: null,
      addedFreeDayRow: null,
    };
    this.renderDate = this.renderDate.bind(this);
    this.renderHoursFromGate = this.renderHoursFromGate.bind(this);
    this.renderDateWorkedHours = this.renderDateWorkedHours.bind(this);
    this.renderHoursRowsData = this.renderHoursRowsData.bind(this);
    this.renderNewHourRowData = this.renderNewHourRowData.bind(this);
    this.addNewHourRow = this.addNewHourRow.bind(this);
    this.removeNewHourRow = this.removeNewHourRow.bind(this);

    this.renderFreeDayDataRow = this.renderFreeDayDataRow.bind(this);
    this.renderNewFreeDayDataRow = this.renderNewFreeDayDataRow.bind(this);
    this.addNewFreeDayRow = this.addNewFreeDayRow.bind(this);
    this.removeNewFreeDayRow = this.removeNewFreeDayRow.bind(this);

    this.ensureAllowedProjects = this.ensureAllowedProjects.bind(this);

    this.removeExistingActivityRow = this.removeExistingActivityRow.bind(this);
    this.addOrUpdateExistingActivityRow = this.addOrUpdateExistingActivityRow.bind(
      this
    );

    this.removeExistingFreeDayRow = this.removeExistingFreeDayRow.bind(this);
    this.addOrUpdateExistingFreeDayRow = this.addOrUpdateExistingFreeDayRow.bind(
      this
    );

    this.refreshData = this.refreshData.bind(this);
  }

  renderDate() {
    let dayData = moment(Date.now()).millisecond(0).second(0).minute(0).hour(0);
    let dayReceived = moment(new Date(this.props.dayData.Day.Date));

    if (dayData.format("DD.MM.YYYY") === dayReceived.format("DD.MM.YYYY"))
      return <span>Today - {dayReceived.format("DD.MM.YYYY")}</span>;
    else return <span>{dayReceived.format("dddd - DD.MM.YYYY")}</span>;
  }

  renderDateWorkedHours() {
    if (this.props.dayData.TotalHours > 0) {
      return "Total hours worked: " + this.props.dayData.TotalHours;
    }
  }

  renderHoursFromGate() {
    let doorsTimes = this.props.dayData.DoorTime;
    let timeFormat = this.props.timeFormat;
    if (doorsTimes.Entry.length !== 0 && doorsTimes.Exit.length !== 0) {
      let entryTime = moment(new Date(doorsTimes.Entry[0])).format(timeFormat);
      let exitTime = moment(
        new Date(doorsTimes.Exit[doorsTimes.Exit.length - 1])
      ).format(timeFormat);
      return (
        <span>
          {" "}
          First entry - {entryTime}, Last exit - {exitTime}{" "}
        </span>
      );
    } else if (doorsTimes.Exit.length !== 0) {
      let exitTime = moment(
        new Date(doorsTimes.Exit[doorsTimes.Exit.length - 1])
      ).format(timeFormat);
      return <span>Last exit - {exitTime} </span>;
    } else if (doorsTimes.Entry.length !== 0) {
      let entryTime = moment(new Date(doorsTimes.Entry[0])).format(timeFormat);
      return <span> First entry - {entryTime} </span>;
    }
  }

  renderHoursRowsData() {
    const { HoursWorked } = this.props.dayData;
    const deletedIds = HoursWorked.filter((h) => h.Action === 3).map(
      (v) => v.ID
    );
    return HoursWorked.map((dataRow, index) => {
      const { dayData, projectHelper } = this.props;
      return (
        <InfoRowHours
          
          key={dataRow.ID + "" + index}
          timeFormat={this.props.timeFormat}
          projectsAvailable={dayData.ProjectsAvailable}
          projectsAllowed={this.state.ProjectsAllowed}
          rowData={dataRow}
          currentDay={dayData.Day.Date}
          projectHelper={projectHelper}
          removeRow={this.removeExistingActivityRow}
          updateOrAddRow={this.addOrUpdateExistingActivityRow}
          ensureAllowedProjects={this.ensureAllowedProjects}
          HoursWorked={HoursWorked}
          workerId={this.props.workerId}
          selectedAuditOption={this.props.selectedAuditOption}
          thisUser={this.props.user.userName === dayData.Email.toLowerCase()}
          deletedIds={deletedIds}
          isLoading={this.props.isLoading}
          showCalendar={false}
          calendar={{ Id: "", Name: "" }}
          calendarsList={[]}
        />
      );
    });
  }

  renderNewHourRowData() {
 
    if (this.state.addedHourRow !== null) {
      const { dayData, projectHelper } = this.props;
      return (
        <InfoRowHours
          employeeID={
            dayData.HoursWorked[0] ? dayData.HoursWorked[0].EmployeeID : null
          }
          rowData={this.state.addedHourRow}
          timeFormat={this.props.timeFormat}
          projectsAvailable={dayData.ProjectsAvailable}
          projectsAllowed={this.state.ProjectsAllowed}
          currentDay={dayData.Day.Date}
          projectHelper={projectHelper}
          removeRow={this.removeNewHourRow}
          updateOrAddRow={this.addOrUpdateExistingActivityRow}
          ensureAllowedProjects={this.ensureAllowedProjects}
          HoursWorked={this.props.dayData.HoursWorked}
          workerId={this.props.workerId}
          selectedAuditOption={this.props.selectedAuditOption}
          thisUser={this.props.user.userName === dayData.Email.toLowerCase()}
          isLoading={this.props.isLoading}
          showCalendar={false}
          calendar={{ Id: "", Name: "" }}
          calendarsList={[]}
        />
      );
    }
  }

  renderFreeDayDataRow() {
    let { HoursFree, Day } = this.props.dayData;
    HoursFree = HoursFree ? HoursFree : [];
    const { FreeDayType } = this.props;
    return HoursFree.map((freeDayRow) => {
      return (
        <FreeDayRow
          key={freeDayRow.ID}
          rowData={freeDayRow}
          currentDay={Day.Date}
          removeRow={this.removeExistingFreeDayRow}
          updateOrAddRow={this.addOrUpdateExistingFreeDayRow}
          FreeDayType={FreeDayType}
          showButtons={this.props.selectedAuditOption.value !== 3}
          showCalendar={false}
          calendar={{ Id: "", Name: "" }}
          calendarsList={[]}
        />
      );
    });
  }

  renderNewFreeDayDataRow() {
    if (this.state.addedFreeDayRow !== null) {
      const { Day } = this.props.dayData;
      const { FreeDayType } = this.props;
      return (
        <FreeDayRow
          rowData={this.state.addedFreeDayRow}
          currentDay={Day.Date}
          removeRow={this.removeNewFreeDayRow}
          updateOrAddRow={this.addOrUpdateExistingFreeDayRow}
          FreeDayType={FreeDayType}
          showButtons={true}
          showCalendar={false}
          calendar={{ Id: "", Name: "" }}
          calendarsList={[]}
        />
      );
    }
  }

  addNewHourRow(description,projectId) {

    if (this.state.addedHourRow === null) {
     
    const { HoursWorked } = this.props.dayData;
      this.ensureAllowedProjects().then(() => {
        let frmTime = "",
          tillTime = "";
        if (HoursWorked.length === 0) {
          // First row to be added
          let doorsTimes = this.props.dayData.DoorTime;
          let timeFormat = this.props.timeFormat;
          if (doorsTimes.Entry.length !== 0) {
            frmTime = moment(new Date(doorsTimes.Entry[0])).format(timeFormat);
          }
          if (doorsTimes.Exit.length !== 0) {
            tillTime = moment(
              new Date(doorsTimes.Exit[doorsTimes.Exit.length - 1])
            ).format(timeFormat);
          }
        } else {
          
          let lastRow = HoursWorked[HoursWorked.length - 1];
          let timeArray = this.calculateDuration(
            lastRow.StartTime,
            lastRow.Duration
          );
          frmTime = timeArray[1]; // end time of last item is first time of new one by default
        }
        this.setState({ addedHourRow: new HourRowData(0, frmTime, tillTime, projectId, description) });
        
      });
   }
    
  }

  calculateDuration(StartTime, Duration) {
    
    let startTime = moment(StartTime, "HH:mm");
    let duration = moment(Duration, "HH:mm");
    let endTime = startTime
      .clone()
      .add(duration.hours(), "h")
      .add(duration.minutes(), "m");
    return [startTime, endTime];
  }

  removeNewHourRow() {
    this.setState({ addedHourRow: null });
  }

  addNewFreeDayRow() {
    const { HoursFree } = this.props.dayData;
    if (
      this.state.addedFreeDayRow === null &&
      (!HoursFree || HoursFree.length === 0)
    ) {
      this.setState({ addedFreeDayRow: new FreeDayRowData() });
    }
  }

  removeNewFreeDayRow() {
    this.setState({ addedFreeDayRow: null });
  }

  async ensureAllowedProjects() {
    if (this.state.ProjectsAllowed === undefined) {
      let response = await httpGetAllowedProjects(
        this.props.dayData.Day.Date,
        this.props.workerId
      );
      if (response.status === 200) {
        let resp = await response.json();
        let projects = resp.map((pid) =>
          getProjectNameByID(pid, this.props.projectHelper)
        );
        projects.sort((a, b) => a.Name.localeCompare(b.Name));
        this.setState({ ProjectsAllowed: projects });
      }
    }
  }

  refreshData() {
    this.props.fetchData(this.props.dayData.Day.Date, this.props.dayData.Email);
  }

  async removeExistingActivityRow(rowData) {
    rowData.WorkerID = this.props.workerId;
    rowData.IsAudit = this.props.selectedAuditOption.value === 2 ? true : false;
    let resp = await httpRemoveActivityRow(rowData);
    if (resp.status === 200) {
      this.refreshData();
    }
  }

  async addOrUpdateExistingActivityRow(rowData) {
    let resp;
    rowData.WorkerID = this.props.workerId;

    if (rowData.ID === undefined) {
      resp = await httpAddActivityRow(rowData);
    } else {
      resp = await httpUpdateActivityRow(rowData);
    }

    if (resp.status === 200) {
      this.refreshData();
    }

    if (resp.status === 400 || resp.status === 403) {
      this.addNewHourRow(rowData.Description, rowData.ProjectID);
    }
  }
  async removeExistingFreeDayRow(data) {
    data.WorkerID = this.props.workerId;
    let resp = await httpRemoveFreeDayRow(data);
    if (resp.status === 200) {
      this.refreshData();
    }
  }

  async addOrUpdateExistingFreeDayRow(data) {
    let resp;
    data.WorkerID = this.props.workerId;
    if (data.ID === undefined) {
      resp = await httpAddFreeDayRow(data);
    } else resp = await httpUpdateFreeDayRow(data);

    if (resp.status === 200) {
      this.refreshData();
    }
    
  }
  render() {
    const { dayData } = this.props;
    let backgroundColor = dayData.Day.Percentage === "100" ? "#ffe7e2" : "#ffffff";
    let addFreeDayButton = !!((!dayData.HoursFree || dayData.HoursFree.length === 0) && this.state.addedFreeDayRow === null) && (
      <a className="new-free-day" onClick={this.addNewFreeDayRow} style={{ backgroundColor: "transparent" }} />
    );

    return (
      <div className="itemContainer WorkedHourItem HoursWorkedHourItem" style={{ backgroundColor }}>
        <div className="hoursInfoRow">
          <div className="textInfoRow">
            <div className="dateShow">{this.renderDate()}</div>
            {!this.props.isShowingWorkers && (
              <span className="empNameShow">{dayData.Name}</span>
            )}
            <div className="entries">{this.renderHoursFromGate()}</div>
            <div className="dateWorkedHours">
              {this.renderDateWorkedHours()}
            </div>
          </div>
          {this.props.selectedAuditOption.value !== 3 && (
            <div className="buttonsInRowHour HoursWorkedHourItemButtonsInRowHour">
              <span>{addFreeDayButton}</span>
              <span>
                <a className="new-row" onClick={this.addNewHourRow} style={{ backgroundColor: "transparent" }} />
              </span>
            </div>
          )}
        </div>
        <div>
          {this.renderFreeDayDataRow()}
          {this.renderNewFreeDayDataRow()}
          {this.renderHoursRowsData()}
          {this.renderNewHourRowData()}
        </div>
      </div>
    );
  }
}

//(this.props.user.userName === this.props.dayData.Email && this.props.selectedAuditOption.value === 1)

export default WorkedHourItem;
