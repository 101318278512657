import { UPDATE_ISSUES } from "../actionTypes";

var defaultState = {
  issues: [],
};

export default function issues(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_ISSUES:
      return { ...state, issues: action.issues };
    default:
      return state;
  }
}
