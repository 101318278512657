import {
  UPDATE_IS_SHOWING_WORKERS,
  UPDATE_MANAGER_WORKERS,
  UPDATE_SELECTED_WORKERS_IDS,
  UPDATE_SELECTED_WORKERS_OBJECTS,
  UPDATE_SHOW_ABSENT_EMPLOYEES_MODAL,
  UPDATE_WORKERS,
} from "../actionTypes";

var defaultState = {
  isShowingWorkers: true,
  managerWorkers: [],
  selectedWorkersIds: [null],
  selectedWorkersObjects: [],
  showAbsentEmployeesModal: false,
  workers: [],
};

export default function workers(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_IS_SHOWING_WORKERS:
      return { ...state, isShowingWorkers: action.value };
    case UPDATE_MANAGER_WORKERS:
      return { ...state, managerWorkers: action.workers };
    case UPDATE_SELECTED_WORKERS_IDS:
      return { ...state, selectedWorkersIds: action.workersIds };
    case UPDATE_SELECTED_WORKERS_OBJECTS:
      return { ...state, selectedWorkersObjects: action.objects };
    case UPDATE_SHOW_ABSENT_EMPLOYEES_MODAL:
      return { ...state, showAbsentEmployeesModal: action.value };
    case UPDATE_WORKERS:
      return { ...state, workers: action.workers };
    default:
      return state;
  }
}
