/**
 * Created by nicom on 8/1/2017.
 */
import React, { Component } from "react";
import { Button, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import Language from "../../Languges/Language";

const timeFormats = {
  "24 hours": "H:mm",
  "AM-PM": "h:mm A",
};

const langFormats = {
  English: "en",
  עברית: "he",
};

class Settings extends Component {
  constructor(props) {
    super(props);

    let timeName = "24 hours";
    let langName = "English";

    let settings = localStorage.getItem("settings");
    try {
      var obj = JSON.parse(settings);
      let format = obj.timeFormat;
      for (var name in timeFormats) {
        if (timeFormats.hasOwnProperty(name) && timeFormats[name] === format) {
          timeName = name;
        }
      }
      let lang = obj.language;
      for (name in langFormats) {
        if (langFormats.hasOwnProperty(name) && langFormats[name] === lang) {
          langName = name;
        }
      }
    } catch (e) {}

    this.state = {
      timeFormat: timeName,
      language: langName,
    };
  }

  onLanguageChange(eventKey, event) {
    this.setState({ language: eventKey });
    let newSettings = {
      language: langFormats[eventKey],
      timeFormat: timeFormats[this.state.timeFormat],
    };
    localStorage.setItem("settings", JSON.stringify(newSettings));
    Language.changeText(eventKey);
    this.props.onSettingsChange(newSettings);
  }

  onTimeChange(eventKey, event) {
    this.setState({ timeFormat: eventKey });
    let newSettings = {
      language: langFormats[this.state.language],
      timeFormat: timeFormats[eventKey],
    };
    localStorage.setItem("settings", JSON.stringify(newSettings));
    this.props.onSettingsChange(newSettings);
  }
  render() {
    return (
      <div>
        <Modal
          size="sm"
          show={this.props.showModal}
          onHide={this.props.close}
        >
          <Modal.Header closeButton>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="RangeExample">
              <div>
                <p className="PercentageSelect">Language</p>
                <DropdownButton
                  onSelect={this.onLanguageChange.bind(this)}
                  id="navDropDownLanguage"
                  title={this.state.language}
                >
                  <Dropdown.Item eventKey="English">English</Dropdown.Item>
                  <Dropdown.Item eventKey="עברית">עברית</Dropdown.Item>
                </DropdownButton>
              </div>
              <div>
                <p className="PercentageSelect">Time format</p>
                <DropdownButton
                  onSelect={this.onTimeChange.bind(this)}
                  id="navDropDownTimeFormat"
                  title={this.state.timeFormat}
                >
                  <Dropdown.Item eventKey="24 hours">24 hours</Dropdown.Item>
                  <Dropdown.Item eventKey="AM-PM">AM-PM</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.close}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Settings;
