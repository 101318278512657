import React from "react";
import "../ApprovalState/ApprovalState.css";

class ApprovalState extends React.Component {
  render() {
    const { approvalId } = this.props;
    return (
      <div className="status">
        <p
          className={
            approvalId === 1
              ? "pending"
              : approvalId === 2
              ? "approved"
              : "declined"
          }
        >
          <span>
            {approvalId === 1
              ? "Pending approval"
              : approvalId === 2
              ? "Approved"
              : "Declined"}
          </span>
        </p>
      </div>
    );
  }
}

export default ApprovalState;
