export const UPDATE_IS_SHOWING_WORKERS = "UPDATE_IS_SHOWING_WORKERS";
export const UPDATE_START_DATE = "UPDATE_START_DATE";
export const UPDATE_END_DATE = "UPDATE_END_DATE";
export const UPDATE_MANAGER_WORKERS = "UPDATE_MANAGER_WORKERS";
export const UPDATE_MANAGER_PROJECTS = "UPDATE_MANAGER_PROJECTS";
export const UPDATE_SELECTED_WORKERS_IDS = "UPDATE_SELECTED_WORKERS_IDS";
export const UPDATE_SELECTED_PROJECTS_IDS = "UPDATE_SELECTED_PROJECTS_IDS";
export const UPDATE_FREE_DAY_TYPES = "UPDATE_FREE_DAY_TYPES";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_ALL_DATES = "UPDATE_ALL_DATES";
export const UPDATE_ALL_DATES_ENABLED = "UPDATE_ALL_DATES_ENABLED";
export const UPDATE_SELECTED_PROJECTS_OBJECTS =
  "UPDATE_SELECTED_PROJECTS_OBJECTS";
export const UPDATE_SELECTED_WORKERS_OBJECTS =
  "UPDATE_SELECTED_WORKERS_OBJECTS";
export const UPDATE_TIME_FORMAT = "UPDATE_TIME_FORMAT";
export const UPDATE_SHOW_ABSENT_EMPLOYEES_MODAL =
  "UPDATE_SHOW_ABSENT_EMPLOYEES_MODAL";
export const UPDATE_PROJECTS_DATA = "UPDATE_PROJECTS_DATA";
export const UPDATE_HEADER_SHOW_MODAL = "UPDATE_HEADER_SHOW_MODAL";
export const UPDATE_ALERTS = "UPDATE_ALERTS";
export const UPDATE_USER_PROJECTS = "UPDATE_USER_PROJECTS";
export const UPDATE_HOURS = "UPDATE_HOURS";
export const UPDATE_WORKED_DETAILS = "UPDATE_WORKED_DETAILS";
export const UPDATE_PROJECT_HELPER = "UPDATE_PROJECT_HELPER";
export const UPDATE_USER_FREE_DAYS = "UPDATE_USER_FREE_DAYS";
export const UPDATE_WORKERS = "UPDATE_WORKERS";
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";
export const UPDATE_ALL_MANAGER_PROJECTS = "UPDATE_ALL_MANAGER_PROJECTS";
export const UPDATE_APPROVAL_STATUS = "UPDATE_APPROVAL_STATUS";
export const UPDATE_ALL_FUTURE = "UPDATE_ALL_FUTURE";
export const UPDATE_ISSUES = "UPDATE_ISSUES";
export const UPDATE_SELECTED_AUDIT_OPTION = "UPDATE_SELECTED_AUDIT_OPTION";
export const UPDATE_USER_GROUPS = "UPDATE_USER_GROUPS";
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_ALL_SELECTED = "UPDATE_ALL_SELECTED";
export const UPDATE_ADMIN_HOURS = "UPDATE_ADMIN_HOURS";
export const UPDATE_CALENDARS = "UPDATE_CALENDARS";
