/**
 * Created by nicom on 8/1/2017.
 */

import React, { Component } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
class Report extends Component {
  render() {
    let data = this.props.data.toCSVFromat();
    let startDate = moment(new Date(this.props.startDate)).format("DD.MM.YYYY");
    let endDate = moment(new Date(this.props.endDate)).format("DD.MM.YYYY");
    let fileName = startDate + "-" + endDate + ".csv";
    let exportButton = null;
    if (data.length) {
      exportButton = (
        <div className="buttonExport">
          <CSVLink data={data} filename={fileName}>
            <Button> Export</Button>{" "}
          </CSVLink>
        </div>
      );
    }
    return (
      <div className="reportContainer">
        {exportButton}
        <div className="reportDataContainer">
          {this.props.data && this.props.data.toParagraph()}
        </div>
      </div>
    );
  }
}

export default Report;
