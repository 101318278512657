/**
 * Created by nicom on 8/19/2017.
 */
/**
 * Created by nicom on 8/19/2017.
 */

export const he = {
  hours: "שעות",
  dashBoard: "Dash Board",
  settings: "הדגרות",
  help: "עזרה",
  signOut: "יציאה",
  allProjects: "כל הפרויקטים",
  me: "אני",
  fromDate: "מתאריך",
  toDate: "עד תאגיך",
  export: "הוצאה",
  prevMonth: "חודש שעבר",
  prevWeek: "שבוע שעבר",
  thisWeek: "השבוע",
  thisMonth: "החודש",
  custom: "בחירה",
  outOfOffice: "מחוץ למשרד",
  alerts: "התראות",
  addNew: "+ הוסף שעות",
  addOut: "+ הוסף מחוץ",
  today: "היום",
  edit: "ערוך",
  save: "שמור",
  cancel: "בטל",
  freeDayDeclaration: "הצהרת מחוץ למשרד",
  language: "שפה",
  time: "פורמט זמן",
  close: "סגור",
  confirm: "אשר",
  removeFreeText: "מחק שורת מחוץ למשרד",
  removeHourText: "מחק שורת שעות עבודה",
  removeSubTittle: "האם אתה בטוח?",
  firstDay: "יום הראשון",
  lastDay: "יום האחרון",
  reset: "אפס",
  chooseCustom: "תבחר את הימים הרצויים",
  pleaseSelect: "אנא בחר",
  chooseFrom: "בחרת מ",
  chooseTill: "עד",
  english: "אנגלית",
  hebrew: "עיברית",
  selectPercentage: "אנא בחר אחוזים",
  days: "ימים",
  selectType: "בחר סוג",
  clear: "אפס",
  from: "מתוך",
  hoursWorked: "שעות עבודה (ממוצע ",
  fromDay: "בכל יום עבודה)",
  firstEntry: "כניסה ראשונה",
  lastExit: "יציאה אחרונה",
};
