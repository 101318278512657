class AdminFreeDayReportDataModel {
  constructor(
    id,
    freeDayID,
    calendarDayId,
    percentage,
    calendarId,
    description
  ) {
    this.ID = id;
    this.FreeDayID = freeDayID;
    this.CalendarDayId = calendarDayId;
    this.Percentage = percentage;
    this.CalendarId = calendarId;
    this.Description = description;
  }
}

export default AdminFreeDayReportDataModel;
