import { useMediaQuery } from 'react-responsive';

/** Based on [Bootstrap breakpoints](https://getbootstrap.com/docs/5.0/layout/breakpoints/) */
const bootstrapBreakpoints = {
  /** Landscape phones    */ sm :  576,
  /** Tablets             */ md :  768,
  /** Desktops            */ lg :  992,
  /** Large desktops      */ xl : 1200,
  /** **Larger** desktops */ xxl: 1400
};

/** Based on [Bootstrap breakpoints](https://getbootstrap.com/docs/5.0/layout/breakpoints/) */
const useScreenMode = () => {

  // No query for portrait mobile since this is the default

  const isLandscapeMobile = useMediaQuery({ minWidth: bootstrapBreakpoints.sm  });
  const isTablet          = useMediaQuery({ minWidth: bootstrapBreakpoints.md  });
  const isDesktop         = useMediaQuery({ minWidth: bootstrapBreakpoints.lg  });
  const isLargeDesktop    = useMediaQuery({ minWidth: bootstrapBreakpoints.xl  });
  const isLargerDesktop   = useMediaQuery({ minWidth: bootstrapBreakpoints.xxl });

  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isRetina   = useMediaQuery({ minResolution: '2dppx' });

  return {
    /** Indicates whether the screen width is **at least** as for a landscape mobile */ isLandscapeMobile,
    /** Indicates whether the screen width is **at least** as for a tablet           */ isTablet         ,
    /** Indicates whether the screen width is **at least** as for a desktop          */ isDesktop        ,
    /** Indicates whether the screen width is **at least** as for a large desktop    */ isLargeDesktop   ,
    /** Indicates whether the screen width is **at least** as for a larger desktop   */ isLargerDesktop  ,
                                                                                        isPortrait       ,
                                                                                        isRetina
  };
};

export default useScreenMode;
